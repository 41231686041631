import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import ReactAudioPlayer from 'react-audio-player'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { FaTelegramPlane } from 'react-icons/fa'
import { FiExternalLink } from 'react-icons/fi'
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { apiUrl } from '../Variables'
import textLogo from '../images/ptp.jpeg'
// product images
import { useRef } from 'react'
import MiniLoading from '../components/MiniLoading'
import logo from '../images/ptp.jpeg'
import messageIc from '../images/icons/message.svg'
import x from '../images/icons/times.svg'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Cookies from 'js-cookie'


import { BsXLg } from 'react-icons/bs'
import { GrRefresh } from 'react-icons/gr'
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"



function PTPBotScreen() {
  const [toggle, setToggle] = useState(false); 

  const [ip, setIp] = useState(Cookies.get('senderId'))
  let audio = new Audio("/message.mp3")
  const [resize, setResize] = useState(false);
  const [formToggle, setFormToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [width, setWidth] = useState(window.innerWidth); 
  const [emojiModel, setEmojiModel] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selecteBtn, setSelecteBtn] = useState('');
  const [botObj, setBotObj] = useState({
    name: "",
    email: "",
    mobile: "",
    guest: "",
    roomType: "",
    date: "",
    cost: 0,
    meal: "",
    roomNumber: "",

  })
  const [message, setMessage] = useState('')

  // chatlists
  const [chatLists, setChatLists] = useState([]);
  const [reset, setReset] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [deleteChats, seDeleteChats] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date())

  const onChange = (dates) => {
    const [start, end] = dates;
    setBotObj({...botObj, date: `${start?.toLocaleDateString()} to ${end?.toLocaleDateString()}`})
    setStartDate(start);
    setEndDate(end);
  };

  console.log("botObj", botObj)

  const mobileViewport = () => {
    setWidth(window.innerWidth)
  }

  const chatRef = useRef(null)

  useEffect(() => {

    const fetchAllChats = async() => {
      try { 
        // localhost:5000/api/ptpscrape/
        const {data} = await axios.get(`${apiUrl}/bot/chatWithBot`)
        setChatLists(data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchAllChats();
  },[reset, deleteChats])

  useEffect(()=> {
    chatRef.current?.scrollIntoView({ behavior: "smooth" })
  },[message, chatLists, error])

  useEffect(() => {
    const getData = async () => {
      // const res = await axios.get('https://geolocation-db.com/json/')
      // localStorage.setItem('ip', res.data.IPv4)
      // setIp(res.data.IPv4)
      if (!ip) {
        // Generate a unique identifier
        const uniqueId = `PTP${new Date().getTime()}${Math.floor(Math.random() * 900) + 100}`.replace(/\./g, 'U')
        // Set the unique identifier as a cookie
        Cookies.set('senderId', uniqueId, { expires: 365 }); // Set an expiration date
        setIp(uniqueId)
      }
    }
    getData()
    window.addEventListener('resize', mobileViewport)
    return () => window.removeEventListener('resize', mobileViewport)
  }, [])

  useEffect(() => {
    const fetchChats = async() => {
        await axios.get(`${apiUrl}/ptpscrape/ptpchat/getChats/${ip}?date=${currentDate.toISOString()}`).then(res => {
          console.log(res)
          setChatLists(res.data.chats)
        }).catch((error) => {
          console.log("error", error)
        })
    }
    fetchChats()
  }, [/*resetData*/])

  const handleToggle = (e) => {
    setToggle(!toggle);
  }

  // open chat and delete or reset existing chats
  const handleOpenChatAndResetChat = async () => {
    setToggle(!toggle);
    // try {
    //   seDeleteChats(false)
    //   setDeleteLoading(true)
    //   const {data} = await axios.delete(`${apiUrl}/bot/chatWithBot`)
    //   seDeleteChats(true)
    //   setDeleteLoading(false)
    //   setError('')
    //   console.log(data)
    // } catch (error) {
    //   console.log(error)
    //   seDeleteChats(false)
    //   setDeleteLoading(false)
    // }
  }
  const handleInputSubmit = async (e, msg) => {
    e?.preventDefault()
    if (!message && !msg) {
      console.log('retrun from onsubmit')
      return
    }
    setLoading(true)
    const tempMsg = message
    const data = { sender: ip, message: tempMsg || msg };
    console.log(data)
    const chatArr = [...chatLists, {user: tempMsg || msg, time: "today's date'"}]
    setChatLists(chatArr)
    setMessage('')
    await axios.post(`${apiUrl}/ptpscrape/ptpchat?btn=${msg?.replace(/\//g, '') || selecteBtn}&date=${currentDate.toISOString()}`, data).then( res => {
        console.log(res)
        setChatLists([...res.data.chats])
        setMessage('')
        setLoading(false)
      }).catch( error => {
        setLoading(false)
        console.log(error)
        setError(true)
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message,
        })
      })
  }

  // delete chats handler
  const deleteChatsHandler = async() => {
    setSelecteBtn(null)
    handleInputSubmit( null, '/restart')
    // try {
    //   seDeleteChats(false)
    //   setDeleteLoading(true)
    //   const {data} = await axios.delete(`${apiUrl}/bot/chatWithBot`)
    //   seDeleteChats(true)
    //   setDeleteLoading(false)
    //   setError('')
    //   console.log(data)
    // } catch (error) {
    //   console.log(error)
    //   seDeleteChats(false)
    //   setDeleteLoading(false)
    // }
  }

  return (
    <>      
        {/* <TestDialogflowReq /> */}
      <section className='chatbot-section bot-demo-wrapper'>
        <div className={resize ? "chatbot-widget-wrapper full-screen" : "chatbot-widget-wrapper"}>

            {/* chatbot widget wrapper */}
            <div className={toggle ? "chatbot-widget active" : "chatbot-widget"}>
              
              {/* chat header */}
              <div className="chatbot-widget-header">

                {/* agent presence */}
                <div className="chatbot-widget-agent-precence">

                  {/* bot times icon */}
                  <div className='chatbot-times-icon' onClick={handleToggle}>
                    <img src={x} alt="x" /> 
                  </div>

                  {/* agent left side */}
                  <div className="chatbot-widget-header-left">
                    {/* agent avatar */}
                    <div className="chatbot-recipient-avatar">
                        <img src={textLogo} alt="agent avatar" />
                        <div className="online-status"></div>
                    </div>

                    {/* agent details */}
                    <div className="chatbot-widget-recipient-details">
                      <p>Chat with</p>
                      <h3>PTP Bot
                      </h3>
                    </div>
                  </div>

                  {/* agent right side options */}
                  <div className="chatbot-widget-header-right">

                    {/* chatbot credit */}
                    {/* <div className='chatbot-creator'>
                      <p>Powered by</p>
                      <a href="https://www.wipdata.com/" target="_blank" rel="noreferrer">
                        <img src={logo} alt="site logo" />
                      </a>
                    </div> */}

                    <div className='chatbot-widget-header-right-options'>

                      {/* minimize */}
                      <div onClick={deleteChatsHandler} className="chatbot-widget-minimize delete-chat-icon">
                        <GrRefresh style={{width:'30px', height:'30px'}}/>
                        {/* <span>Close chat</span> */}
                      </div>

                      {/* minimize */}
                      <div className="chatbot-widget-minimize desktop-hide" onClick={handleToggle}>
                        <BsXLg />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* welcome */}
              {/* <div className="welcome-the-bot">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, minima?</p>
                <button>Start</button>
              </div> */}
              {/* chat conversation group body */}
              <div className="chat-conversation-wrapper bot-message-wrapper">
                {/* message body */}
                <div className="message-body chatbot-message-body">
                  <ul>
                    { deleteLoading && <MiniLoading /> }

                      <li>
                        <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            <p className='message-bubble-agent'>Hello! Welcome to PTP chatbot. How can I assist you today?</p>
                          </div>
                      </li>
                  {console.log(chatLists)}
                  {/* conversations */}
                  {
                    chatLists?.map((item, index) => (
                      <div key={index}>

                        {/* user */}
                        <li className="message-bubble-visitor">
                          <span className=''>{item?.user}</span>
                        </li>

                        {/* bot response */}
                        {
                          item?.agent?.length > 0 &&  <li>
                            <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            
                            <p className='message-bubble-agent'>{item?.agent?.length > 0 ? item?.agent[0]?.response : ''} </p>
                              
                            

                            {/* if button, then show the button layout */}
                            {item?.agent?.length > 0 && item?.agent[0]?.payload && (
                              <div className="message-auto-suggest-buttons">
                                
                                {
                                  item?.agent[0]?.payload?.map((btn, btnIndex) => (
                                    <button onClick={() => {
                                      setSelecteBtn(btn.payload.replace(/\//g, ''))
                                      handleInputSubmit(null, btn.payload)
                                      }} className='suggest-btn' key={btnIndex}>
                                      <span>{btn?.title}</span>
                                    </button>
                                    
                                  ))
                                }
                              
                              </div>
                            )}

                          </div>
                          
                        </li>
                        }
                      </div>
                    ))
                  }


                  {/* babble typing... */}
                  {loading && (
                    <li>
                      <img className='agent-logo' src={logo} alt="bot" />
                      <div className="typing-wrap message-bubble-agent">
                        <span className="tying">Typing</span>
                        <span className="animate-typing">
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                        </span>
                      </div>
                    </li>
                  )}
                  {/* {
                    error && (
                      <li>
                        <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            <p className='message-bubble-agent'>{error}</p>
                          </div>
                      </li>
                    )
                  } */}
                  <li ref={chatRef}></li>
                </ul>
                </div>
              </div>

              {/* chat footer */}
              <div className="chatbot-footer">
                <form onSubmit={handleInputSubmit}>
                    <div className="chatbot-footer-input-box mini-bot-footer">
                      <input style={{width:'280px'}} value={message} onChange={(e) => setMessage(e.target.value) } type="text" placeholder='Start conversation...' />
                      <button className='chatbot-send-message-button'>
                        <FaTelegramPlane />
                      </button>
                    </div>
                </form>
            </div>
          </div>

            {/* chatbot open icon && if resize is true the hide chatbot icon */}
            <div className={toggle ? 'chatbot-mini-preview-wrap active' : 'chatbot-mini-preview-wrap'} onClick={handleOpenChatAndResetChat}>
              <div className="chatbot-mini-preview-left">
                <img className='bot-preview-img' src={messageIc} alt="message icon" /> 
                <span className='send-us-message'>Send us a message</span>
              </div>
              <div className="chatbot-mini-preview-right">
                <h5>PTP BOT</h5>
              </div>
            </div>
            {/* <ReactAudioPlayer
              src={audio}
              controls
              className='audio-hidden'
            /> */}
        </div>
        </section>
    </>
  )
}

export default PTPBotScreen