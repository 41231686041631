import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BiLineChart } from 'react-icons/bi'
import { BsBarChartFill, BsFileEarmarkText, BsX } from 'react-icons/bs'
import { FaChartArea } from 'react-icons/fa'
import { RiBarChartHorizontalFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import AreaChartAnalytics from '../components/analytics/AreaChartAnalytics'
import BarChartAnalytics from '../components/analytics/BarChartAnalytics'
import HorizontalChartAnalytics from '../components/analytics/HorizontalChartAnalytics'
import LineChartAnalytics from '../components/analytics/LineChartAnalytics'
import TextChartAnalytics from '../components/analytics/TextChartAnalytics'
import AnalyticsAxis from '../components/AnalyticsAxis'
import AnalyticsFields from '../components/AnalyticsFields'
import WhiteCanvas from '../components/WhiteCanvas'
import Drag from '../images/drag.png'
import { apiUrl } from '../Variables'
// import 'react-tabs/style/react-tabs.css';
import { GrPowerReset } from 'react-icons/gr'


function AnalyticsScreen() {

  const [fileDataLoading, setFileDataLoading] = useState(false)
  const [fileDataSuccess, setFileDataSuccess] = useState(false)
  const [tableData, setTableData] = useState([{
    label: '',
    id: '',
    sheet: '',
    data: []
  }])
  const [fileData, setFileData] = useState({
    sheetArray: [],
    dataArray: [],
  })
  const [xData, setXData] = useState([])
  const [yData, setYData] = useState([])
  const [yToggle, setYToggle] = useState(false)
  const [xToggle, setXToggle] = useState(false)
  const [reset, setReset] = useState(false)
  const toggleReset = () => {
    setReset(!reset)
  }

  const toggleFunc = () => {
    setYToggle(!yToggle)
  }

  const xToggleFunc = () => {
    setXToggle(!xToggle)
  }

  const [filter, setFilter] = useState({
    sheet:'',
    label: [/* {
      name: '',
      amount: 0,
      operation: ''
    } */],
  })
  const [xfilter, setxFilter] = useState({
    sheet:'',
    label: [/* {
      name: '',
      amount: 0,
      operation: ''
    } */],
  })
  const [filterdYChartData, setFilterdYChartData] = useState([])
  const [filterdXChartData, setFilterdXChartData] = useState([])

  // only for drag item data storage
  const [filterdTableData, setFilteredTableData] = useState([])
  const [uniqueDataArr, setUniqueDataArr] = useState([])

  useEffect(() => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const fetchData = async () => {
        setFileDataLoading(true)
        setFileDataSuccess(false)
        const { data } = await axios.get(`${apiUrl}/scrap/xero`, config)
        setFileDataLoading(false)
        setFileDataSuccess(true)
        data && setFileData({ ...fileData, sheetArray: Object.keys(data[0]?.xero), dataArray: data[0]?.xero })
      }
      fetchData()
    } catch (error) {
      console.log(error)
    }
    
  }, [uniqueDataArr])


  useEffect(() => {
    if (xData.length !== 0 && yData.length !== 0) {
      yAxisDataIntoChartObject()
    } else {
      setUniqueDataArr([])
    }
  }, [xData, yData, reset])

  const yAxisDataIntoChartObject = (e) => {
    e?.preventDefault()
    // init variables
    const tempChartData = []
    const dateData = []
    xData?.map((item, index) => {
      dateData.push(item?.data)
    })

    for (let index = 0; index < dateData?.length; index++) {
      let element = dateData[index];
    yData?.map((item, index) => {
      let i = 0
      item?.data?.map((data, dataIndex) => {
        
        xData?.map((xdataItem, xdataIndex) => {
          // console.log(xdataItem.label)
          const chartData = {
            ...tempChartData[i],
            // [xdataItem?.label]: (xdataItem === null) ? '' : xdataItem?.label,
            [item.label]: (data === null || data === '-') ? 0 : typeof(data) === 'string' ? Math.floor(data?.replace(/[,\s]/g, '')) : data,

            xAxis: (element[i] === null) ? '0' : element[i].replace(/,/g, ''),
            
            xAxisTitle : xdataItem.label
          }
          tempChartData[i] = chartData
          i++
        })

      })
    })
  }

    // filter functional
    if ((filter.label[0]?.amount !== '' && filter.label[0]?.operation !== '') || (xfilter.label[0]?.amount !== '' && xfilter.label[0]?.operation !== '') || (xfilter.label[0]?.startDate !== '' && xfilter.label[0]?.endDate !== '')) {
      let temp = [...tempChartData]
      for (const f of filter.label) {
        switch (f.operation) {
          case '>':
            temp = temp.map(tdata => {
              if (parseFloat(tdata[f.name]) <=  parseFloat(f.amount)) {
                return {...tdata, [f.name]: null}
              } else {
                return tdata
              }
            })
            setUniqueDataArr(temp)
            break;
          case '<':
             temp = temp.map(tdata => {
              if (parseFloat(tdata[f.name]) >=  parseFloat(f.amount)) {
                return {...tdata, [f.name]: null}
              } else {
                return tdata
              }
            })
            setUniqueDataArr(temp)
            break;
          case '===':
            temp = temp.map(tdata => {
              if (parseFloat(tdata[f.name]) !==  parseFloat(f.amount)) {
                return {...tdata, [f.name]: null}
              } else {
                return tdata
              }
            })
            setUniqueDataArr(temp)
             break;
          case '>==':
            temp = temp.map(tdata => {
              if (parseFloat(tdata[f.name]) <  parseFloat(f.amount)) {
                return {...tdata, [f.name]: null}
              } else {
                return tdata
              }
            })
            setUniqueDataArr(temp)
            break;
          case '<==':
            temp = temp.map(tdata => {
              if (parseFloat(tdata[f.name]) >  parseFloat(f.amount)) {
                return {...tdata, [f.name]: null}
              } else {
                return tdata
              }
            })
            setUniqueDataArr(temp)
            break;
        }
      }
      for (const f of xfilter.label || []) {
        if (f.startDate !== '' && f.endDate !== '') {
          temp = temp.filter(tdata => new Date(tdata['xAxis']) >= new Date(f.startDate) )
          temp = temp.filter(tdata => new Date(tdata['xAxis']) <= new Date(f.endDate) )
          setUniqueDataArr(temp)
        } else {
          switch (f.operation) {
            case 'contains':
              temp = temp.filter(tdata => tdata['xAxis'].toLowerCase().includes(f.amount.toLowerCase()))
              setUniqueDataArr(temp)
              break;
            case 'exact':
              temp = temp.filter(tdata => tdata['xAxis'].toLowerCase() === f.amount.toLowerCase())
              setUniqueDataArr(temp)
              break;
            case 'starts_with':
              temp = temp.filter(tdata => tdata['xAxis'].toLowerCase().startsWith(f.amount.toLowerCase()))
              setUniqueDataArr(temp)
               break;
            case 'ends_with':
              temp = temp.filter(tdata => tdata['xAxis'].toLowerCase().endsWith(f.amount.toLowerCase()))
              setUniqueDataArr(temp)
              break;
          }
        }
      }
      ///check for any useless x axis value
      const temp1 = []
      for (const t of temp) {
        const tempObj = {...t}
        delete tempObj.xAxis
        delete tempObj.xAxisTitle
        const keys = Object.keys(tempObj)
        const nonEmptyKeys = keys.filter(k => t[k] !== null)
        if (nonEmptyKeys.length !== 0) {
          temp1.push(t)
        }
      }
      setUniqueDataArr(temp1)
      console.log('temp', temp)
      console.log('temp1', temp1)
    } else {
      setUniqueDataArr(tempChartData)
    }
  
  }

  return (
    <>
     {/* filter for yAxis */}
     <div className={yToggle ? "analytics-filter-container active" : "analytics-filter-container"}>

        {/* filter header */}
        <div className="filter-header">
          <h2 className="filter-title">Y Axis Filter</h2>
          <BsX onClick={() => setYToggle(!yToggle)} className='filter-times' />
        </div>

        <div className={"dropdown-value-filter"}>
        <form onSubmit={yAxisDataIntoChartObject}>
          <div className="value-filter-container">
            {/* send option */}
            {
              filter.label?.map((l, index) => 
              <Tabs>
                <div className="filter-sidebar-title-and-reset">
                  <h5>{l.name}</h5>
                  <GrPowerReset onClick={() => {
                    filter.label[index] = {...filter.label[index], amount: 0, operation: '>'}
                    setFilter({...filter})
                    setReset(!reset)
                  }}/>
                </div>
                <TabList className="choose-option choose-item">
                  {/* money filed */}
                  <div>
                    <div className="analytics-choose-amount-filed choose-item">
                      <label htmlFor="">Refference Amount</label>
                      <input onChange={(e) => {
                        filter.label[index] = {...filter.label[index], amount: e.target.value}
                        setFilter({...filter})
                      }} type="text" placeholder='Amount' value={l.amount}/>
                    </div>
                    <div className="form-group" style={{marginTop:'5px'}}>
                      <select onChange={(e) => {
                        filter.label[index] = {...filter.label[index], operation: e.target.value}
                        setFilter({...filter})
                      }} id="isAdmin" value={filter.label[index].operation}>
                        <option value="select-user" disabled selected>Operations</option>
                        <option value=">">Greater Than</option>
                        <option value="<">Less Than</option>
                        <option value="===">Equal</option>
                        <option value=">==">Greater Than Equal</option>
                        <option value="<==">Less Than Equal</option>
                      </select>
                    </div>
                  </div>
                </TabList>
              </Tabs>)
            }
          </div>
          

          {/* send button */}
          <div /* onClick={filterSubmitHandler} */ className="choose-send-button choose-item analytics-filter-btn">
            <button className={''} type='submit'>Filter</button>
          </div>
        </form>
        </div>
      </div>


      {/* xAxis filter */}
     <div className={xToggle ? "analytics-filter-container active" : "analytics-filter-container"}>

        {/* filter header */}
        <div className="filter-header">
          <h2 className="filter-title">X Axis Filter</h2>
          <BsX onClick={() => setXToggle(!xToggle)} className='filter-times' />
        </div>

        <div className={"dropdown-value-filter"}>
        <form onSubmit={yAxisDataIntoChartObject}>
          <div className="value-filter-container">
            {/* send option */}
            {
              xfilter.label?.map((l, index) => 
              <Tabs key={index}>
                <div className="filter-sidebar-title-and-reset">
                  <h5>{l.name}</h5>
                  <GrPowerReset onClick={() => {
                    xfilter.label[index] = {...xfilter.label[index], amount: '', operation: '', startDate: '', endDate: ''}
                    setxFilter({...xfilter})
                    setReset(!reset)
                  }}/>
                </div>
                <TabList className="choose-option choose-item">
                  {/* money filed */}
                  <div>
                    {
                      l.xAxisTitle?.toLowerCase() === 'date' ? <>  
                      {/* start date filter */}
                      <div className="analytics-choose-amount-filed choose-item">
                        <label htmlFor="">Start date</label>
                        <input type="date" value={l.startDate} onChange={(e) => {
                        xfilter.label[index] = {...xfilter.label[index], startDate: e.target.value}
                        setxFilter({...xfilter})
                      }}/>
                      </div>
  
                      {/* end date filter */}
                      <div className="analytics-choose-amount-filed choose-item">
                        <label htmlFor="">End date</label>
                        <input type="date" value={l.endDate} onChange={(e) => {
                        xfilter.label[index] = {...xfilter.label[index], endDate: e.target.value}
                        setxFilter({...xfilter})
                      }}/>
                      </div></> : <>
                         {/* search string */}
                    <div className="analytics-choose-amount-filed choose-item">
                      <label htmlFor="">Search string</label>
                      <input onChange={(e) => {
                        xfilter.label[index] = {...xfilter.label[index], amount: e.target.value}
                        setxFilter({...xfilter})
                      }} type="text" placeholder='Search...'  value={l.amount}/>
                    </div>
                    <div className="form-group" style={{marginTop:'5px'}}>
                      <select  onChange={(e) => {
                        xfilter.label[index] = {...xfilter.label[index], operation: e.target.value}
                        setxFilter({...xfilter})
                      }} id="isAdmin" value={l.operation}>
                        <option value="select-user" disabled selected>Operations</option>
                        <option value="contains">Contains</option>
                        <option value="exact">Exact</option>
                        <option value="starts_with">Starts With</option>
                        <option value="ends_with">Ends With</option>
                      </select>
                    </div>
                      </>
                    }
                  </div>
                </TabList>
              </Tabs>)
            }
          </div>
          

          {/* send button */}
          <div /* onClick={filterSubmitHandler} */ className="choose-send-button choose-item analytics-filter-btn">
            <button className={''} type='submit'>Filter</button>
          </div>
        </form>
        </div>
      </div>

      {/* {fileDataLoading && <Loading />} */}
      <div className="analytics-container p-20">

        {fileData.sheetArray.length !== 0 ? (
          <div className="analytics-wrapper">
            {/* body wrapper */}
            <div className="analytics-canvas">
              <Tabs>
                {/* analytics header */}
                <div className="analytics-header">
                  <div className="table-title">
                    {/* <h3>File name: {fileData?.fileName && <span className="filename-span">{fileData?.fileName}</span>} </h3> */}
                  </div>
                  <TabList className="analytics-tab-icon-wrap">
                    <div className="analytics-header-icons">
                      <Tab className="analytics-tab-icon"><FaChartArea className='chart-text green' /></Tab>
                      {/* <Tab className="analytics-tab-icon"><AiFillPieChart className='chart-text purple' /></Tab> */}
                      <Tab className="analytics-tab-icon"><BsBarChartFill className='chart-text orange' /></Tab>
                      <Tab className="analytics-tab-icon"><RiBarChartHorizontalFill className='chart-text blue' /></Tab>
                      <Tab className="analytics-tab-icon"><BiLineChart className='chart-text skyblue' /></Tab>
                      <Tab className="analytics-tab-icon"><BsFileEarmarkText className='chart-text green' /></Tab>
                    </div>
                  </TabList>
                </div>
                {/* body canvas */}
                {
                  fileData.sheetArray.length !== 0 ? (
                    <>
                      {/* Area chart | tab content */}
                      <TabPanel>
                        <div className="analytics-chart-mask">
                          {/* <AreaChartAnalyticsNew data={uniqueDataArr} /> */}
                          <AreaChartAnalytics data={uniqueDataArr} />
                        </div>
                      </TabPanel>

                      {/* Pie chart | tab content */}
                      {/* <TabPanel>
                        <div className="analytics-chart-mask">
                          <PieChartAnalytics data={uniqueDataArr} />
                        </div>
                      </TabPanel> */}
                      
                      {/* Bar chart | tab content */}
                      <TabPanel>
                        <div className="analytics-chart-mask">
                          <BarChartAnalytics data={uniqueDataArr} />
                        </div>
                      </TabPanel>
                      
                      {/* Horizontal chart | tab content */}
                      <TabPanel>
                        <div className="analytics-chart-mask">
                          <HorizontalChartAnalytics data={uniqueDataArr} />
                        </div>
                      </TabPanel>
                      
                      {/* Vertical chart | tab content */}
                      <TabPanel>
                        <div className="analytics-chart-mask">
                          <LineChartAnalytics data={uniqueDataArr} />
                        </div>
                      </TabPanel>
                      
                      
                      {/* Text chart | tab content */}
                      <TabPanel>
                        <div className="analytics-chart-mask">
                          <TextChartAnalytics data={uniqueDataArr} />
                        </div>
                      </TabPanel>
                    </>
                  ) : (
                    <WhiteCanvas
                      h2="Build visual with your data"
                      p="Select or drup and drop fields from report canvas"
                      icon={<img src={Drag} alt="drag" />}>
                    </WhiteCanvas>
                  )
                }
              </Tabs>
            </div>

            {/* sidebar */}
            {/* <DndProvider backend={HTML5Backend}> */}
            <div className="analytics-sidebar">
               
                <div>
                  {/* sidebar fields */}
                  <div className="analytics-sidebar-fileds">
                    {/* title */}
                    <div className="analytics-sidebar-title">
                      <h3>Fields</h3>
                    </div>

                    {/* data */}
                    <AnalyticsFields fileData={fileData} />
                  </div>
                  {/* sidebar labels */}
                  <div className="analytics-sidebar-labels">
                    {/* header */}
                    <div className="analytics-sidebar-title">
                      <h3>Axis</h3>
                    </div>
                    {/* data */}
                    <AnalyticsAxis yData={yData} setYData={setYData} xData={xData} setXData={setXData} setFilteredTableData={setFilteredTableData} filterdTableData={filterdTableData} tableData={tableData} fileData={fileData} toggleFunc={toggleFunc} xToggleFunc={xToggleFunc} filter={filter} setFilter={setFilter} xfilter={xfilter} setxFilter={setxFilter} toggleReset={toggleReset}/>
                  </div>
                </div>
            </div>
            {/* </DndProvider> */}
          </div>
        ) : (
          <div className="not-found-data">
            {/* if there is no data is our db then show the canvas */}
            <WhiteCanvas
              h2="Oops, No Data!"
              p="It seems you are not create any data yet. Please upload a file for analysis the data. "
            // icon={<AiOutlineCloudUpload />}
            >
              <div className="table-add-new">
                <Link to="/data">
                  <button className='common-button import-data-body-btn'>Import Data</button>
                </Link>
              </div>
            </WhiteCanvas>
          </div>
        )}
      </div>

    </>
  )
}

export default AnalyticsScreen

{/* <WhiteCanvas 
  h2="Build visual with your data" 
  p="Select or drup and drop fields from report canvas"
  icon={<img src={Drag} alt="drag" />}>
</WhiteCanvas> */}