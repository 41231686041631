import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import ReactAudioPlayer from 'react-audio-player'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { FaTelegramPlane } from 'react-icons/fa'
import { FiExternalLink } from 'react-icons/fi'
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { apiUrl } from '../Variables'
import textLogo from '../images/text-logo.png'
// product images
import { useRef } from 'react'
import MiniLoading from '../components/MiniLoading'
import logo from '../images/aceva.png'
import message from '../images/icons/message.svg'
import x from '../images/icons/times.svg'


import { BsXLg } from 'react-icons/bs'
import { GrRefresh } from 'react-icons/gr'
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

const options = [
  { value: 'en', label: 'English' },
  { value: 'ms', label: 'Malay' },
  { value: 'zh-CN', label: 'Chinese' },
];


function BotScreen({QueryParams, setQueryParams}) {
  const [toggle, setToggle] = useState(false); 

  let audio = new Audio("/message.mp3")
  const [resize, setResize] = useState(false);
  const [formToggle, setFormToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [width, setWidth] = useState(window.innerWidth); 
  const [emojiModel, setEmojiModel] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [language, setLanguage] = useState({source: 'en', target: ''})

  const [staticResp, setStaticResp] = useState([]);

  const [botObj, setBotObj] = useState({
    name: "",
    email: "",
    mobile: "",
    guest: "",
    roomType: "",
    date: "",
    cost: 0,
    meal: "",
    roomNumber: "",

  })

  // chatlists
  const [chatLists, setChatLists] = useState([]);
  const [userText, setUserText] = useState(''); 
  const [reset, setReset] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [deleteChats, seDeleteChats] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setBotObj({...botObj, date: `${start?.toLocaleDateString()} to ${end?.toLocaleDateString()}`})
    setStartDate(start);
    setEndDate(end);
  };

  console.log("botObj", botObj)

  const mobileViewport = () => {
    setWidth(window.innerWidth)
  }

  const chatRef = useRef(null)

  const tranlateFunc = async (txts) => {
      await axios.post(`${apiUrl}/gtranslate`, {
          "sourceLanguage": "",
          "targetLanguage": language.source,
          "text": txts
      }).then(res => {
        setStaticResp(res.data.translations.map(i => i.translatedText))
        return res.data.translations.map(i => i.translatedText)
      })
  }

  useEffect(() => {
    tranlateFunc([
      "Hello! Welcome to our hotel booking chatbot. How can I assist you today?",
      "Book a Room", "Room Services", "Payment & Checkout", "Wifi", "Pool & Gym", "Sightseeing"
    ])
    const fetchAllChats = async() => {
      try {
        const {data} = await axios.get(`${apiUrl}/bot/chatWithBot`)
        setChatLists(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchAllChats();
  },[reset, deleteChats, language])

  useEffect(()=> {
    chatRef.current?.scrollIntoView({ behavior: "smooth" })
  },[userText, chatLists, error])

  useEffect(() => {
    window.addEventListener('resize', mobileViewport)
    return () => window.removeEventListener('resize', mobileViewport)
    
  }, [])

  const handleToggle = (e) => {
    setToggle(!toggle);
  }

  // open chat and delete or reset existing chats
  const handleOpenChatAndResetChat = async () => {
    setToggle(!toggle);
    try {
      seDeleteChats(false)
      setDeleteLoading(true)
      const {data} = await axios.delete(`${apiUrl}/bot/chatWithBot`)
      seDeleteChats(true)
      setDeleteLoading(false)
      setError('')
      console.log(data)
    } catch (error) {
      console.log(error)
      seDeleteChats(false)
      setDeleteLoading(false)
    }
  }

  const handleInputSubmit = async (e, next, key) => {
    e?.preventDefault(); 

    if (key) {
      setBotObj({...botObj, [key]: next});
    }

    try {
      setLoading(true)
      setError('')
      const res = await axios.post(`${apiUrl}/bot/chatWithBot`, {user:userText || next, ln: language.source});
      setReset(!reset)
      setUserText('')
      setLoading(false)
      console.log(res)
    } catch (error) {
      console.log(error);
      setError(error?.message === "Request failed with status code 500" && "Sorry, I can't get it 😔")
      setLoading(false)
      setUserText('')
    }
  }

  // delete chats handler
  const deleteChatsHandler = async() => {
    try {
      seDeleteChats(false)
      setDeleteLoading(true)
      const {data} = await axios.delete(`${apiUrl}/bot/chatWithBot`)
      seDeleteChats(true)
      setDeleteLoading(false)
      setError('')
      console.log(data)
    } catch (error) {
      console.log(error)
      seDeleteChats(false)
      setDeleteLoading(false)
    }
  }

  console.log("bot obj", botObj?.name)

  return (
    <>      
        {/* <TestDialogflowReq /> */}
      <section className='chatbot-section bot-demo-wrapper'>
        <div className={resize ? "chatbot-widget-wrapper full-screen" : "chatbot-widget-wrapper"}>

            {/* chatbot widget wrapper */}
            <div className={toggle ? "chatbot-widget active" : "chatbot-widget"}  style={{background: 'white'}}>
              
              {/* chat header */}
              <div className="chatbot-widget-header">

                {/* agent presence */}
                <div className="chatbot-widget-agent-precence">

                  {/* bot times icon */}
                  <div className='chatbot-times-icon' onClick={handleToggle}>
                    <img src={x} alt="x" /> 
                  </div>

                  {/* agent left side */}
                  <div className="chatbot-widget-header-left">
                    {/* agent avatar */}
                    <div className="chatbot-recipient-avatar">
                        <img src={textLogo} alt="agent avatar" />
                        <div className="online-status"></div>
                    </div>

                    {/* agent details */}
                    <div className="chatbot-widget-recipient-details">
                      <p>Chat with</p>
                      <h3>Hotel Assistant
                      </h3>
                    </div>
                  </div>

                  {/* agent right side options */}
                  <div className="chatbot-widget-header-right">

                    {/* chatbot credit */}
                    {/* <div className='chatbot-creator'>
                      <p>Powered by</p>
                      <a href="https://www.wipdata.com/" target="_blank" rel="noreferrer">
                        <img src={logo} alt="site logo" />
                      </a>
                    </div> */}

                    <div className='chatbot-widget-header-right-options'>
                      {/* select box */}
                      <div className='faq-select-wrap'>
                        <select className='language-select-box' onChange={(e) => {
                          setLanguage({source: e.target.value, target: 'en'})
                          }}>
                          {
                            options.map(i => <option value={i.value}>{i.label}</option>)
                          }
                        </select>
                      </div>

                      {/* minimize */}
                      <div onClick={deleteChatsHandler} className="chatbot-widget-minimize delete-chat-icon">
                        <GrRefresh style={{width:'30px', height:'30px'}}/>
                        {/* <span>Close chat</span> */}
                      </div>

                      {/* minimize */}
                      <div className="chatbot-widget-minimize desktop-hide" onClick={handleToggle}>
                        <BsXLg />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* welcome */}
              {/* <div className="welcome-the-bot">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, minima?</p>
                <button>Start</button>
              </div> */}
              {/* chat conversation group body */}
              <div className="chat-conversation-wrapper bot-message-wrapper">
                {/* message body */}
                <div className="message-body chatbot-message-body">
                  <ul>
                    { deleteLoading && <MiniLoading /> }

                      <li>
                        <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            <p className='message-bubble-agent'>{ staticResp[0] || "Hello! Welcome to our hotel booking chatbot. How can I assist you today?"}</p>
                            <div className="message-auto-suggest-buttons">
                                {/* <button onClick={() => handleInputSubmit(null, "Room availability")} className='suggest-btn'> Room availability </button> */}
                                <button onClick={() => handleInputSubmit(null, "Book a Room")} className='suggest-btn'>
                                      <span>{staticResp[1] || 'Book a Room'}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Room Services")} className='suggest-btn'>
                                      <span>{staticResp[2] || 'Room Services'}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Payment and Checkout")} className='suggest-btn'>
                                      <span>{staticResp[3] || 'Payment & Checkout'}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Wifi")} className='suggest-btn'>
                                      <span>{staticResp[4] || 'Wifi'}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Pool and Gym")} className='suggest-btn'>
                                      <span>{staticResp[5] || 'Pool & Gym'}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Sightseeing")} className='suggest-btn'>
                                      <span>{staticResp[6] || 'Sightseeing'}</span>
                                </button>
                              </div>
                          </div>
                      </li>

                  {/* conversations */}
                  {
                    chatLists?.map((item, index) => (
                      <div key={index}>

                        {/* user */}
                        <li className="message-bubble-visitor">
                          <span className=''>{item?.user}</span>
                        </li>

                        {/* bot response */}
                        <li>
                            <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            {
                              item?.chat?.response === "" ? (
                                <>
                                  <p className='message-bubble-agent'>{`Thank you, ${botObj?.name}. Your confirmation details are as below :`} </p>
                                  <div className='message-bubble-agent booking-summery-wrap'>
                                    <li>Room Type: <span className='sum-item-badge sum-item-room-type-badge'>{botObj?.roomType}</span></li>
                                    <li>Booking Date: <span className='sum-item-badge'>{botObj?.date} </span> </li>
                                    <li>Number of Guests: <span className='sum-item-badge'>0{botObj?.guest}</span> </li>
                                    <li>Total Cost: <span className='sum-item-badge'>$1080</span></li>
                                  </div>
                                  <p className='message-bubble-agent booking-end-msg'>Here are payment methods you can choose to pay.</p>

                                  <div className="message-auto-suggest-buttons">
                                    <button onClick={() => handleInputSubmit(null, "Book a Room")} className='suggest-btn'>
                                          <span>Paypal</span>
                                    </button>

                                    <button onClick={() => handleInputSubmit(null, "Room Services")} className='suggest-btn'>
                                          <span>Stripe</span>
                                    </button>

                                    <button onClick={() => handleInputSubmit(null, "Payment and Checkout")} className='suggest-btn'>
                                          <span>Apple Pay</span>
                                    </button>
                                  </div>
                                  
                                  <p className='message-bubble-agent booking-end-msg payment-question-text'>Is there anything else today?</p>
                                </>
                              ) : (
                                <p className='message-bubble-agent'>{item?.chat?.response} </p>
                              )
                            }

                            {/* if button, then show the button layout */}
                            {item?.chat?.payloadType === "button" && (
                              <div className="message-auto-suggest-buttons">
                                
                                {
                                  item?.chat?.payload?.map((btn, btnIndex) => (
                                    btn?.next === "new chat" ? (
                                      <button onClick={deleteChatsHandler} className='suggest-btn' key={btnIndex}>
                                      <span>{btn?.text}</span>
                                    </button>
                                    ) : (
                                      <button onClick={() => handleInputSubmit(null, btn?.next, btn?.key)} className='suggest-btn' key={btnIndex}>
                                      <span>{btn?.text}</span>
                                    </button>
                                    )
                                    
                                  ))
                                }
                              
                              </div>
                            )}

                            {/* if link, then show the link layout */}
                            {item?.chat?.payloadType === "link" && (
                              <div className="message-auto-suggest-buttons">
                                
                                {
                                  item?.chat?.payload?.map((linkItem, index) => (
                                    <a key={index} className="extarnal-link" href={`${linkItem?.next}`}>{linkItem?.text} <FiExternalLink /></a>
                                  ))
                                }

                                {/* <p className='message-bubble-agent booking-end-msg'>Is there anything else today <span className='highlight-name'>{botObj?.name}?</span> Here is some suggestions for you. </p>

                                <button onClick={() => handleInputSubmit(null, "Book a Room")} className='suggest-btn'>
                                      <span>Book a Room</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Room Services")} className='suggest-btn'>
                                      <span>Room Services</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Payment and Checkout")} className='suggest-btn'>
                                      <span>Payment & Checkout</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Wifi")} className='suggest-btn'>
                                      <span>Wifi</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Pool and Gym")} className='suggest-btn'>
                                      <span>Pool & Gym</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Sightseeing")} className='suggest-btn'>
                                      <span>Sightseeing</span>
                                </button>

                                <button onClick={deleteChatsHandler} className='suggest-btn'>
                                      <span>Close the chat</span>
                                </button>                            */}
                              
                              </div>
                            )}

                            {/* pay */}
                            {/* if link, then show the link layout */}
                            {item?.chat?.payloadType === "pay" && (
                              <div className="message-auto-suggest-buttons">

                                {
                                  item?.chat?.payload?.map((btn, btnIndex) => (
                                    <a key={btnIndex} className="extarnal-link" href={`${btn?.next}`}>{btn?.text} <FiExternalLink /></a>                               
                                  ))
                                }
                                
                                <p className='message-bubble-agent booking-end-msg'>Is there anything else today <span className='highlight-name'>{botObj?.name}?</span> </p>

                                <button onClick={() => handleInputSubmit(null, "close")} className='suggest-btn'>
                                      <span>No</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "new chat")} className='suggest-btn'>
                                      <span>Yes</span>
                                </button>
                              
                              </div>
                            )}

                            {/* if date, then show the date layout */}
                            {item?.chat?.payloadType === "date" && (
                              <div className="booking-date-wrapper">
                                <DatePicker 
                                  selected={startDate}
                                  onChange={onChange}
                                  startDate={startDate}
                                  endDate={endDate}
                                  selectsRange
                                  className="booking-date-field"
                                  inline
                                />

                                <button onClick={() => handleInputSubmit(null, "date selected")} className='suggest-btn'>Next</button>
                              </div>
                            )}

                            {/* if link, then show the link layout */}
                            {item?.chat?.payloadType === "link" && (
                              <div className="message-auto-suggest-buttons">
                              
                              </div>
                            )}

                            {/* if date, then show the date layout */}
                            {item?.chat?.payloadType === "form" && (
                              <div className="booking-date-wrapper">
                                {
                                  item?.chat?.payload?.map((input, inputIndex) => (
                                    <div className="form-group bot-form" key={inputIndex}>
                                      <label>{input?.text}</label>
                                      <input 
                                      onChange={(e) => setBotObj({
                                        ...botObj, [input?.next]: e.target.value
                                      })} 
                                      type="text" placeholder={input?.text} />
                                    </div>
                                  ))
                                }

                                <button onClick={() => handleInputSubmit(null, "submitted")} className='suggest-btn bot-form-btn'>Next</button>
                              </div>
                            )}

                            {/* if room number input form, then show the room number input form layout */}
                            {item?.chat?.payloadType === "room number selected" && (
                              <div className="booking-date-wrapper">
                                {
                                  item?.chat?.payload?.map((input, inputIndex) => (
                                    <div className="form-group bot-form" key={inputIndex}>
                                      <label>{input?.text}</label>
                                      <input 
                                      onChange={(e) => setBotObj({
                                        ...botObj, [input?.next]: e.target.value
                                      })} 
                                      type="text" placeholder={input?.text} />
                                    </div>
                                  ))
                                }

                                <button onClick={() => handleInputSubmit(null, "room number selected")} className='suggest-btn bot-form-btn'>Next</button>
                              </div>
                            )}

                            {/* if room selected for food & vevarage, then show the room selected for food & vevarage layout */}
                            {item?.chat?.payloadType === "room selected" && (
                              <div className="booking-date-wrapper">
                                {
                                  item?.chat?.payload?.map((input, inputIndex) => (
                                    <div className="form-group bot-form" key={inputIndex}>
                                      <label>{input?.text}</label>
                                      <input 
                                      onChange={(e) => setBotObj({
                                        ...botObj, [input?.next]: e.target.value
                                      })} 
                                      type="text" placeholder={input?.text} />
                                    </div>
                                  ))
                                }

                                <button onClick={() => handleInputSubmit(null, "room selected")} className='suggest-btn bot-form-btn'>Next</button>
                              </div>
                            )}

                            {/* if cleaning service room number form, then show the cleaning service room number form layout */}
                            {item?.chat?.payloadType === "number-of-room-for-cleaning" && (
                              <div className="booking-date-wrapper">
                                {
                                  item?.chat?.payload?.map((input, inputIndex) => (
                                    <div className="form-group bot-form" key={inputIndex}>
                                      <label>{input?.text}</label>
                                      <input 
                                      onChange={(e) => setBotObj({
                                        ...botObj, [input?.next]: e.target.value
                                      })} 
                                      type="text" placeholder={input?.text} />
                                    </div>
                                  ))
                                }

                                <button onClick={() => handleInputSubmit(null, "room number submit for cleaning")} className='suggest-btn bot-form-btn'>Next</button>
                              </div>
                            )}

                            {/* if payment room number, then show the payment room number layout */}
                            {item?.chat?.payloadType === "room-number-for-payment" && (
                              <div className="booking-date-wrapper">
                                {
                                  item?.chat?.payload?.map((input, inputIndex) => (
                                    <div className="form-group bot-form" key={inputIndex}>
                                      <label>{input?.text}</label>
                                      <input 
                                      onChange={(e) => setBotObj({
                                        ...botObj, [input?.next]: e.target.value
                                      })} 
                                      type="text" placeholder={input?.text} />
                                    </div>
                                  ))
                                }

                                <button onClick={() => handleInputSubmit(null, "balance history")} className='suggest-btn bot-form-btn'>Next</button>
                              </div>
                            )}

                            {/* if email address input, then show the email address input layout */}
                            {item?.chat?.payloadType === "email-address" && (
                              <div className="booking-date-wrapper">
                                {
                                  item?.chat?.payload?.map((input, inputIndex) => (
                                    <div className="form-group bot-form" key={inputIndex}>
                                      <label>{input?.text}</label>
                                      <input 
                                      onChange={(e) => setBotObj({
                                        ...botObj, [input?.next]: e.target.value
                                      })} 
                                      type="text" placeholder={input?.text} />
                                    </div>
                                  ))
                                }

                                <button onClick={() => handleInputSubmit(null, "email address")} className='suggest-btn bot-form-btn'>Next</button>

                                {/* <p className='message-bubble-agent booking-end-msg'>Is there anything else today <span className='highlight-name'>{botObj?.name}?</span> Here is some suggestions for you. </p> */}
                              </div>
                            )}

                            {/* if close, then show the close layout */}
                            {item?.chat?.payloadType === "close" && (
                            <div className="message-auto-suggest-buttons">

                                {
                                  item?.chat?.payload?.map((btn, btnIndex) => (
                                    <button onClick={() => handleInputSubmit(null, btn?.next)} className='suggest-btn' key={btnIndex}>
                                      <span>{btn?.text}</span>
                                    </button>
                                  ))
                                }
                                
                              </div>
                            )}

                            {/* if curosol, then show the curosol layout */}
                            {item?.chat?.payloadType === "curosol" && (
                              <div style={{width:'300px'}}>
                                <Swiper
                                slidesPerView={3}
                                spaceBetween={10}
                                slidesPerGroup={1}
                                centeredSlides={true}
                                loop={true}
                                loopFillGroupWithBlank={false}
                                navigation={true}
                                modules={[Navigation]}
                                className="chatbot-swiper-container"
                              >
                                {
                                    item?.chat?.payload?.map((btn, btnIndex) => (
                                      <SwiperSlide onClick={() => handleInputSubmit(null, btn?.next, btn?.key)} key={btnIndex}>
                                        <div className="curosol-item" key={btnIndex}>
                                          <img src={btn?.image} alt={btn?.text} />
                                          <p className='suggest-btn'>{btn?.text}</p>
                                        </div>
                                      </SwiperSlide>
                                    ))
                                }
                              
                              </Swiper>
                            </div>
                            // <div className="message-auto-suggest-buttons">

                            //   <div className="curosol-wrap">
                            //     {
                            //       // item?.chat?.payload?.map((btn, btnIndex) => (
                            //       //     <div onClick={() => handleInputSubmit(null, btn?.next)} className="curosol-item" key={btnIndex}>
                            //       //       <img src={btn?.image} alt={btn?.text} />
                            //       //       <p className='suggest-btn'>{btn?.text}</p>
                            //       //     </div>
                            //       // ))
                            //     }
                            //     </div>
                                
                            //   </div>
                            )}

                          </div>
                          
                        </li>
                      </div>
                    ))
                  }


                  {/* babble typing... */}
                  {loading && (
                    <li>
                      <img className='agent-logo' src={logo} alt="bot" />
                      <div className="typing-wrap message-bubble-agent">
                        <span className="tying">Typing</span>
                        <span className="animate-typing">
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                        </span>
                      </div>
                    </li>
                  )}
                  {
                    error && (
                      <li>
                        <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            <p className='message-bubble-agent'>{error}</p>
                          </div>
                      </li>
                    )
                  }
                  <li ref={chatRef}></li>
                </ul>
                </div>
              </div>

              {/* chat footer */}
              <div className="chatbot-footer">
                <form onSubmit={handleInputSubmit}>
                    <div className="chatbot-footer-input-box mini-bot-footer">
                      <input value={userText} onChange={(e) => setUserText(e.target.value) } type="text" placeholder='Start conversation...' />
                      <button className='chatbot-send-message-button'>
                        <FaTelegramPlane />
                      </button>
                    </div>
                </form>
            </div>
          </div>

            {/* chatbot open icon && if resize is true the hide chatbot icon */}
            <div className={toggle ? 'chatbot-mini-preview-wrap active' : 'chatbot-mini-preview-wrap'} onClick={handleOpenChatAndResetChat}>
              <div className="chatbot-mini-preview-left">
                <img className='bot-preview-img' src={message} alt="message icon" /> 
                <span className='send-us-message'>Send us a message</span>
              </div>
              <div className="chatbot-mini-preview-right">
                <h5>ACEVA BOT</h5>
              </div>
            </div>
            {/* <ReactAudioPlayer
              src={audio}
              controls
              className='audio-hidden'
            /> */}
        </div>
        </section>
    </>
  )
}

export default BotScreen