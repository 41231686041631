import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MdCloudUpload } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MiniLoading from '../components/MiniLoading';
import { useAppContext } from '../context/AppContext';
import logo from '../images/cat.png';
import { apiUrl } from '../Variables';

function UserProfile() {

  const [user, setUser] = useState({})
  const {userInfo} = useAppContext(); 
  const [password, setPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [passError, setPassError] = useState(false)
  const [thumb, setThumb] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fetchUserloading, setFetchUserLoading] = useState(false)

  useEffect(() => {
    const fetchUser = async () => {
      setFetchUserLoading(true)
      const {data} = await axios.get(`${apiUrl}/user/${userInfo._id}`)
      setUser(data)
      setFetchUserLoading(false)
    }
    fetchUser()

    // check if password is not matched thorw an error
    if(passError) {
      toast.error("Password not matched!", {
        progress: undefined,
        className: "password-error",
      })
    }

    // if successfully updated then show success message
    if(updateSuccess) {
      toast.success("Your profile updated successfully!", {
        progress: undefined,
        className: "password-error",
      })
    }

  },[userInfo, passError, updateSuccess])

  // profile photo handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "pewhglsn")
    formData.append("cloud_name", "dzo2vmjdy")

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, config)
      setLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, config)
      setUser({...user, profile: data?.url})
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  // update handler
  const handleUserProfileUpdate = async (e) => {
    e.preventDefault();

    // check if password is match with db
    setPassError(false)
    if(!password === '' && password !== userInfo?.password) {
      setPassError(!passError)
      return
    }

    const config = {
      headers: {
        'Accept': 'application/json',
        "Content-Type": 'application/json'
      }
    }
    setUpdateSuccess(false)
    const {data} = await axios.put(`${apiUrl}/user/${user._id}`, user, config)
    data && localStorage.setItem('userInfo', JSON.stringify(data))
    setUpdateSuccess(true)
    console.log(data)
  }

  useEffect(() => {
     // if confirm password 
     if(password) {
      setUser({...user, password: password})
    }
  },[password, userInfo])

  return (
    <>
     <ToastContainer />
      <section className='user-profile-section p-20'>
            { fetchUserloading && <MiniLoading /> }
        <form onSubmit={handleUserProfileUpdate}>
          <div className="user-profile-header-wrapper">

            <div className="user-profile-header-card">
              {/* upload profile photo */}
              <label>
                <div className="user-profile-header-thumb">
                {loading && <MiniLoading />}
                  <img src={user?.profile ? `${user?.profile}` : logo} alt="profile photo" />
                  <div className="user-update-profile create-user-profile-update-icon-wrapper">
                    <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                    <div className="user-update-profile">
                      <MdCloudUpload className='user-update-icon' />
                    </div>
                  </div>
                </div>
              </label>

              <div className="user-profile-content">
                <h2>✋ Hi, <span className="user-title-name">{user?.name}</span></h2>

                <div className="user-profile-role-and-department">
                  <span className="badge editor-badge">{user?.role}</span>
                  <span className="badge department-badge">{user?.department}</span>
                </div>

                {/* date */}
                <div className="user-join-date">
                  <p>Joined: {moment(user?.createdAt).fromNow()}</p>
                </div>
              </div>
            </div>

            {/* updated at */}
            <div className="user-join-date user-update-date">
              <p>Last Update: {moment(user?.updatedAt).fromNow()}</p>
            </div>
          </div>


          {/* user field or body */}
          <div className="user-profile-body">
            <div className='form-field-wrapper'>

              {/* Website name */}
              <div className="form-group">
                <label htmlFor="name">Full name</label>
                <input type="text" id='name' onChange={(e) => setUser({...user, name: e.target.value})} value={user?.name} placeholder='Your name' />
              </div>

              {/* Email and Phone number */}
              <div className="form-row">
                {/* Email address */}
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input type="email" onChange={(e) => setUser({...user, email: e.target.value})} value={user?.email} id='email' />
                </div>

                {/* Phone number */}
                <div className="form-group">
                  <label htmlFor="number">Phone number</label>
                  <input type="text" onChange={(e) => setUser({...user, phone: e.target.value})} value={user?.phone} id='number' />
                </div>
              </div>

              {/* user role and department */}
              <div className="form-row">

                {/* Role */}
                <div className="form-group">
                  <label htmlFor="isAdmin">Role</label>
                  {
                    user?.role === 'admin' ? (
                      <select onChange={(e) => setUser({...user, role: e.target.value})} value={user?.role} id="isAdmin">
                        <option disabled value="admin">Admin</option>
                        <option disabled value="editor">Editor</option>
                        <option disabled value="user">User</option>
                      </select>
                    ) : (
                      <select value={user?.role} id="isAdmin" disabled className='disabled'>
                        <option value={user?.role}>{user?.role}</option>
                      </select>
                    )
                  }
                </div>

                {/* Department */}
                <div className="form-group">
                  <label htmlFor="isAdmin">Department</label>
                  <select onChange={(e) => setUser({...user, department: e.target.value})} value={user?.department} id="isAdmin">
                    <option value="sales">Sales</option>
                    <option value="human-resource">Human Resource</option>
                    <option value="account">Account</option>
                    <option value="finance">Finance</option>
                    <option value="audit">Audit</option>
                  </select>
                </div>
              </div>

              {/* Password and set password */}
              <div className="form-row">
                {/* old password */}
                <div className="form-group">
                  <label htmlFor="oldPassword">Old password</label>
                  <input type="password" 
                  onChange={(e) => {
                    setOldPassword(e.target.value)
                  }} 
                  placeholder='Old password' id='oldPassword' />
                </div>

                {/* new password */}
                <div className="form-group">
                  <label htmlFor="newPassword">New password</label>
                  <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder='New password' id='newPassword' />
                </div>
              </div>

              <button type='submit' className='form-button'>
                Save changes
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  )
}

export default UserProfile