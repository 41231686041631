import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { BsThreeDots } from 'react-icons/bs'
import { FaHospital, FaListAlt, FaUser } from 'react-icons/fa'
import { FiDownload } from 'react-icons/fi'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { kpisUrl } from '../../Variables'
import NewAreaChart from '../../components/charts/NewAreaChart'
import NewBarChart from '../../components/charts/NewBarChart'

import 'react-tabs/style/react-tabs.css'
import LineChartComponent from '../../components/charts/LineChartComponent'
import PieChartComponent from '../../components/charts/PieChartComponent'
import userTwo from '../../images/user (1).png'
import userOne from '../../images/user.png'


function HomeScreen() {
  const [date, setDate] = useState({
    startDate: new Date(new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() - 1)).toISOString().split('T')[0],
    endDate: new Date(Date.now()).toISOString().split('T')[0]
  })
  console.log("is mobile", isMobile)

  // const navigate = useNavigate();
  const [kpis, setKpis] = useState({})
  useEffect(() => {
    axios.get(`${kpisUrl}?startDate=${date.startDate}&endDate=${date.endDate}`).then(res => {
      console.log('kpisUrl', res)
      setKpis(res.data)
    }).catch(err => {
      console.log(err)
    })
  },[date])

  const xeroData = []

  return (
    <>
      <div className="container">

        {/* new design */}
        <div className="dashboard-new-design-wrapper">

          {/* header */}
          <div className="dashboard-new-design-header">
            {/* left */}
            <div className="dashboard-header-left">
              <h3>Analytics Dashboard</h3>
            </div>
            {/* right */}
            <div className="dashboard-header-right">
              <div className="date-filter">
                <h6>From</h6>
                <div className='date-item' style={{display:'flex', alignItems:'center'}}>
                  <input value={date.startDate} onChange={(e) => setDate({...date, startDate: e.target.value})} style={{height:'30px'}} type='date'/> 
                  <h6 style={{margin:'0 5px'}}>to</h6> 
                  <input value={date.endDate} onChange={(e) => setDate({...date, endDate: e.target.value})} style={{height:'30px'}} type='date'/></div>
                </div>
                <div className="download-btn-wrap">
                  <FiDownload className="dwn-btn" />
                </div>
            </div>
          </div>

          {/* kpi tab wrapper */}
          <Tabs>
              <TabList className='dashboard-kpi-wrapper'>

                {/* tab header item */}
                <Tab className="dashboard-kpi-item da-kpi-one">
                  <div className="kpi-item-header">
                    <h4>Total Interactions</h4>
                    <p>Today {kpis.todayInteraction || 0}</p>
                  </div>
                  <div className="kpi-item-body">
                    <h2>{kpis.totalInteraction || 0}</h2>
                  </div>
                  <div className="kpi-item-footer"></div>
                </Tab>

                {/* tab header item */}
                <Tab className="dashboard-kpi-item da-kpi-one">
                  <div className="kpi-item-header">
                    <h4>Average Chat Duration</h4>
                    <p>Today {kpis.todayAvgChatDuration === 'NaN' ? 0 : kpis.todayAvgChatDuration}</p>
                  </div>
                  <div className="kpi-item-body">
                    <h2>{kpis.totalAvgChatDuration === 'NaN' ? 0 : kpis.totalAvgChatDuration || 0}<span className='sec-badge'>Min</span></h2>
                  </div>
                  <div className="kpi-item-footer"></div>
                </Tab>

                {/* tab header item */}
                <Tab className="dashboard-kpi-item da-kpi-one">
                  <div className="kpi-item-header">
                    <h4>Response Time</h4>
                    <p>Today {kpis.todayAvgChatDuration === 'NaN' ? 0 : kpis.todayAvgesponseTime}s</p>
                  </div>
                  <div className="kpi-item-body">
                    <h2>{kpis.totalAvgResponseTime === 'NaN' ? 0 : kpis.totalAvgResponseTime || 0}<span className='sec-badge'>Sec</span></h2>
                  </div>
                  <div className="kpi-item-footer"></div>
                </Tab>

                {/* tab header item */}
                <Tab className="dashboard-kpi-item da-kpi-one">
                    <div className="kpi-item-header">
                    <h4>Retention Rate</h4>
                    <p>Today {kpis.todayRetentionRate}%</p>
                  </div>
                  <div className="kpi-item-body">
                    <h2>{kpis.totalRetentionRate === 'NaN' ? 0 : kpis.totalRetentionRate || 0}<span className='sec-badge'>%</span></h2>
                  </div>
                  <div className="kpi-item-footer"></div>
                </Tab>
              </TabList>  

              {/* tab content wrapper */}
              <div className="tab-content-wrapper">
                {/* dynamic tab content */}
                <div className="chart-wrapper">
            
                {/* line chart */}
                <div className="chart-item">

                  {/* Total Interactions */}
                  <TabPanel>
                    <div className="kpi-dynamic-title">
                    Total Interactions <span className='role-badge interaction-count-badge'>{kpis.totalInteraction || 0}</span>
                    </div>
                    <NewAreaChart keys = {['Date', 'User']} plotdata={kpis.interactionArr?.sort((a, b) => new Date(a.date) - new Date(b.date))?.map(i => {return {Date: i.date, User: i.user_count}})}/>
                  </TabPanel>

                  {/* Average Chat Duration */}
                  <TabPanel>
                    <div className="kpi-dynamic-title">
                    Average Chat Duration <span className='role-badge interaction-count-badge'>{kpis.totalAvgChatDuration === 'NaN' ? 0 : kpis.totalAvgChatDuration || 0}min</span>
                    </div>
                    <NewAreaChart keys = {['Date', 'Minutes']} plotdata={kpis.chatDurationArr?.sort((a, b) => new Date(a.date) - new Date(b.date))?.map(i => {return {Date: i.date, Minutes: i.AvgChatDuration}})}/>
                  </TabPanel>

                  {/* Response Time */}
                  <TabPanel>
                    <div className="kpi-dynamic-title">
                    Response Time <span className='role-badge interaction-count-badge'>{kpis.totalAvgResponseTime === 'NaN' ? 0 : kpis.totalAvgResponseTime || 0}s</span>
                    </div>
                    <NewAreaChart  keys = {['Date', 'Second']} plotdata={kpis.responseTimeArr?.sort((a, b) => new Date(a.date) - new Date(b.date))?.map(i => {return {Date: i.date, Second: i.AvgResponseTime}})}/>
                  </TabPanel>

                  {/* Retention Rate */}
                  <TabPanel>
                    <div className="kpi-dynamic-title">
                    Retention Rate <span className='role-badge interaction-count-badge'>{kpis.totalRetentionRate || 0}%</span>
                    </div>
                    <NewAreaChart  keys = {['Date', 'Rate']} plotdata={kpis.retentionArr?.sort((a, b) => new Date(a.date) - new Date(b.date))?.map(i => {return {Date: i.date, Rate: i.RetentionRate}})}/>
                  </TabPanel>
                </div>

                {/* bar chart */}
                <div className="chart-item">
                  {/* first column */}
                  <Tabs>
                    <TabList className='kpi-tab-header-wrap'>
                        <Tab>Platform</Tab>
                        <Tab>Device</Tab>
                        <Tab>Age</Tab>
                        <Tab>Gender</Tab>
                    </TabList>

                    <TabPanel>
                      <NewBarChart />
                    </TabPanel>

                    <TabPanel>
                      <NewBarChart />
                    </TabPanel>

                    <TabPanel>
                      <NewBarChart />
                    </TabPanel>

                    <TabPanel>
                      <NewBarChart />
                    </TabPanel>

                  </Tabs>
                </div>
              </div>

            </div>

          </Tabs>
        </div>

        {/* featured status */}
        {/* mobile version KPI's */}
        <div className="mobile-version-kpi-wrapper display-none">
          <div className="featured-status-item mobile-kpi-item mobile-kpi-item-one">
            <div className="featured-top-content">
              <div className='featured-status-content mobile-kpi-content'>
                <h3>Appointments</h3>
                <h1>{kpis?.appointment_count}</h1>
              </div>
              <figure className='featured-status-thumb'>
                <img src={userOne} alt="current user" />
                <FaHospital />
              </figure>
            </div>
          </div>
          <div className="featured-status-item mobile-kpi-item mobile-kpi-item-two">
            <div className="featured-top-content">
              <div className='featured-status-content mobile-kpi-content'>
                <h3>Users</h3>
                <h1>{kpis?.user_count}</h1>
              </div>
              <figure className='featured-status-thumb'>
                <img src={userTwo} alt="current user" />
                <FaUser />
              </figure>
            </div>
          </div>
          <div className="featured-status-item mobile-kpi-item mobile-kpi-item-three">
            <div className="featured-top-content">
              <div className='featured-status-content mobile-kpi-content'>
                <h3>Prescription</h3>
                <h1>{kpis?.prescription_refill_count}</h1>
              </div>
              <figure className='featured-status-thumb'>
                <img src={userOne} alt="current user" />
                <FaListAlt />
              </figure>
            </div>
          </div>
          <div className="featured-status-item mobile-kpi-item mobile-kpi-item-one">
            <div className="featured-top-content">
              <div className='featured-status-content mobile-kpi-content'>
                <h3>Sales</h3>
                <h1>{kpis?.appointment_count}</h1>
              </div>
              <figure className='featured-status-thumb'>
                <img src={userOne} alt="current user" />
                <FaHospital />
              </figure>
            </div>
          </div>
          <div className="featured-status-item mobile-kpi-item mobile-kpi-item-two">
            <div className="featured-top-content">
              <div className='featured-status-content mobile-kpi-content'>
                <h3>Response time</h3>
                <h1>{kpis?.user_count}</h1>
              </div>
              <figure className='featured-status-thumb'>
                <img src={userTwo} alt="current user" />
                <FaUser />
              </figure>
            </div>
          </div>
          <div className="featured-status-item mobile-kpi-item mobile-kpi-item-three">
            <div className="featured-top-content">
              <div className='featured-status-content mobile-kpi-content'>
                <h3>Avg. Chat Duration</h3>
                <h1>{kpis?.prescription_refill_count}</h1>
              </div>
              <figure className='featured-status-thumb'>
                <img src={userOne} alt="current user" />
                <FaListAlt />
              </figure>
            </div>
          </div>
        </div>

        {/* desktop version KPI's */}
        <div className='blank'>

          {/* item */}
          {/* <div className="featured-status-item">
            <div className="featured-top-content">
              <div className='featured-status-content'>
                <h3>Current User</h3>
                <h1>500</h1>
              </div>
              <figure className='featured-status-thumb'>
                <img src={userOne} alt="current user" />
              </figure>
            </div>
            <div className="featured-bottom-content">
              <BiTrendingUp className='up-icon' />
              <span className="percent-of-up">30%</span>
              <span>Free previous period</span>
            </div>
          </div> */}

          {/* <img className='temp' src={temp} alt="" /> */}

            <Tabs>
              <TabList className="featured-status-wrapper">
                <Tab className="featured-status-item">
                  <div className="featured-top-content">
                    <div className='featured-status-content'>
                      <h3>{ isMobile ? "Appointment" : "Number of booking appointments" }</h3>
                      <h1>{kpis?.appointment_count}</h1>
                    </div>
                    <figure className='featured-status-thumb'>
                      {/* <img src={userOne} alt="current user" /> */}
                      <FaHospital />
                    </figure>
                  </div>
                </Tab>
                <Tab className="featured-status-item">
                  <div className="featured-top-content">
                    <div className='featured-status-content'>
                      <h3>Number Of Users</h3>
                      <h1>{kpis?.user_count}</h1>
                    </div>
                    <figure className='featured-status-thumb'>
                      <img src={userTwo} alt="current user" />
                    </figure>
                  </div>
                </Tab>
                <Tab className="featured-status-item">
                  <div className="featured-top-content">
                    <div className='featured-status-content'>
                      <h3>Number of Prescription refills</h3>
                      <h1>{kpis?.prescription_refill_count}</h1>
                    </div>
                    <figure className='featured-status-thumb'>
                      <img src={userOne} alt="current user" />
                    </figure>
                  </div>
                </Tab>
              </TabList>

              {/* appointment details */}
              <TabPanel className="dashboard-app-table-wrap">
                <div className="xero-table-wrapper">
                  <table>
                      {/* header */}
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>Specialist Department</th>
                        <th>Doctor</th>
                        <th>Schedule</th>
                        <th>Symptom</th>
                        <th>Created Date</th>
                      </tr>
                      
                      {/* table body */}
                      <tbody>
                        {/* item */}
                        {
                          kpis?.appointments?.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.name}</td>
                              <td>{item?.mobile}</td>
                              <td>{item?.age}</td>
                              <td>{item?.gender}</td>
                              <td>{item?.department}</td>
                              <td>{item?.doctor}</td>
                              <td>{item?.schedule}</td>
                              <td>{item?.symptom?.toString()}</td>
                              <td>{new Date(item?.createdAt || Date.now()).toLocaleDateString()}</td>
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>
                </div>
              </TabPanel>

              {/* Number Of Users */}
              <TabPanel className="dashboard-app-table-wrap">
                <div className="xero-table-wrapper">
                  <table>
                      {/* header */}
                      <tr>
                        <th>Users' IP Address</th>
                      </tr>
                      
                      {/* table body */}
                      <tbody>
                        {/* item */}
                        {
                          kpis?.user_list?.map((item, index) => (
                            <tr key={index}>
                              <td>{item}</td>
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>
                </div>
              </TabPanel>

              {/* Number of Prescription refills */}
              <TabPanel className="dashboard-app-table-wrap">
                <div className="xero-table-wrapper">
                  <table>
                      {/* header */}
                      <tr>
                        <th>Customer ID</th>
                        <th>Medicines</th>
                        <th>Refill Requested Date</th>
                      </tr>
                      
                      {/* table body */}
                      <tbody>
                        {/* item */}
                        {
                          kpis?.prescriptions?.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.name}</td>
                              <td>{item?.symptom?.toString()}</td>
                              <td>{new Date(item?.createdAt || Date.now()).toLocaleDateString()}</td>
                            </tr>
                          ))
                        }

                      </tbody>
                    </table>
                </div>
              </TabPanel>
            </Tabs>

        </div>

        {/* chart wrapper */}
        <div className="chart-wrapper display-none">

          <div className="chart-item">
            {/* header */}
            <div className="chart-header">
              <h3>Client Business Study</h3>
              <div className='chart-header-option'>
                <BsThreeDots />
              </div>
            </div>
            <LineChartComponent />
          </div>
          <div className="chart-item">
            {/* header */}
            <div className="chart-header">
              <h3>Client Revenew Strategy</h3>
              <div className='chart-header-option'>
                <BsThreeDots />
              </div>
            </div>
            <PieChartComponent />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeScreen