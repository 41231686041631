import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiUrl } from '../Variables';

const AppContext = createContext({})
const AppContextProvider = (props) => {

    const [sidebarToggle, setSidebarToggle] = useState(false)

    // get local storage token and user data
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

    // const {userInfo} = useAppContext(); 
    const navigate = useNavigate(); 
    const location = useLocation();
    // const [isUpdated,setIsUpdated] = useState(false)   
    useEffect(() => {
        if(!userInfo && location.pathname !== "/forgot-password" && location.pathname !== "/ABMSBUC" && location.pathname !== "/bot-demo" && location.pathname !== "/register" && !location.pathname.includes("/reset-password/")) {
            navigate('/login')
        }
    },[userInfo, navigate, location])

    const { children, values = {} } = props
    return (
        <AppContext.Provider
            value={{
                userInfo,
                apiUrl,
                sidebarToggle,
                setSidebarToggle,
                ...values
            }}
        >
            {children}
        </AppContext.Provider>

    )
}
const useAppContext = () => useContext(AppContext)
export { AppContextProvider, useAppContext };

