import React, { Fragment, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Navigation, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import logo from '../../images/aceva.png'
import textLogo from '../../images/oren.png'

function HealthCareBot({language, chat, error, handleSendMessage, message, setMessage, symptoms}) {
  const [output, setOutput] = useState('')
  const [medichines, setMedichines] = useState([]);
  const chatRef = useRef(null)
  const [scheduleDate, setScheduleDate] = useState();
  //formate the json payload coming from rasa
  const formatePayload = (payload) => {
    const pload = JSON.parse(payload.replace(/None/g, "\"null\"").replace(/\'/g, "\"").replace(/ -/g, "").replace(/\"\[/g, "\[").replace(/\]\"/g, "\]"))
    console.log('payload formated', pload)
    return pload || 0
  }
  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: "smooth" })
    error && toast('Error')
  },[message, chat])
  useEffect(() => {
    setOutput('')
    let i = 0;
    let speed = 40; /* The speed/duration of the effect in milliseconds */
    let oo = ''
    function typeWriter() {
      if (chat[chat.length - 1]?.bot?.length > 0 && i < chat[chat?.length - 1]?.bot[chat[chat?.length - 1]?.bot.length - 1]?.text?.length) {
        oo = `${oo}${chat[chat.length - 1]?.bot[chat[chat?.length - 1]?.bot.length - 1]?.text?.charAt(i)}`;
        setOutput(oo)
        i++;
        setTimeout(typeWriter, speed);
      }
    }
    typeWriter()
  }, [chat])

  return (
    <ul>
      {/* plain text bubble */}
      {
        chat?.map((item, mIndex) => <div key={mIndex}>
          {/* message by visitor */}
            {
              (item.user !== '/restart' && item.user !== null) && <li className="message-bubble-visitor date-bubble-item">
              <span className=''>{item.user}</span>
              {
                new Date(item?.time)?.toDateString() === new Date(Date.now())?.toDateString() ? <div className="messageTimestamp fade-enter-done">Today {new Date(item?.time).toLocaleTimeString()}</div> : <div className="messageTimestamp fade-enter-done">{new Date(item?.time).toDateString()}</div>
              }
            </li>
            }
            
            {/* bot message */}
            {
              !item.bot ? <li>
                <img className='agent-logo' src={textLogo} alt="bot" />
                <div className="typing-wrap message-bubble-agent">
                  <span className="tying">Typing</span>
                  <span className="animate-typing">
                    <span className="dot ms-1"></span>
                    <span className="dot ms-1"></span>
                    <span className="dot ms-1"></span>
                  </span>
                </div>
              </li> : item.bot.length === 0 ?  <li>
                <img className='agent-logo' src={textLogo} alt="bot" />
                <div className="chat-bubble-wrap">
                  <p className='message-bubble-agent'>{language === 'en' ? 'Good day! How can I help you?' : language === 'my' ? 'selamat hari! Bagaimana saya boleh tolong awak?' : ''}</p>
                </div>
              </li> : <li>
                <img className='agent-logo' src={textLogo} alt="bot" />
                <div className='relative'>
                  {item.bot?.map((bt, index) => <div key={index}>
                    {
                      bt?.text &&  <div>
                          {/* {bt.text === 'What are your symptoms?' ? <Fragment>
                              {bt.text}
                              <div style={{width: '250px'}}>
                                <Select
                                  id= "symptom"
                                  isMulti
                                  isClearable
                                  onChange={(selected) => setMessage(`I have ${selected.map(s => s.value).toString().replace(/,/g, ', ')}`) }
                                  menuPlacement='auto'
                                  isDisabled={chat.length - 1 !== mIndex}
                                  options={symptoms}
                                />
                              </div>
                            </Fragment> : <p className='message-bubble-agent'>{(chat.length - 1 === mIndex && item.bot.length - 1 === index) ? output : bt.text bt.text}</p>
                            } */}
                            <p dangerouslySetInnerHTML={{ __html: bt.text}} className='message-bubble-agent'>{/*(chat.length - 1 === mIndex && item.bot.length - 1 === index) ? output : bt.text*/}</p>
                            {
                              new Date(item?.time)?.toDateString() === new Date(Date.now())?.toDateString() ? <div className="messageTimestamp bot-date-response-badge">Today {new Date(item?.time).toLocaleTimeString()}</div> : <div className="messageTimestamp bot-date-response-badge">{new Date(item?.time).toDateString()}</div>
                            }
                        </div>
                    }
                    {bt?.image && <div className="chat-bubble-wrap"><img style={{width:'100%'}} src={bt.image} /></div>} 
                    {
                      bt?.buttons && <div className="message-auto-suggest-buttons">
                          {bt.buttons.map((btn, index) => {
                            return btn.payload.startsWith("http") ? <button className='suggest-btn' key={index}><a href={btn.payload} target="_blank">{btn.title}</a></button> : <button className='suggest-btn' key={index} disabled={chat.length - 1 !== mIndex} onClick={() => {
                              handleSendMessage(null, btn.payload)
                              }}><span>{btn.title}</span></button>
                          })}
                        </div>
                    }
                    {
                      bt?.custom && <div className="message-bubble-agent">
                          {
                            (formatePayload(bt?.custom?.payload)['type'] === 'custom_text') && <Fragment>
                              <div style={{width: '250px'}}>
                                {`${bt.custom.text}.`}  <br /><br />
                                {(formatePayload(bt?.custom?.payload)['entity_name'])} : {formatePayload(bt?.custom?.payload)['procedures']}
                              </div>
                            </Fragment>
                          }
                          {
                            (formatePayload(bt?.custom?.payload)['type'] === 'dropdown') && <Fragment>
                              <div style={{width: '250px'}}>
                                {`${bt.custom.text} ${(formatePayload(bt?.custom?.payload)['entity_name'])}:`}  
                                <Select
                                  id= "procedures"
                                  onChange={(selected) => handleSendMessage(null, language === 'my' ? `${(formatePayload(bt?.custom?.payload)['entity_name']).toLowerCase() === 'department' ? 'Jabatan ialah ' : (formatePayload(bt?.custom?.payload)['entity_name']).toLowerCase() === 'doctor' ? 'Doktor ialah ' : (formatePayload(bt?.custom?.payload)['entity_name']).toLowerCase() === 'procedure' ? 'prosedur ialah ' : (formatePayload(bt?.custom?.payload)['entity_name']).toLowerCase() === 'state' ? 'negeri adalah ' : (formatePayload(bt?.custom?.payload)['entity_name']).toLowerCase() === 'city' ? 'bandar adalah ' : ''}  ${selected.value}` : `${(formatePayload(bt?.custom?.payload)['entity_name'])} is  ${selected.value}`)}
                                  menuPlacement='auto'
                                  isDisabled={chat.length - 1 !== mIndex}
                                  options={formatePayload(bt?.custom?.payload)['procedures'].map(val => { return {value: val, label: val}})}
                                />
                              </div>
                            </Fragment>
                          }
                          {
                            (formatePayload(bt?.custom?.payload)['type'] === 'datepicker') && <Fragment>
                              <div style={{width: '280px', position:'relative'}}>
                                    {`${bt.custom.text} ${(formatePayload(bt?.custom?.payload)['entity_name'])}:`}
                                    <DatePicker
                                      selected={scheduleDate || new Date(formatePayload(bt?.custom?.payload)['procedures'][0]?.split(',  ')[0])}
                                      onChange={(date) => {
                                        console.log(date)
                                        setMessage(language === 'my' ? `Temujanji anda ialah ${new Date(date).toLocaleString()}` : `${(formatePayload(bt?.custom?.payload)['entity_name'])} is  ${new Date(date).toLocaleString()}`)
                                        setScheduleDate(date)
                                      }}
                                      // showTimeInput
                                      // timeInputLabel="Time:"
                                      placeholderText='--/--/----, --:--'
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      readOnly = {chat.length - 1 !== mIndex}
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                      shouldCloseOnSelect={false}
                                      highlightDates={formatePayload(bt?.custom?.payload)['procedures'][0]?.split(',  ')?.map(d => new Date(d))}
                                      includeDates={formatePayload(bt?.custom?.payload)['procedures'][0]?.split(',  ')?.map(d => new Date(d))}
                                      includeTimes={formatePayload(bt?.custom?.payload)['procedures'][0]?.split(',  ')?.map(d => new Date(d))}
                                      showTimeSelect
                                      timeIntervals={10}
                                    />
                              </div>
                            </Fragment>
                          }
                          {
                            (formatePayload(bt?.custom?.payload)['type'] === 'report') && <Fragment>
                              <div className='report-block'>
                              {`${bt.custom.text}`}
                                  <h5>{formatePayload(bt?.custom?.payload)['entity_name']}</h5>
                                  <h6>Procedure: <span className='report-label-value'>{formatePayload(bt?.custom?.payload)['procedure']}</span></h6>
                                  <h6>State: <span className='report-label-value'>{formatePayload(bt?.custom?.payload)['state']}</span></h6>
                                  <h6>City: <span className='report-label-value'>{formatePayload(bt?.custom?.payload)['city']}</span></h6>
                                  { formatePayload(bt?.custom?.payload).procedures.length > 0 && <h6>Provider Name: <span className='report-label-value'>{formatePayload(bt?.custom?.payload).procedures[0]?.Provider_Name}</span></h6>}
                                  { formatePayload(bt?.custom?.payload).procedures.length > 0 && <h6>Provider Street Address: <span className='report-label-value'>{formatePayload(bt?.custom?.payload).procedures[0]?.Provider_Street_Address}</span></h6>}
                                  { formatePayload(bt?.custom?.payload).procedures.length > 0 && <h6>Provider Zip Code: <span className='report-label-value'>{formatePayload(bt?.custom?.payload).procedures[0]?.Provider_Zip_Code}</span></h6>}
                                  { formatePayload(bt?.custom?.payload).procedures.length > 0 && <h6>Total Discharges: <span className='report-label-value'>{formatePayload(bt?.custom?.payload).procedures[0]?.Total_Discharges}</span></h6>}
                                  { formatePayload(bt?.custom?.payload).procedures.length > 0 && <h6>Average Total Payments: <span className='report-label-value'>{formatePayload(bt?.custom?.payload).procedures[0]?.Average_Total_Payments}</span></h6>}
                                  { (formatePayload(bt?.custom?.payload).procedures?.avg_medicare && formatePayload(bt?.custom?.payload).procedures?.avg_medicare !== 'null') ? <h6>Average Charge Covered by Medicare: <span className='report-label-value'>${formatePayload(bt?.custom?.payload)?.procedures?.avg_medicare?.toFixed(2)}</span></h6> : <h6>Average Charge Covered by Medicare: <span className='report-label-value'>Not found</span></h6>}
                                  { (formatePayload(bt?.custom?.payload)?.procedures?.avg_charge && formatePayload(bt?.custom?.payload)?.procedures?.avg_charge !== 'null') ? <h6>Average Charge: <span className='report-label-value'>${formatePayload(bt?.custom?.payload)?.procedures?.avg_charge?.toFixed(2)}</span></h6> : <h6>Average Charge: <span className='report-label-value'>Not found</span></h6>}
                              </div>
                            </Fragment>
                          }
                          {
                            (formatePayload(bt?.custom?.payload)['type'] === 'carosel') && <Fragment>
                              <div style={{width:'300px'}}>
                               {`${formatePayload(bt?.custom?.payload).entity_name}`}
                                {
                                  typeof(formatePayload(bt?.custom?.payload).procedures) === 'string' ? <h6>{formatePayload(bt?.custom?.payload).procedures}</h6> : <Swiper
                                  slidesPerView={3}
                                  spaceBetween={30}
                                  slidesPerGroup={2}
                                  loop={true}
                                  loopFillGroupWithBlank={true}
                                  // pagination={{
                                  //   clickable: true,
                                  // }}
                                  navigation={true}
                                  modules={[Pagination, Navigation]}
                                  className="mySwiper"
                                >
                                  {
                                    formatePayload(bt?.custom?.payload).procedures?.map(item => <SwiperSlide key={item._id}>
                                      <div className='refill-item'>
                                          <h6>{item.name}</h6>
                                          <div className='refill-img'>
                                            <img src={item.img}/>
                                          </div>
                                          <button disabled={chat.length - 1 !== mIndex} onClick={() => {
                                            setMedichines([...medichines, item.name])
                                            setMessage(`Refill ${[...medichines, item.name].toString().replace(/,/g, ", ")}`)
                                          }}>Refill</button>
                                      </div>
                                    </SwiperSlide>)
                                  }
                                </Swiper>
                                }
                              </div>
                            </Fragment>
                          }
                          {
                            new Date(item?.time)?.toDateString() === new Date(Date.now())?.toDateString() ? <div className="messageTimestamp fade-enter-done">Today {new Date(item?.time).toLocaleTimeString()}</div> : <div className="messageTimestamp fade-enter-done">{new Date(item?.time).toDateString()}</div>
                          }
                    </div>
                    }
                  </div>)}
                </div>
              </li>
      }
      </div>)}
      <li className='ref-li' ref={chatRef}></li>
    </ul>
    )
  }

  export default HealthCareBot
