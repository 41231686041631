import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { MdComment, MdNotificationsActive, MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import moment from 'moment'
import MiniLoading from '../../components/MiniLoading';
import { apiUrl } from '../../Variables';

import NotifyDropdown from '../../NotifyDropdown';

function Payable() {

  const [xeroData, setXeroData] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [syncLoading, setSyncLoading] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [date, setDate] = useState('')

  useEffect(() => {
    (async() => {
      setError('')
      try {
        setLoading(true)
        const {data} = await axios.get(`${apiUrl}/scrap/xero`)
        setLoading(false)
        setXeroData(data[0]?.xero?.payable)
        setDate(data[0]?.createdAt)
      } catch (error) {
        setError(error)
        console.log(error)
      }
    })()
  },[])

  return (
   <>
   <div>

      <div className="table-header">

          <div className="table-title">
            <h3>Aged Payable Summery</h3>
            <div class="user-join-date user-update-date p-relative aged-title"><p>Last Sync: {moment(date).fromNow()}</p></div>
          </div>

          {/* {error && <h2>{error}</h2>} */}
          
        {/* group right buttons */}
        <div className='group-right-buttons'>
          {/* import data */}
          {/* <div className="table-add-new table-header-button-wrapper">

            <button className='table-header-button' onClick={() => setToggle(!toggle)}> { syncLoading ? <MiniLoading /> : <span><MdNotificationsActive /> Notify {toggle ? <FiChevronUp /> : <FiChevronDown />}</span>} </button>

            {toggle && 
              <NotifyDropdown />
            }

          </div> */}
        </div>
      </div>

        {xeroData?.message && <h1 className='xero-error'>{xeroData?.message}</h1>}
        <div className="xero-table-wrapper">
          {loading && <MiniLoading />}
          <table>
              {/* header */}
              <tr>
                <th>Contact</th>
                <th>Current</th>
                <th> <span className='th-flex'><MdOutlineKeyboardArrowLeft /> 1 Month</span></th>
                <th>1 Month</th>
                <th>2 Month</th>
                <th>3 Month</th>
                <th>Older</th>
                <th>Total</th>
                <th>Date</th>
              </tr>
              
              {/* table body */}
              <tbody>
                {/* item */}
                {
                  xeroData?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.contact}</td>
                      <td>{item.current}</td>
                      <td>{item.lessOneMonth}</td>
                      <td>{item.oneMonth}</td>
                      <td>{item.twoMonth}</td>
                      <td>{item.threeMonth}</td>
                      <td>{item.older}</td>
                      <td>{item.total}</td>
                      <td>{item.date}</td>
                      {/* <td>{new Date(item?.Date).slice(1, -1).toUTCString()}</td> */}
                      {/* {<td>{item?.Date.slice(1, -1)} </td>} */}
                      {/* {<td>{moment(new Date(item?.DateString).toDateString()).fromNow()}</td>} */}
                    </tr>
                  ))
                }

              </tbody>
            </table>
        </div>
     </div>
   </>
  )
}

export default Payable