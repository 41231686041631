import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { MdCloudUpload } from 'react-icons/md';
import tempLogo from '../images/cat.png';
import { apiUrl } from '../Variables';
import MiniLoading from './MiniLoading';

function UpdateGroupModel({setUpdateGroupToggle, updateGroupId, updateGroupToggle, updateGroupSuccess, setUpdateGroupSuccess}) {

  const [uploadLoading, setUploadLoading] = useState(false)
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [logo, setLogo] = useState(null)
  const [groupItem, setGroupItem] = useState({
    name: '',
    authCode: '',
    username: '',
    type: '',
    logo: ''
  })

  // file upload handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "pewhglsn")
    formData.append("cloud_name", "dzo2vmjdy")

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, config)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, config)
      setLogo(data?.url)
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  // effect
  useEffect(() => {

    // fetch single channel object
    try {
      const fetchSingleGroupObject = async() => {
      const {data} = await axios.get(`${apiUrl}/chatlog/group/${updateGroupId}`)
      setGroupItem(data?._doc)
      console.log(data?._doc)
      }
      fetchSingleGroupObject()
    } catch (error) {
      console.log(error)
    }

  },[updateGroupId, updateSuccess])

  // submit handler
  const handleSubmit = async (e) => {
    e.preventDefault()
    const config = {
      headers: {
        'Accept': 'application/json',
        "Content-Type": 'application/json'
      }
    }
    await axios.put(`${apiUrl}/chatlog/group/`, {_id: updateGroupId, ...groupItem}, config)
               .then(res => setUpdateGroupSuccess(!updateGroupSuccess))
               .catch(error => console.log(error))
  }

  return (
    <>
      <div className={updateGroupToggle ? "create-user-wrapper active" : "create-user-wrapper"}>
        <form onSubmit={handleSubmit} className="create-user-form">

          <div className="form-title">
            <h2>Edit</h2>
            {/* <p>Insert file name.</p> */}
            <BsX onClick={() => setUpdateGroupToggle(!updateGroupToggle)} className='time-icon' />
          </div>

          {/* export items */}
          <div className='create-group-wrapper'>

            {/* name */}
            <div className="form-group">
              <label htmlFor="">Group name</label>
                <input value={groupItem?.name} onChange={(e) => setGroupItem({...groupItem, name: e.target.value})} type="text" placeholder='Write group label' />
            </div>

            {/* Authentication code */}
            <div className="form-group">
              <label htmlFor="">Access Token</label>
                <input value={groupItem?.authCode} onChange={(e) => setGroupItem({...groupItem, authCode: e.target.value})} type="text" placeholder='5926675960:AAFm8xgRufI2wVw5I9bnoK8FS4WyDykDwOY' />
            </div>

            {/* username */}
            <div className="form-group">
              <label htmlFor="">Channel Username</label>
                <input value={groupItem?.username} onChange={(e) => setGroupItem({...groupItem, username: e.target.value})} type="text" placeholder='@wipdata_channel' />
            </div>

            {/* group */}
            <div className="form-group">
              <label htmlFor="">Channel Or Group ? </label>
              <select value={groupItem?.type} onChange={(e) => setGroupItem({...groupItem, type: e.target.value})}>
                <option value="channel">Channel</option>
                <option value="group">Group</option>
              </select>
            </div>

            {/* logo */}
            <div className="form-group">
              <label>
                <div className="user-profile-header-thumb create-group-logo">
                {uploadLoading && <MiniLoading />}
                  <img src={groupItem?.logo ? groupItem?.logo : tempLogo} alt="profile photo" />
                  <div className="user-update-profile create-user-profile-update-icon-wrapper">
                    <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                    <div className="user-update-profile">
                      <MdCloudUpload className='user-update-icon' />
                    </div>
                  </div>
                </div>
              </label>
            </div>


            <div className="form-group">
              <button onClick={() => setUpdateGroupToggle(!updateGroupToggle)} type='submit' className='btn create-group-btn'>Update</button>
            </div>
          </div>
          
        </form>
      </div>
    </>
  )
}

export default UpdateGroupModel