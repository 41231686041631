import React, { useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from "xlsx";


const BulkMessageImportModel = ({setImportedNumbers}) => {

  function readExcelFile(file) {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
  
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  
    return promise;
  }

  return (
    <>
      <div className="export-wrapper">
        <label htmlFor="">
          <div className="export-item number-import-item">
            
            <input 
              type="file" 
              id='import-number-file' name="importnumber" custom="true"
            
              onChange={(e) => {
                const file = e.target.files[0];
                readExcelFile(file).then((data) => {
                  console.log(data)
                  setImportedNumbers(data)
                  e.target.value = null;
                });
              }} 
            />
          </div>
        </label>
      </div>
    </>
  );
};

export default BulkMessageImportModel;