import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ErrorText from '../components/ErrorText';
import Loading from '../components/Loading';
import { useAppContext } from '../context/AppContext';
import Logo from '../images/wipdata-logo.png';
import { apiUrl } from '../Variables';

function LoginScreen({toggleRefresh}) {
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {register, handleSubmit, formState: {errors}} = useForm();
  const {userInfo, refresh, setRefresh} = useAppContext();
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();
  useEffect(() => {
    if(userInfo) {
      navigate('/')
    }

  }, [navigate, success])

  const onSubmit = async (data) => {
    // header supports
    const config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }
    try {
      setLoading(true)
      const res = await axios.post(`${apiUrl}/user/login`, data, config)
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      setSuccess(!success)
      setLoading(false)
      toggleRefresh()
      navigate('/')
    } catch (error) {
      setLoading(false)
      console.log(error.response
        ? error.response.data.message
        : error.message); // this is the main part. Use the response property from the error object
        setErrorMessage(error.response
          ? error.response.data.message
          : error.message)

          if(error.response) {
           return Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.message,
            })
          }

      return error.response;
    } 
  }

  console.log(loading)

  return (
    <>
    {loading && <Loading />}
      <div className="register-container">
          <div className="single-logo-wrapper">
            <Link to="/">
              <div className="single-logo">
                <img src={Logo} alt="logo" />
                <div className="logo-text">
                  <h2>WipData</h2>
                </div>
              </div>
            </Link>
          </div>

        {/* wrapper */}
        <div className="register-wrapper">
        
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="form-title">
              <h2>Login</h2>
              <p>Please fill the fileds to login.</p>
            </div>

            {/* email */}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input className={errors.email?.message && "error-input"} {...register('email', {required: "Email is required!"})} type="email" placeholder='Email address' id='email' />
              <ErrorText error={errors.email?.message} />
            </div>

            <div className="form-row">
              {/* Password */}
              <div className="form-group">
                <label htmlFor="Password">Password</label>
                <input className={errors.password?.message && "error-input"} {...register('password', {required: "Password is required!"})} type="password" placeholder='Password' id='Password' />
                <ErrorText error={errors.password?.message} />
              </div>
            </div>

            <div className="already-have-account">
              {/* <p>Don't have an account? <span className='link'><Link to="/register">Register Here</Link></span></p> */}
              <p><span className='link'><Link to="/forgot-password">Forget Password?</Link></span></p>
            </div>

            <button type='submit' className='form-button'>
              Login
            </button>
          </form>
          
        </div>
      </div>
    </>
  )
}

export default LoginScreen