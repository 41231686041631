import React from 'react'
import { BsX } from 'react-icons/bs'
import { GrAddCircle } from 'react-icons/gr'

function AddNumberModel({addNumber, addNumberHandler, setAddNumber, setNewNumber, newNumber}) {
  return (
    <>
      <div className={addNumber ? "create-user-wrapper active" : "create-user-wrapper"}>
        <form onSubmit={addNumberHandler} className="create-user-form bulk-msg-create-user-form">
          <div className="form-title bulk-import-title">
            <span>Add new number</span>
            <BsX onClick={() => setAddNumber(!addNumber)} className='time-icon' />
          </div>

          <label htmlFor="">Number</label>
          <div className="form-group add-number-wrap">
            <input onChange={(e) => setNewNumber(e.target.value)} value={newNumber} type="number" placeholder='Phone number...' />
            <button onClick={() => setAddNumber(!addNumber)} className="btn number-btn" type='submit'>Add</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddNumberModel