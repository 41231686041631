import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai'
import { BiMessageSquareEdit } from 'react-icons/bi'
import { BsChevronDown, BsChevronUp, BsInfoCircle, BsPinAngle, BsX } from 'react-icons/bs'
import { FaBullhorn } from 'react-icons/fa'
import { FiMoreVertical, FiSearch } from 'react-icons/fi'
import { GoSettings } from 'react-icons/go'
import { HiUserGroup } from 'react-icons/hi'
import { MdOutlinePhotoLibrary } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Swal from 'sweetalert2'
import { apiUrl, getChatByUserId } from '../Variables'
import CreateGroupModel from '../components/CreateGroupModel'
import GroupFilterModel from '../components/GroupFilterModel'
import MiniLoading from '../components/MiniLoading'
import UpdateGroupModel from '../components/UpdateGroupModel'
import WhiteCanvas from '../components/WhiteCanvas'
import AdminMessageBody from '../components/chatlogs/AdminMessageBody'
import { useAppContext } from '../context/AppContext'

const imageNotFound = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtWkiWDS6J0sln0jthemckUqQxaA6dmbP9WFM0EtS7iOHmoDy1MsVonWIPyPOPYfZb3kc&usqp=CAU'

// dummy images
 const images = [
  {
    img: "https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
  },
  {
    img: "https://static.vecteezy.com/system/resources/previews/019/896/012/original/female-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHCZuslFbn42wwA9qw6ywBERhtpr_yOFy3Cw&usqp=CAU"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG1dXcbGQTvqLXLBEQnr3S2ta1flJNUUc6kw&usqp=CAU"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFhSnoly9J1ySaRA45scYB4Q1otdhWAXtLlg&usqp=CAU"
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/1057/1057231.png"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHUndSzxcF1UbSXX3bVILVaUbSIhoc_GEA8g&usqp=CAU"
  },
  {
    img: "https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
  },
  {
    img: "https://static.vecteezy.com/system/resources/previews/019/896/012/original/female-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHCZuslFbn42wwA9qw6ywBERhtpr_yOFy3Cw&usqp=CAU"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG1dXcbGQTvqLXLBEQnr3S2ta1flJNUUc6kw&usqp=CAU"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFhSnoly9J1ySaRA45scYB4Q1otdhWAXtLlg&usqp=CAU"
  },
  {
    img: "https://cdn-icons-png.flaticon.com/512/1057/1057231.png"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHUndSzxcF1UbSXX3bVILVaUbSIhoc_GEA8g&usqp=CAU"
  },
 ]

function ChatLogsScreen() {

  const [group, setGroup] = useState([])
  const [botChatlog, setBotChatLog] = useState([])

  const [width, setWidth] = useState(window.innerWidth);
  const [showChat, setShowChat] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [createGroupToggle, setCreateGroupToggle] = useState(false) 
  const [mediaToggle, setMediaToggle] = useState(false)

  const [updateGroupToggle, setUpdateGroupToggle] = useState(false) 
  const [updateGroupId, setUpdateGroupId] = useState('')
  const [updateGroupSuccess, setUpdateGroupSuccess] = useState(false)

  const [createGroupSuccess, setCreateGroupSuccess] = useState(false)
  const [groupDeleteSuccess, setGroupDeleteSuccess] = useState(false)
  const [groupFilterToggle, setGroupFilterToggle] = useState(false)

  const [deleteFail, setDeleteFail] = useState(false)

  const [groupId, setGroupId] = useState('')
  const [username, setUsername] = useState('')
  const [authCode, setAuthCode] = useState('')
  const [menuToggle, setMenuToggle] = useState(null);
  const [pinMenuToggle, setPinMenuToggle] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedMenue, setSelectedMenue] = useState('')

  const location = useLocation();
  const pathname = location.pathname; 

  const {sidebarToggle, setSidebarToggle} = useAppContext()

  const [resetData, setResetData] = useState(false);
  const toggleReset = () => {
    setResetData(!resetData)
  }

  console.log("pathname", sidebarToggle)

  useEffect(() => {
    if(pathname === '/chatlogs') {
      setSidebarToggle(true)
    }
  },[pathname])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const menuToggleHandler = (index) => {
      if(index == menuToggle) {
        setMenuToggle(null)
      } else {
        setMenuToggle(index)
      }
    }

    // pin icon toggle handler
    const pinMenuToggleHandler = (index) => {
      if(index == pinMenuToggle) {
        setPinMenuToggle(null)
      } else {
        setPinMenuToggle(index)
      }
    }

    // create group model
    const handleCreateGroup = () => {
      setCreateGroupToggle(!createGroupToggle)
    }

    // media toggle
    const mediaToggleHandler = () => {
      setMediaToggle(!mediaToggle);
    };

    // fetch group data []
    useEffect(() => {
      const fetchGroup = async() => {
        setLoading(true)
        const res = await axios.get(`${apiUrl}/chatlog/group`)
        const res1 = await axios.get(getChatByUserId)
        const res2 = await axios.get(`${apiUrl}/telegram/whatsapp/getChats`)
        const botChatLogs = []
        const whatsappChatLog = []
        for (const item of res1.data) {
          botChatLogs.push({
            _id: item?._id,
            authCode: "5825347110:AAHnvF5KVfTR8p9KxLG1kfYCvA44XAc11sA",
            chats: item.chats,
            createdAt: item.createdAt,
            isPinned: "false",
            logo: null,
            name: item.user_id, 
            type: "Web",
            updatedAt: item.updatedAt,
            username: item.user_id})
          }
        for (const item of res2.data) {
          whatsappChatLog.push({
            _id: item?._id,
            authCode: "5825347110:AAHnvF5KVfTR8p9KxLG1kfYCvA44XAc11sA",
            chats: item.chats,
            createdAt: item.createdAt,
            isPinned: "false",
            logo: null,
            name: item.user_number, 
            type: "Whatsapp",
            updatedAt: item.updatedAt,
            username: item.user_number})
          }
        setLoading(false)
        setGroup([...res.data, ...botChatLogs, ...whatsappChatLog])
        setBotChatLog({...[...res.data, ...botChatLogs, ...whatsappChatLog].find(i => i.name === botChatlog?.name), img: botChatlog.img})
        console.log(res)
        console.log(res1)
        console.log(res2)
      }
      fetchGroup()
    },[createGroupSuccess, updateGroupSuccess, groupDeleteSuccess, resetData])   
    
    
    // if delete fail
    useEffect(() => {
      if(deleteFail) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },[deleteFail])

    // pin handler
    const pinHandler = async (item, isPinned) => {
      try {
        const {data} = await axios.put(`${apiUrl}/chatlog/group/`, {...item, isPinned})
        setCreateGroupSuccess(!createGroupSuccess)
      } catch (error) {
        console.log(error.message)
      }
    }

    // group delete handler 
   const handleDeleteChannel = async (id) => {
    
      Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const res = await axios.delete(`${apiUrl}/chatlog/group/${id}`)
              setDeleteFail(false)
              setGroupDeleteSuccess(!groupDeleteSuccess)
              Swal.fire(
                'Deleted!',
                'Group has been deleted.',
                'success'
              )
            } catch (error) {
              console.log(error)
              setDeleteFail(true)
            }
          }
        })
    }

  return (
    <>

      {/* Create group model */}
      <CreateGroupModel setCreateGroupSuccess={setCreateGroupSuccess} setCreateGroupToggle={setCreateGroupToggle} createGroupToggle={createGroupToggle} />

      {/* update group model */}
      <UpdateGroupModel setUpdateGroupSuccess={setUpdateGroupSuccess} updateGroupSuccess = {updateGroupSuccess} updateGroupId={updateGroupId} updateGroupToggle={updateGroupToggle} setUpdateGroupToggle={setUpdateGroupToggle} />

      {/* group filter model */}
      <GroupFilterModel groupId={groupId} setGroupId={setGroupId} groupFilterToggle={groupFilterToggle} setGroupFilterToggle={setGroupFilterToggle} />

        {/* chat body */} 
        <div className={fullScreen ? 'chat-body-wrapper active' : 'chat-body-wrapper'}>
          {/* sidebar */}
          <div className='chat-body-sidebar'>
            <Tabs>
              <div className="table-header">
                <TabList className="api-header-wrapper">
                  <Tab className="api-item">Groups</Tab>
                  <Tab className="api-item">Bulk Message</Tab>
                </TabList>
              </div>

              <div className="tab-content-wrapper">
                  <TabPanel>
                    <div className="sub-group-wrap">
                      <li className={selectedMenue === '' ? "api-sub-item-active" : "api-sub-item"} onClick={() => setSelectedMenue('')}>All</li>
                      <li className={selectedMenue === 'Web' ? "api-sub-item-active" : "api-sub-item"} onClick={() => setSelectedMenue('Web')}>Web</li>
                      <li className={selectedMenue === 'Whatsapp' ? "api-sub-item-active" : "api-sub-item"} onClick={() => setSelectedMenue('Whatsapp')}>Whatsapp</li>
                      <li className={(selectedMenue === 'channel' || selectedMenue === 'group') ? "api-sub-item-active" : "api-sub-item"} onClick={() => setSelectedMenue('channel')}>Telegram</li>
                      <li className={selectedMenue === 'Messenger' ? "api-sub-item-active" : "api-sub-item"} onClick={() => setSelectedMenue('Messenger')}>Messenger</li>
                    </div>
                    <div className='chatbox-sidebar-wrap'>
                      <div className='chat-title chat-title-big'>
                        <h2>Search</h2>
                        <AiOutlinePlus onClick={handleCreateGroup} className='add-new-group-icon' />
                      </div>

                      {/* search */}
                      <div className='chat-body-search'>
                        <input placeholder='Search messages or users' />
                        <FiSearch />
                      </div>

                      {/* All group title */}
                      <div className='chat-title'>
                        <h2>Chats {group.length === 0 && <MiniLoading/>}</h2>
                      </div>


                      {/* All Group list */}
                      <div className='recent-chat'>
                      {loading && <MiniLoading />}
                        <ul className='recent-chat-wrap'>

                          {group ? 
                          (
                            group?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))?.filter((filtered) => {
                              if (selectedMenue === 'channel') {
                                return (filtered?.type === selectedMenue || filtered?.type === 'group')
                              }else if (selectedMenue !== '') {
                                return filtered?.type === selectedMenue
                              } else {
                                return true
                              }
                            })?.map((item, index) => (
                              <li key={index} className={groupId == item._id ? 'recent-chat-item active' : 'recent-chat-item'} 
                                onClick={() => {
                                  setGroupId(item?._id)
                                  setUsername(item?.username)
                                  setAuthCode(item?.authCode)
                                  setShowChat(!showChat)
                                  setBotChatLog({...item, img: images?.[index]?.img})
                                }}
                              >
                                <div className='recent-chat-item-inner'>
                                  <div className='recent-chat-item-content'>
                                    {/* thumb */}
                                    <div className='featured-chat-thumb'>
                                      <img src={item?.logo ? item?.logo : images?.[index]?.img} alt="chat one" />
                                      <div className="online-status"></div>
                                    </div>
                                    {/* content */}
                                    <div className='recent-chatbox-content'>
                                      <h2>{item.name}</h2>
                                      <div className='recent-chatbox-user-chat'>
                                        {(item?.type === 'channel') ? <div className='icon-wrap'><FaBullhorn /> <span>Channel</span></div> : (item?.type === 'Web') ? <div className='icon-wrap'><FaBullhorn /> <span>Web</span></div> : (item?.type === 'Whatsapp') ? <div className='icon-wrap'><FaBullhorn /> <span>Whatsapp</span></div> : <div className='icon-wrap'><HiUserGroup /> <span>Group</span></div> 
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  {/* Right side */}
                                  <div className="channel-right">

                                    {(item?.type === 'channel' || item?.type === 'group') && <GoSettings onClick={() => {
                                      setGroupFilterToggle(!groupFilterToggle)
                                      setGroupId(item?._id)
                                    }} />}

                                    {/* <BsPinAngle onClick={() => pinHandler(item, "true")} /> */}

                                    {/* more */}
                                    <div className='chatbox-header-icon' onClick={() => menuToggleHandler(index)}>
                                      { menuToggle === index ? <BsX /> : <FiMoreVertical /> } 

                                      {/* dropdown */}
                                      <div className={menuToggle === index ? 'dropdown-menu-wrapper active-chat-item' : 'dropdown-menu-wrapper'}>
                                        <ul>
                                          <li onClick={() => {
                                            setUpdateGroupId(item?._id)
                                            setUpdateGroupToggle(!updateGroupToggle)
                                          }}>
                                            <span>Edit</span> 
                                            <BiMessageSquareEdit />
                                          </li>
                                          <li onClick={() => handleDeleteChannel(item?._id)}>
                                            <span>Delete</span> 
                                            <AiOutlineDelete />
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : 
                          <WhiteCanvas
                            parentClass="Channel Or Group Not Found"
                            h2="No Channels"
                            p="Create new channel or group on the top of the plus button"
                            icon={<FaBullhorn />}>
                          </WhiteCanvas>
                          }
                        </ul>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <h5>Not connect yet!</h5>
                  </TabPanel>
              </div>
            </Tabs>
          </div>

          {/* message body */}
          <div className={showChat ? 'chat-message-body chatbox-message-body active' : 'chat-message-body chatbox-message-body'}>
            <AdminMessageBody loading={loading} setLoading={setLoading} toggleReset={toggleReset} resetData={resetData} botChatlog={botChatlog} setGroupFilterToggle={setGroupFilterToggle} groupFilterToggle={groupFilterToggle} groupId={groupId} username={username} authCode={authCode} showChat={showChat} setShowChat={setShowChat} fullScreen={fullScreen} setFullScreen={setFullScreen} />
          </div>

          {/* profile card */}
          <div className="chatlog-profile-card">

              {/* thumb */}
              <div className="chatlog-card-thumb">
                <img src="https://images.pexels.com/photos/2328719/pexels-photo-2328719.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>

              {/* card content */}
              <div className={ botChatlog?.name ? "chatlog-card-content active" : "chatlog-card-content" }>
                <div className="card-avatar">
                  <img src={botChatlog?.img} alt="" />
                </div>
                <h2>{botChatlog?.name}</h2>
                <h3>{botChatlog?.type}</h3>
                <h4>San fransisco, Califonia</h4>
              </div>

              {/* card content */}
              <div className="chatlog-card-wrapper">
                
                {/* card informations */}
                <div className="chatlog-card-info">
                  <div className="chatlog-info-title">
                    <p>User informations</p>
                    <BsInfoCircle />
                  </div>

                  <div className="info-group">
                    <label htmlFor="">Name</label>
                    <p>+88 01818457878</p>
                  </div>
                  <div className="info-group">
                    <label htmlFor="">Email</label>
                    <p>info.wipdata.com</p>
                  </div>
                </div>

                {/* card media */}
                <div onClick={mediaToggleHandler} className="chatlog-media-wrapper">
                  <div className="chatlog-info-title">
                    <p> <MdOutlinePhotoLibrary /> Media</p>
                    { mediaToggle ? <BsChevronDown /> : <BsChevronUp /> }
                  </div>

                  {mediaToggle && (
                    <div className="chatlog-card-media">
                      <img src="https://www.teahub.io/photos/full/144-1444824_artwork-lions-3d-chronicles-of-narnia-wallpaper-high.jpg" alt="test image" />
                      <img src="https://wallpaperaccess.com/full/138728.jpg" alt="test image" />
                      <img src="https://rockseeker.com/wp-content/uploads/2020/08/sea-glass-4-1.jpg" alt="test image" />
                    </div>
                  )}

                  
                </div>
                
              </div>
          </div>
        </div>
    </>
  )
}

export default ChatLogsScreen