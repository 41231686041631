import React, { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import { BsX } from 'react-icons/bs'
import { MdKeyboardArrowDown, MdYoutubeSearchedFor } from 'react-icons/md';

function XAxis({fileData, tableData, xData, setXData, xToggleFunc, xfilter, setxFilter, toggleReset}) {

  const [xBoard, setXBoard] = useState([]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "div",
    drop: (item) => getFieldObject(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    const haveData = xBoard;
    const checkData = haveData || []
    if(checkData) {
      setXData(checkData)
    }

    if(xBoard && xBoard?.length !== 0) {
      localStorage.setItem('xBoard', JSON.stringify(xBoard))
      localStorage.setItem('xfilter', JSON.stringify(xfilter))
    }

  }, [tableData, xBoard, xData])

  useEffect(() => { 
    setXBoard(JSON.parse(localStorage.getItem('xBoard')) || xBoard) 
    setxFilter(JSON.parse(localStorage.getItem('xfilter')) || xfilter) 
  }, [])

  const getFieldObject = (obj) => {
    const dataArr = []
    for (let i = 0; i < fileData?.dataArray[obj.sheet]?.length ; i++) { // first we map all sheet
      dataArr.push(fileData?.dataArray[obj.sheet][i][obj.label]) 
    }
    // console.log(dataArr)
    // const uniqeArr = []
    // for (const item1 of dataArr) {
    //   if (!uniqeArr.includes(item1)) {
    //     uniqeArr.push(item1)
    //   }
    // }
    // console.log(uniqeArr)
    xfilter.label = [{xAxisTitle: obj.label, name: obj.label, amount: '', operation: '', startDate: '', endDate:''}]
    setxFilter({...xfilter, sheet: obj.sheet})
    setXBoard((xBoard) => [{...obj, data: dataArr}]);
  };
  

  // remove xData index
  const handleColumnRemove = (index) => {
    localStorage.removeItem('xBoard')
    toggleReset()
    const removed = xBoard?.filter((item, i) => i !== index)
    setXBoard(removed)
    xfilter.label?.splice(index, 1)
    setxFilter({...xfilter})
  }

  return (
    <>
     {/* x axis */}
     <div className="y-axis">
        <label htmlFor="">X-Axis</label>
        <div className="y-axis-drag-canvas" ref={drop} style={{border: isOver ? "2px dotted green" : "2px dotted lightgray"}}>
        {
            xBoard && xBoard?.map((item, index) => (
              <div className="field-item label-item x-axis-item" key={index}>
                <div className="label-content">
                  <span>{`${index+1}. ${item.label}`}</span>
                </div>
                <div className='item-action-btn'>
                  {
                    <div className="label-action">
                      <MdKeyboardArrowDown onClick={() => {
                        xToggleFunc()
                      }} />
                    </div>
                  }
                <div className="label-action">
                  <BsX onClick={() => handleColumnRemove(index)} />
                </div>
                </div>
              </div> 
            ))
          }
        </div>
      </div>      
    </>
  )
}

export default XAxis