import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import ErrorText from '../components/ErrorText'
import { useAppContext } from '../context/AppContext'
import Logo from '../images/wipdata-logo.png'

function RegisterScreen({toggleRefresh}) {

  const {userInfo, apiUrl} = useAppContext()
  
  const [success, setSuccess] = useState(false)

  const {register, handleSubmit, watch, formState: {errors}} = useForm();
  const navigate = useNavigate();

  

  const onSubmit = async (data) => {
    setSuccess(false)
    const res = await axios.post(`${apiUrl}/user/register`, data)
    localStorage.setItem('userInfo', JSON.stringify(res.data))
    toggleRefresh()
    setSuccess(true)
  }

  useEffect(() => {
    if(userInfo) {
      navigate('/')
    }
    setSuccess(false)
  }, [navigate, userInfo])


  return (
    <>
      <div className="register-container">
      <div className="not-found-header">
          <div className="single-logo-wrapper">
            <Link to="/">
              <div className="single-logo">
                <img src={Logo} alt="logo" />
                <div className="logo-text">
                  <h2>WipData</h2>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {/* wrapper */}
        <div className="register-wrapper">

          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="form-title">
              <h2>Register</h2>
              <p>Please fill the form to create account.</p>
            </div>

            {/* name */}
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input className={errors.name?.message && "error-input"} {...register('name', {required: "Name is required!"})} type="text" id='name' placeholder='Full name' />
              <ErrorText error={errors.name?.message} />
            </div>

            {/* email */}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input className={errors.email?.message && "error-input"} {...register('email', {required: "Email is required!"})} type="email" placeholder='Email address' id='email' />
              <ErrorText error={errors.email?.message} />
            </div>

            <div className="form-row">
              {/* Password */}
              <div className="form-group">
                <label htmlFor="Password">Password</label>
                <input className={errors.password?.message && "error-input"} {...register('password', {required: "Password is required!"})} type="password" placeholder='Password' id='Password' />
              <ErrorText error={errors.password?.message} />
              </div>

              {/* Confirm Password */}
              {/* <div className="form-group">
                <label htmlFor="ConfirmPassword">Confirm password</label>
                <input className={errors.confirmPassword?.message && "error-input"} {...register('confirmPassword', {required: "Confirm Password is required!"})} type="password" placeholder='Confirm password' id='Confirm Password' />
                <ErrorText error={errors.confirmPassword?.message} />
              </div> */}
            </div>

            {/* role and department */}
            <div className="form-row">
              {/* Role */}
              <div className="form-group">
                <label htmlFor="role">Role</label>
                <select 
                  // onChange={(e) => {
                  //   registerState.role = e.target.value
                  //   setRegisterState({...registerState})
                  // }}
                  {...register('role', {required: "role is required!"})}
                  name="role"
                  
                  id="role">
                  <option value="admin">Admin</option>
                  <option value="editor">Editor</option>
                  <option value="user">User</option>
                </select>
              </div>

              {/* Department */}
              <div className="form-group">
                <label htmlFor="department">Department</label>
                <select
                {...register('department', {required: "Department is required!"})}
                
                id="department">
                  <option value="sales">Sales</option>
                  <option value="human-resource">Human Resource</option>
                  <option value="account">Account</option>
                  <option value="finance">Finance</option>
                  <option value="audit">Audit</option>
                </select>
              </div>
            </div>

            <div className="already-have-account">
              <p>Already have an account? <span className='link'><Link to="/login">Login Here</Link></span></p>
            </div>

            <button type='submit' className='form-button'>
              Register
            </button>
          </form>
          
        </div>
      </div>
    </>
  )
}

export default RegisterScreen