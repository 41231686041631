import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { BsX } from 'react-icons/bs'
import { apiUrl } from '../Variables'
import { useForm } from 'react-hook-form';
import ErrorText from './ErrorText';
import { MdCloudUpload } from 'react-icons/md';
import MiniLoading from './MiniLoading';
import tempLogo from '../images/cat.png';
import Swal from 'sweetalert2'

function CreateGroupModel({setCreateGroupToggle, createGroupToggle, setCreateGroupSuccess}) {

  const [uploadLoading, setUploadLoading] = useState(false)
  const [logo, setLogo] = useState(null)
  const [createFail, setCreateFail] = useState(false)

  const {register, handleSubmit, formState: {errors}} = useForm();
  const [error, setError] = useState('')

  // file upload handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "pewhglsn")
    formData.append("cloud_name", "dzo2vmjdy")

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, config)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, config)
      setLogo(data?.url)
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  // throw error if group create fail
  useEffect(() => {
    if(createFail) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'This Channel/Group is already exist!',
      })
    }
  },[createFail])

  // submit handler || create new group or channel
  const onSubmit = async (data) => {
    const config = {
      headers: {
        'Accept': 'application/json',
        "Content-Type": 'application/json'
      }
    }
    
    try {
      setCreateGroupSuccess(false)
      const {data:resData} = await axios.post(`${apiUrl}/chatlog/group`, {...data, logo: logo, isPinned: false}, config)
      console.log("res data", resData)
      setCreateGroupSuccess(true)
    } catch (error) {
      setError(error)
      console.log(error)
      setCreateFail(true)
    }
  }

  return (
    <>
      <div className={createGroupToggle ? "create-user-wrapper active" : "create-user-wrapper"}>
        <form onSubmit={handleSubmit(onSubmit)} className="create-user-form">

          <div className="form-title">
            <h2>Create New Group/Channel</h2>
            {/* <p>Insert file name.</p> */}
            <BsX onClick={() => setCreateGroupToggle(!createGroupToggle)} className='time-icon' />
          </div>

          {/* export items */}
          <div className='create-group-wrapper'>

            {/* name */}
            <div className="form-group">
              <label htmlFor="">Group name</label>
                <input className={errors.name?.message && "error-input"} {...register('name', {required: "Name is required!"})} 
                type="text" placeholder='Write group label' />
                <ErrorText error={errors.name?.message} />
            </div>

            {/* Authentication code */}
            <div className="form-group">
              <label htmlFor="">Access Token <span className='required'>(required)</span></label>
                <input className={errors.authCode?.message && "error-input"} {...register('authCode', {required: "Access token is required!"})} 
                type="text" placeholder='5926675960:AAFm8xgRufI2wVw5I9bnoK8FS4WyDykDwOY' />
                <ErrorText error={errors.authCode?.message} />
            </div>

            {/* username */}
            <div className="form-group">
              <label htmlFor="">Channel Username <span className='required'>(required)</span></label>
                <input className={errors.username?.message && "error-input"} {...register('username', {required: "Name is required!"})} 
                type="text" placeholder='@wipdata_channel' />
            </div>

            {/* group */}
            <div className="form-group">
              <label htmlFor="">Channel Or Group ? </label>
              <select {...register('type', {required: "Type is required!"})}>
                <option value="channel">Channel</option>
                <option value="group">Group</option>
              </select>
            </div>

            {/* logo */}
            <div className="form-group">
              <label>
                <div className="user-profile-header-thumb create-group-logo">
                {uploadLoading && <MiniLoading />}
                  <img src={logo ? logo : tempLogo} alt="profile photo" />
                  <div className="user-update-profile create-user-profile-update-icon-wrapper">
                    <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                    <div className="user-update-profile">
                      <MdCloudUpload className='user-update-icon' />
                    </div>
                  </div>
                </div>
              </label>
            </div>


            <div className="form-group">
              <button onClick={() => setCreateGroupToggle(!createGroupToggle)} type='submit' className='btn create-group-btn'>Create</button>
            </div>
          </div>
          
        </form>
      </div>
    </>
  )
}

export default CreateGroupModel