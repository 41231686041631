import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BiCloudLightRain, BiMinus, BiPlus } from 'react-icons/bi';
import { Tab, TabList, Tabs } from 'react-tabs';
import { apiUrl } from './Variables';
// import 'react-tabs/style/react-tabs.css';

const template = "Client's aging more than MYR1000 for 3 months : Azam Wira - MYR 2500"
const telegramApi = 'https://api.telegram.org/bot5769160094:AAF0ucil8aEIiGcyoQtnLrxo-850z6uOEs4/sendMessage?chat_id=@wipdatabotchannel&text='
  const enter = '%0A'
  const currentDate = new Date().toLocaleString();
    // 1 h = 3600000 ms

function NotifyDropdown() {

  const [data, setData] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const [commonRow, setCommonRow] = useState([])
  const [filter3Month, setFilter3Month] = useState([])
  const [groupedMonth, setGroupMonth] = useState([])

  const [filter, setFilter] = useState({
    column: 'older',
    amount: 0,
    condition: false,
  })

  // fetch data form API
  useEffect(() => {

    (async() => {
      setError('')
      try {
        setLoading(true)
        const res = await axios.get(`${apiUrl}/scrap/xero`)
        console.log("res", res.data)
        setLoading(false)
        setData(res.data[0]?.xero?.receivable)
      } catch (error) {
        setError(error)
        console.log(error)
      }
    })()
  },[])

  // console.log(data)

  useEffect(() => {

    // get common column
    function getCommonFirstColumn() {
      const tempArr = []
      data?.map((item, index) => {
        const tepm = Object.keys(item)
        tempArr.push(tepm)
      })
      if(tempArr) {
        setCommonRow(tempArr[0])
      }
    }
    getCommonFirstColumn()

    // get filterd item
    function getFilterdItem() {
      const filterdMonths = []
      data?.filter((filterdItem, index) => {
        // if there '-' this symble then make it 0 and remove the comma form thousands. 
        const checkNumberExist = filterdItem[filter?.column] === '-' ? 0 : parseFloat(filterdItem[filter?.column].replace(/,/g, ''))

        if(filter?.condition === true) {
          if(checkNumberExist >= Number(filter?.amount) && checkNumberExist !== 0) {
            filterdMonths.push({...filterdItem, [filter?.column]:checkNumberExist})
          }
        } else {
          if(checkNumberExist <= Number(filter?.amount) && checkNumberExist !== 0) {
            filterdMonths.push({...filterdItem, [filter?.column]:checkNumberExist})
          }
        }
        
        setFilter3Month(filterdMonths)
      })
    }
    getFilterdItem()
  },[data, filter])

  console.log("groupedMonth", groupedMonth)

  useEffect(() => {
    // Filtered last 3 month array value into text formate. 
    function margeMonthValue() {
      const tempText = []
      filter3Month?.map((item, index) => {
        const joinedToString = `✅ ${item.contact} 👉 MYR ${item[filter?.column]}`
        tempText.push(joinedToString || {})
      })
      setGroupMonth(tempText || [])
    }
    margeMonthValue()
  }, [filter3Month])


  // onSubmit filter
  const filterHandler = async (e) => {
    e.preventDefault()

      try {
        setSuccess(true)
        setError(false)
        await axios.post(`${telegramApi} ${currentDate} - Client's aging ${filter?.condition === true ? 'more than' : 'less than'} MYR${filter?.amount.toLocaleString()} for ${filter?.column} : ${enter + enter + groupedMonth?.join(`${enter}`).replace(/&/g, "and")}`).toLocaleString()
        setSuccess(false)
      } catch (error) {
        console.log(error.message)
        setError(true)
      }
  }

  console.log("filter", filter)
  // console.log("filter3Month", filter3Month)


  return (
    <form onSubmit={filterHandler}>
      <div className="notify-container">

        {/* choose column */}
        <div className="choose-table-header">
          <label htmlFor="">Choose column</label>
          <select onChange={(e) => setFilter({...filter, column: e.target.value})} name="" id="">
            {
              commonRow?.map((item, index) => (
                <option value={item} key={index}>{item}</option>
              ))
            }
          </select>
        </div>


        {/* send option */}
        <Tabs>
          <label htmlFor="">Less or Bigger</label>
          <TabList className="choose-option choose-item">
            <Tab onClick={() => setFilter({...filter, condition: !filter.condition})}><button className='choose-button less-btn' type='button'> <BiMinus /></button></Tab>

            {/* money filed */}
            <div className="choose-amount-filed choose-item">
            {/* <label htmlFor="">Set amount</label> */}
              <input onChange={(e) => setFilter({...filter, amount: e.target.value})} type="number" placeholder='Amount' />
            </div>

            <Tab onClick={() => setFilter({...filter, condition: !filter.condition})}><button className='choose-button gether-btn' type='button'> <BiPlus /></button></Tab>
          </TabList>
        </Tabs>

        {/* send button */}
        <div className="choose-send-button choose-item">
          <button disabled={filter?.amount === 0} className={filter?.amount === 0 ? 'disabled' : ''} type='submit'>Send</button>
        </div>
      </div>
    </form>
  )
}

export default NotifyDropdown