import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlineStop } from 'react-icons/ai';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SettingsCard from '../components/settings/SettingsCard';
import { useAppContext } from '../context/AppContext';
import { apiUrl } from '../Variables';

function SettingsScreen() {

  const [settings, setSettings] = useState({
    siteName: '',
    siteTitle: '',
    siteDescription: '',
    logo: ''
  })
  const [success, setSuccess] = useState(false)
  const [fetchSuccess, setFetchSuccess] = useState(false)

  const {userInfo} = useAppContext()

    useEffect(() => {

      // fetching data from api/settings api. 
      const getSettings = async () => {
        try {
          setFetchSuccess(true)
          const {data} = await axios.get(`${apiUrl}/settings`)
          setSettings(data[0])
          setFetchSuccess(false)
        } catch (error) {
          console.log(error)
        }
      }
      getSettings()
    },[success])


    useEffect(() => {
      // check setting is updated
      if(success) {
        toast.success("Settings updated successfully!", {
          progress: undefined,
        })
      }
    },[success])

    console.log(settings)

    // update settings
    const handleUpdateSettingsHandler = async (e) => {
      e.preventDefault()
         
      // first delete existing settings collection
      try {
        await axios.delete(`${apiUrl}/settings/delete-all`)
      } catch (error) {
        console.log(error)
      }

      // second, create new collection for creating new settings
      try {
        const {data} = await axios.post(`${apiUrl}/settings`, settings)
        setSuccess(true)
      } catch (error) {
        console.log(error)
      }
    }

  return (
    <>
      <ToastContainer />
      {
        userInfo?.role === 'editor' ? (
          <div className="editor-not-allow">
            <div className="editor-na-alart">
              <AiOutlineStop />
              <span>Sorry!</span>
            </div>
            <h1>You don't have permissions!</h1>
            <p>Only Admin can access this page.</p>
          </div>
        ) : (
          <div className="settings-container p-20">

            {/* wrapper */}
            <div className="settings-wrapper p-20">
              <form onSubmit={handleUpdateSettingsHandler}>

                {/* left */}
                <div className="settings-left-wrapper">
                  <div className="form-title">
                    <h2>Widget settings</h2>
                    <p>Dummy content, You can allow or block permissions for a specific site. The site will use the permissions you set instead of the default settings.</p>
                  </div>

                  {/* Website name */}
                  <div className="form-group">
                    <label htmlFor="name">Website name</label>
                    <input type="text" id='name' value="Wipdata | Beyond Automation" placeholder='Wipdata | Beyond Automation' />
                  </div>

                  {/* Online status */}
                  <div className="form-group">
                    <label htmlFor="OnlineStatus">Online status</label>
                    <input type="text" value="We reply immediately" placeholder='Online status' id='OnlineStatus' />
                  </div>

                  {/* Message */}
                  <div className="form-group">
                    <label htmlFor="Message">Message</label>
                    <input type="text" value="Welcome to our website. Ask us anything" placeholder='Message' id='Message' />
                  </div>

                  {/* Role */}
                  <div className="form-group">
                    <label htmlFor="isAdmin">Online Visibility</label>
                    <select id="isAdmin">
                      <option value="both">Both on desktop and mobile devices</option>
                      <option value="desktop">Only mobile devices</option>
                      <option value="mobile">Only desktop devices</option>
                    </select>
                  </div>

                  {/* Offline message */}
                  <div className="form-group">
                    <label htmlFor="Message">Offline message</label>
                    <textarea type="text" value="We’re currently unavailable. We’ll get back to you when one of our operators is able to respond. Please provide your email address so we can get in touch with you. Welcome to our website. Ask us anything" placeholder='Message' id='Message'></textarea>
                  </div>
                  <button disabled={userInfo?.role === 'editor'} type='submit' className={userInfo?.role === 'admin' ? "form-button" : "form-button disabled"}>Save changes</button>
                </div>

                {/* right */}
                <SettingsCard settings={settings} setSettings={setSettings} fetchSuccess={fetchSuccess} />
                
              </form>
            </div>
          </div>
        )
      }
    </>
  )
}

export default SettingsScreen