import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { BiFilterAlt } from 'react-icons/bi'
import { BsChatLeftDots, BsX, BsXLg } from 'react-icons/bs'
import { FaTelegramPlane } from 'react-icons/fa'
import { FiSearch } from 'react-icons/fi'
import { IoNotificationsOutline } from 'react-icons/io5'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { apiUrl } from '../../Variables'
import chatbotIcon from '../../images/icons/robot.png'
import x from '../../images/icons/x.png'
import logo from '../../images/wipdata-logo.png'
import MiniLoading from '../MiniLoading'
import WhiteCanvas from '../WhiteCanvas'


const telegramApi = 'https://api.telegram.org/bot5769160094:AAF0ucil8aEIiGcyoQtnLrxo-850z6uOEs4/sendMessage?chat_id=@wipdatabotchannel&text='
  const enter = '%0A'
  const currentDate = new Date().toLocaleString();
    // 1 h = 3600000 ms
const imageNotFound = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtWkiWDS6J0sln0jthemckUqQxaA6dmbP9WFM0EtS7iOHmoDy1MsVonWIPyPOPYfZb3kc&usqp=CAU'

function AdminMessageBody({loading, setLoading, toggleReset, resetData, botChatlog, fullScreen, setFullScreen, showChat, setShowChat, groupId, username, authCode, setGroupFilterToggle, groupFilterToggle}) {
  const user = JSON.parse(localStorage.getItem('userInfo'))
  const [callToggle, setCallToggle] = useState(true);
  const [searchToggle, setSearchToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [whichIcon, setWhichIcon] = useState('');
  const [sticky, setSticky] = useState(window.scrollY); 
  const [bodyClick, setBodyClick] = useState(window);
  const [chat, setChat] = useState([])
  const [message, setMessage] = useState('')
  const [groupDetails, setGroupDetails] = useState(null)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [chatSendLoading, setChatSendLoading] = useState(false)

  // telegram dynamic channels
  const telegramModifyApiUrl = `https://api.telegram.org/bot${authCode}/sendMessage?chat_id=@${username}&text=`

  const chatRef = useRef(null); 
  const botRef = useRef(null); 

  // call cancle
  const callModel = (iconName) => {
    setCallToggle(!callToggle);
    setWhichIcon(iconName)
  }

  // fetch chats
  useEffect(() => {
    setChat(null)
    setGroupDetails(null)
    if (groupId) {
        const fetchChats = async() => {
          setLoading(true)
          await axios.get(`${apiUrl}/chatlog/group/${groupId}`).then(res => {
            console.log(res)
            setGroupDetails(res.data)
            setChat([...res.data.chats])
            setLoading(false)
          }).catch(err => {
            setLoading(false)
            console.log(err)
          })
        }
        fetchChats()
    }
  },[resetData, groupId])

  // submit or send message
  const handleSendMessage = async (e) => {
    e.preventDefault()
    setChatSendLoading(true)
    if (botChatlog.type === 'Whatsapp') {
      // Send message in whatsapp api
      try {
        const res = await axios.post(`${apiUrl}/telegram/whatsapp/whatsAppSendMsg`, { 
          number: botChatlog.name, 
          header: "", 
          body: message, 
          footer: "", 
          media: ""})
        console.log(res)
        toggleReset()
        setMessage("")
        setChatSendLoading(false)
      } catch (error) {
        setChatSendLoading(false)
        console.log("error", error)
        setError(true)
      }
    } else {

      // Send message in telegram chennel by API
      try {
        setSuccess(true)
        setError(false)
        await axios.post(`${telegramModifyApiUrl} ${message}`)
        setSuccess(false)
      } catch (error) {
        console.log(error)
        setError(true)
      }
  
      // Send message in our api
      try {
        const {data} = await axios.post(`${apiUrl}/chatlog/chat`, {group : groupId, user : user.name, chat: message})
        console.log(data)
        toggleReset()
        setMessage("")
        setChatSendLoading(false)
      } catch (error) {
        setChatSendLoading(false)
        console.log("error", error)
        setError(true)
      }
    }
  }

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: "smooth" })
    error && toast('Error')
  }, [message, chat])

  useEffect(() => {
    botRef.current?.scrollIntoView({ behavior: "smooth" })
  },[botChatlog])

  console.log('botChatlog', botChatlog)

  return (
    <>
    <ToastContainer />
      <div className="chatbox-wrapper">

          {
            groupId ? (
            <>
                {/* chatbot widget wrapper */}
              <div className="chatbox">
                
                {/* chat header */}
                {/* <div className={isScrolled ? "chatbot-widget-header chatbox-header active" : "chatbot-widget-header chatbox-header"}> */}
                <div className="chatbot-widget-header chatbox-header active">
  
                  {/* agent presence */}
                  <div className="chatbot-widget-agent-precence">
  
                    {/* agent left side */}
                    <div className="chatbot-widget-header-left">
                      {/* agent avatar */}
                      <div className="chatbot-recipient-avatar chatbox-recipient-avatar">
                          <img src={groupDetails?._doc?.logo || botChatlog?.img ? groupDetails?._doc?.logo || botChatlog?.img : imageNotFound} alt="agent avatar" />
                          <div className="online-status"></div>
                      </div>
  
                      {/* agent details */}
                      <div className="chatbot-widget-recipient-details chatbox-recipient-details">
                        <p>Chat with { botChatlog?.type ? botChatlog?.type : "Telegram" } </p>
                        <h3>{groupDetails?._doc?.name ? groupDetails?._doc?.name : botChatlog?.name}</h3>
                      </div>
                    </div>
  
                    {/* agent right side options */}
                    <div className="chatbot-widget-header-right">
  
                      {/* search */}
                      <div className={searchToggle ? 'search-model active' : 'search-model'}>
                        <input placeholder='Search here...' />
                        <FiSearch className='search-model-search-icon' />
                        <BsXLg onClick={() => setSearchToggle(!searchToggle)} />
                      </div>
  
                      {/* dropdown menu */}
                      <div className={menuToggle ? 'dropdown-menu-wrapper active' : 'dropdown-menu-wrapper'}>
                        {/* <ul>
                          <li>
                            <span>Archive</span> 
                            <VscArchive />
                          </li>
                          <li>
                            <span>Muted</span> 
                            <BiVolumeMute />
                          </li>
                          <li>
                            <span>Delete</span> 
                            <AiOutlineDelete />
                          </li>
                        </ul> */}
                        <BiFilterAlt />
                      </div>
  
                      {/* chatbot credit */}
                      <div className='chatbot-creator chatbox-creator'>
                        <p>Powered by</p>
                        <a href="https://www.wipdata.com/" target="_blank" rel="noreferrer">
                          <img src={logo} alt="site logo" />
                        </a>
                      </div>
  
                      <div className='chatbox-header-right-options'>
                        {/* search */}
                        <div className='chatbox-header-icon' onClick={() => setSearchToggle(!searchToggle)}>
                          <p style={{cursor:'pointer', marginRight:'10px', fontSize:'11px'}} onClick={() => toggleReset()}>refresh</p>
                          <FiSearch />
                        </div>
  
                        {/* audio call */}
                        {/* <div className='chatbox-header-icon' onClick={()=>callModel('audio')}>
                          <FiPhoneCall />
                        </div> */}
  
                        {/* video call */}
                        {/* <div className="mobile-hide chatbox-header-icon" onClick={()=>callModel('video')}>
                          <BsCameraVideo />
                        </div> */}
  
                        {/* resize or full screen */}
                        {/* <div className="mobile-hide chatbox-header-icon" onClick={()=>setFullScreen(!fullScreen)}>
                          <AiOutlineFullscreen />
                        </div> */}
  
                        {/* more */}
                        <div className='chatbox-header-icon' onClick={() => setGroupFilterToggle(!groupFilterToggle)}>
                          { !menuToggle ? <IoNotificationsOutline /> : <img src={x} alt="cancle" /> } 
                        </div>
  
                        <div onClick={() => setShowChat(!showChat)} className='chatbox-header-icon desktop-hide'>
                          <BsX className='chatbox-close-icon' />
                        </div>
  
                      </div>
                    </div>
                  </div>
                </div>
  
                {/* chat conversation group body */}
                <div className="chat-conversation-wrapper chatbox-conversation">

                {/* {chat?.length === 0 && <WhiteCanvas h2="Start conversation" p="Type something and start new conversation" icon={<BsChatLeftDots />} parentClass="not-shadow" />} */}

                  <ul className='message-body'>
  
                    {/* <li className="message-bubble-agent"></li>
                    <li className="message-day-slot"></li>
                    <li className="message-bubble-visitor"></li> */}

                    {chat?.length === 0 && <MiniLoading />}
                    {(chat && chat?.length === 0) && <WhiteCanvas h2="Start conversation" p="Type something and start new conversation" icon={<BsChatLeftDots />} parentClass="not-shadow" />}

                    {
                      chat?.length !== 0 && chat?.map((item, index) => (
                        <div ref={chatRef} className="chat-message-wrapper">
                          <li key={index} className="message-bubble-agent" dangerouslySetInnerHTML={{ __html: item.chat}}>{}</li>
                          <p className='chat-send-time'>{moment(item?.createdAt).fromNow('lll')}</p>
                        </div>
                      ))
                    }
                    {
                      botChatlog?.chats?.map((item, index) => (
                        <>
                          {
                            item?.user && <div className="chat-message-wrapper">
                              <li key={index} className="message-bubble-agent"><strong>User:</strong> {item?.user}</li>
                              <p className='chat-send-time'>{moment(item?.time).fromNow('lll')}</p>
                            </div>
                          }
                          {
                            item?.bot?.map((itm, itmIndex) => (
                                <div className="chat-message-wrapper" key={itmIndex}>
                                  <li key={index} className="message-bubble-agent"><strong>Bot:</strong> <span dangerouslySetInnerHTML={{ __html: itm?.text}}></span></li>
                                  <p className='chat-send-time'>{moment(item?.time).fromNow('lll')}</p>
                                </div>
                            ))
                          }
                          {
                            item?.agent?.map((itm, itmIndex) => (
                                <div className="chat-message-wrapper" key={itmIndex}>
                                  <li key={index} className="message-bubble-agent"><strong>Agent:</strong> <span dangerouslySetInnerHTML={{ __html: itm?.response}}></span></li>
                                  <p className='chat-send-time'>{moment(item?.time).fromNow('lll')}</p>
                                </div>
                            ))
                          }
                          <li ref={botRef}></li>
                        </>
                      ))
                    }

                    {
                      chat?.map((item, index) => (
                        <div ref={chatRef} className="chat-message-wrapper">
                          <li key={index} className="message-bubble-agent" dangerouslySetInnerHTML={{ __html: item?.chat}}>{}</li>
                          <p className='chat-send-time'>{moment(item?.createdAt).fromNow('lll')}</p>
                        </div>
                      ))
                    }
                    
                  </ul>
                </div>
  
                {/* chat footer */}
                {
                  (botChatlog?.type !== 'Web' || botChatlog?.type === 'Whatsapp') && <form className='chatbox-conversation-form' onSubmit={handleSendMessage}>
                  <div className="chatbot-footer chatbox-footer">
                    <div className="chatbot-footer-input-box">
                      <input value={message} onChange={(e) => setMessage(e.target.value)} type="text" placeholder='Start conversation...' />
                      <button type='submit' className='chatbot-send-message-button chatlogs-send-btn'>
                        {chatSendLoading ? <MiniLoading /> : <div className='send-msg-wrap'><FaTelegramPlane /> Send</div>}
                      </button>
                    </div>
                    {/* <div className="chatbot-footer-options">
                      <HiPlus />
                      <MdAttachFile className="mobile-hide" />
                      <BsFillEmojiSmileFill className='mobile-hide' />
                    </div> */}
                  </div>
                </form>
                }
              </div>
              </>
            ) : (
              <WhiteCanvas
                parentClass="chat-init-not-found"
                h2="Start your first conversation"
                p="Click on left side channal or group, and start messaging."
                icon={<img src={chatbotIcon} alt="drag" />}>
              </WhiteCanvas>
            )
          }
      </div>
    </>
  )
}

export default AdminMessageBody