import React, { useEffect, useState, useContext } from 'react'
import { FiSearch } from 'react-icons/fi'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'
import { MdDarkMode, MdLogout, MdOutlineLightMode } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'

import { useAppContext } from '../context/AppContext'
import logo from '../images/cat.png'

function Header({ mobileSidebar, setMobileSidebar, isMobile, toggleRefresh}) {

  const [dropdownToggle, setDropdownToggle] = useState(true)
  const [mode, setMode] = useState(false)
  const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light-theme')

  const navigate = useNavigate()

  const {userInfo, sidebarToggle, setSidebarToggle} = useAppContext();
  /* Method that will fix header after a specific scrollable */
  const scrollHandler = () => {
      const header = document.querySelector('header');
      const scrollTop = window.scrollY;
      (scrollTop >= 200) ? header.classList.add('active') : header.classList.remove('active');
  }

  useEffect(() => {
    if(isMobile) {
      window.addEventListener('scroll', scrollHandler)
        return () => {
          window.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [isMobile , userInfo])

  // burget menu click for both mobile and desktop sidebar
  const toggleSidebarHandler = () => {
    setSidebarToggle(!sidebarToggle)
    setMobileSidebar(!mobileSidebar)
  }

  // logout handler
  const handleLogout = () => {
    localStorage.removeItem('userInfo')
    toggleRefresh()
    navigate('/login')
  }

  const toggleTheme = () => {
    setMode(!mode)
    if(theme == 'light-theme') {
      localStorage.setItem('theme', 'dark-theme')
      const dark = localStorage.getItem('theme')
      setTheme(dark)
    } else {
      localStorage.setItem('theme', 'light-theme')
      const light = localStorage.getItem('theme')
      setTheme(light)  
    }
  }

  useEffect(() => {
    document.body.className = theme; 
  }, [theme, mode])
  
  return (
    <>
      <header>
        {/* header option */}
        <div className="header-option">

          {/* burger menu */}
          <HiOutlineMenuAlt2 onClick={toggleSidebarHandler} className='burger-menu-icon' />

          {/* search */}
          <div className="search-box">
              <input type="text" placeholder='Search...' />
              <FiSearch />
          </div>
        </div>
        {/* header profile */}
        <div className="header-profile" onClick={() => setDropdownToggle(!dropdownToggle)}>

          <div className="mode" onClick={toggleTheme}>
            { mode ? <MdDarkMode />  : <MdOutlineLightMode /> }
          </div>

          {/* profile */}
          <Link to='/profile'>
            <div className='header-logo-wrap'>
              <div className='header-logo'>
                <img src={userInfo?.profile ? `${userInfo?.profile}` : logo} alt="header" />
                
              </div>
              <div className='header-logo-content'>
                  <p>{userInfo?.name}</p>
                  <p className="role-badge">{userInfo?.role}</p>
                </div>
            </div>
          </Link>
          <li onClick={handleLogout} className='logout-btn'><MdLogout /> Logout</li>

          {/* { dropdownToggle ? <MdOutlineKeyboardArrowDown /> : <BsX /> }  */}
        
          {/* dropdown profile */}
          {/* <div className={dropdownToggle ? "header-profile-dropdown" : "header-profile-dropdown active"}>
            <ul>
              <li><Link to="/"> <AiOutlineUser /> Profile pictures</Link></li>
              <li><Link to="/"> <AiOutlineMail /> Inbox</Link></li>
              <li><Link to="/"> <AiOutlineSetting /> Settings</Link></li>
              <li onClick={handleLogout} className='logout-btn'><Link to="/"> <MdLogout /> Logout</Link></li>
            </ul>
          </div> */}
          
        </div>

      </header>
    </>
  )
}

export default Header