import axios from 'axios';
import React, { useState } from 'react';
import { MdCloudUpload } from 'react-icons/md';
import logo from '../../images/not-found.png';
import { useAppContext } from '../../context/AppContext';
import MiniLoading from '../MiniLoading';

function SettingsCard({ settings, setSettings, fetchSuccess }) {
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false)

  // logo handler
   const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "pewhglsn")
    formData.append("cloud_name", "dzo2vmjdy")

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      setLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, config)
      setLoading(false)
      setSettings({...settings, logo: data?.url})

      // const { data } = await axios.post(`${fileUrl}`, formData, config)
      // setSettings({...settings, logo: data})

    } catch (error) {
      console.error(error)
    }
  }

  const {userInfo} = useAppContext();

  console.log(userInfo.role)

  return (
    <div className="settings-right-card">
      {fetchSuccess && <MiniLoading />}
      {/* header */}
      <div className="settings-right-header">
        {/* upload logo */}
        {loading && <MiniLoading />}
        <label>
          <div className="user-profile-header-thumb settings-thumb">
            <img src={settings?.logo ? `${settings?.logo}` : logo} alt="profile photo" />
            <div className="user-update-profile settings-upload-icon">
              <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
              <div className="user-update-profile">
                <MdCloudUpload className='user-update-icon' />
              </div>
            </div>
          </div>
        </label>
        <div className="user-profile-content settings-profie-content">
          <h2><span className="user-title-name settings-title">{settings?.siteName}</span></h2>
        </div>
      </div>

      {/* body */}
      <div className="settings-card-body">
        {/* Website name */}
        <div className="form-group">
          <label htmlFor="name">Website name</label>
          <input type="text" id='name' disabled={userInfo?.role === 'editor'} value={settings?.siteName} onChange={(e) => setSettings({ ...settings, siteName: e.target.value })} placeholder='Your website name here' />
        </div>
        {/* Website name */}
        <div className="form-group">
          <label htmlFor="name">Website title</label>
          <input type="text" id='name' disabled={userInfo?.role === 'editor'} value={settings?.siteTitle} onChange={(e) => setSettings({ ...settings, siteTitle: e.target.value })} placeholder='Site title here' />
        </div>

        {/* Offline message */}
        <div className="form-group">
          <label htmlFor="Message">Site description</label>
          <textarea type="text" disabled={userInfo?.role === 'editor'} value={settings?.siteDescription} onChange={(e) => setSettings({ ...settings, siteDescription: e.target.value })} placeholder='Site descriptions' id='Message'></textarea>
        </div>

      </div>
    </div>
  )
}

export default SettingsCard