import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import ReactAudioPlayer from 'react-audio-player'
// import { Picker } from 'emoji-mart';
// import 'emoji-mart/css/emoji-mart.css'; // Option 1
// import 'emoji-mart/dist/emoji-mart.css'; // Option 2

import "react-datepicker/dist/react-datepicker.css";
import { FaTelegramPlane, FaTimes } from 'react-icons/fa';
import { apiUrl } from '../Variables';
// product images
import EmojiPicker from 'emoji-picker-react';
import { useRef } from 'react';
import { BsFillEmojiSmileFill, BsX } from 'react-icons/bs';
import { IoMdRefreshCircle } from 'react-icons/io';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import MiniLoading from '../components/MiniLoading';
import logo from '../images/aceva.png';
import messageimg from '../images/icons/message.svg';
import x from '../images/icons/times.svg';

const options = [
  { value: 'en', label: 'English' },
  { value: 'ms', label: 'Malay' },
  { value: 'zh-CN', label: 'Chinese' },
];

function FaqBotScreen({QueryParams, setQueryParams}) {
  const [toggle, setToggle] = useState(false); 
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  let audio = new Audio("/message.mp3")
  const [resize, setResize] = useState(false);
  const [staticResp, setStaticResp] = useState([]);
  const [menuToggle, setMenuToggle] = useState(false);
  const [width, setWidth] = useState(window.innerWidth); 
  const [emojiModel, setEmojiModel] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [translatedText, setTranslatedText] = useState('');
  const [botObj, setBotObj] = useState({
    name: "",
    email: "",
    mobile: "",
    guest: "",
    roomType: "",
    date: "",
    cost: 0,
    meal: "",
    roomNumber: "",

  })
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  // chatlists
  const [chatLists, setChatLists] = useState([]);
  const [userText, setUserText] = useState(''); 
  const [reset, setReset] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [deleteChats, seDeleteChats] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [message, setMessage] = useState('')
  const [language, setLanguage] = useState({source: 'en', target: ''})

  const onChange = (dates) => {
    const [start, end] = dates;
    setBotObj({...botObj, date: `${start?.toLocaleDateString()} to ${end?.toLocaleDateString()}`})
    setStartDate(start);
    setEndDate(end);
  };

  console.log("botObj", botObj)

  const mobileViewport = () => {
    setWidth(window.innerWidth)
  }

  const chatRef = useRef(null)

  const tranlateFunc = async (txts) => {
    if (language.source === 'en') {
      await axios.post(`${apiUrl}/gtranslate`, {
          "sourceLanguage": "",
          "targetLanguage": "en",
          "text": txts
      }).then(res => {
        setStaticResp(res.data.translations.map(i => i.translatedText))
        return res.data.translations.map(i => i.translatedText)
      })
    } else {
      await axios.post(`${apiUrl}/gtranslate`, {
          "sourceLanguage": "en",
          "targetLanguage": language.source,
          "text": txts
      }).then(res => {
        setStaticResp(res.data.translations.map(i => i.translatedText))
        return res.data.translations.map(i => i.translatedText)
      })
    }
  }

  useEffect(() => {
    setDeleteLoading(true)
    const fetchAllChats = async() => {
      await tranlateFunc([
        "Hi, I am your AI virtual assistant, how can I help you today?",
        'Product',
        'FAQ',
        "I am unable to login what should I do?",
        "Why I can't register to Touch 'n Go eWallet account?",
        "Can I change my mobile number?",
        "What do I need to do if I forgot my 6-digit PIN or my account has been suspended?",
        "How do I accept the loan offer once it is approved?"
      ])
      try {
        seDeleteChats(false)
        setDeleteLoading(true)
        const {data} = await axios.delete(`${apiUrl}/bot/faqbot`)
        seDeleteChats(true)
        setDeleteLoading(false)
        setError('')
        console.log(data)
      } catch (error) {
        console.log(error)
        seDeleteChats(false)
      }
      setDeleteLoading(false)
    }
    fetchAllChats();
  },[language])

  useEffect(() => {
    const fetchAllChats = async() => {
      try {
        const {data} = await axios.get(`${apiUrl}/bot/faqbot`)
        setChatLists(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchAllChats();
  },[reset, deleteChats])
  
  console.log('chatlists', chatLists)
  console.log('language', language)

  useEffect(()=> {
    chatRef.current?.scrollIntoView({ behavior: "smooth" })
  },[userText, chatLists, error])

  useEffect(() => {
    window.addEventListener('resize', mobileViewport)
    return () => window.removeEventListener('resize', mobileViewport)
    
  }, [])

  const handleToggle = (e) => {
    setToggle(!toggle);
  }

  const onEmojiClick = (event, emojiObject) => {
    setUserText(`${userText}${event.emoji}`);
    setEmojiModel(!emojiModel)
  };

  // open chat and delete or reset existing chats
  const handleOpenChatAndResetChat = async () => {
    setToggle(!toggle);
    try {
      seDeleteChats(false)
      setDeleteLoading(true)
      const {data} = await axios.delete(`${apiUrl}/bot/faqbot`)
      seDeleteChats(true)
      setDeleteLoading(false)
      setError('')
      console.log(data)
    } catch (error) {
      console.log(error)
      seDeleteChats(false)
      setDeleteLoading(false)
    }
  }

  const handleInputSubmit = async (e, next, key) => {
    e?.preventDefault(); 

    if (key) {
      setBotObj({...botObj, [key]: next});
    }

    try {
      setLoading(true)
      setError('')
      const res = await axios.post(`${apiUrl}/bot/faqbot`, {user:userText || next, ln: language});
      setReset(!reset)
      setUserText('')
      setLoading(false)
      console.log(res)
    } catch (error) {
      console.log(error);
      setError(error?.message === "Request failed with status code 500" && "Sorry, I can't get it 😔")
      setLoading(false)
      setUserText('')
    }
  }

  // delete chats handler
  const deleteChatsHandler = async() => {
    try {
      seDeleteChats(false)
      setDeleteLoading(true)
      const {data} = await axios.delete(`${apiUrl}/bot/faqbot`)
      seDeleteChats(true)
      setDeleteLoading(false)
      setError('')
      console.log(data)
    } catch (error) {
      console.log(error)
      seDeleteChats(false)
      setDeleteLoading(false)
    }
  }

  const capitalizeFirstLetter = (word) => {
    const words = word?.split(" ") || null; // Split the sentence into an array of words
    const capitalizedWord = words?.map((w, index) => {
      if (index === 0) {
        // Capitalize the first letter of the first word
        return w?.charAt(0).toUpperCase() + w?.slice(1);
      } else {
        return w; // Leave the other words unchanged
      }
    });
    return capitalizedWord?.join(" "); // Join the words back into a sentence
  };

  console.log("bot obj", botObj?.name)

  return (
    <>      
      {/* <TestDialogflowReq /> */}
      <section className='chatbot-section bot-demo-wrapper sales-bot-wrapper'>
        <div className={resize ? "chatbot-widget-wrapper full-screen" : "chatbot-widget-wrapper"}>

            {/* chatbot widget wrapper */}
            <div className={toggle ? "chatbot-widget active" : "chatbot-widget"}>
              
              {/* chat header */}
              <div className="chatbot-widget-header">

                {/* agent presence */}
                <div className="chatbot-widget-agent-precence">

                  {/* bot times icon */}
                  <div className='chatbot-times-icon' onClick={handleToggle}>
                    <img src={x} alt="x" /> 
                  </div>

                  {/* agent left side */}
                  <div className="chatbot-widget-header-left">
                    {/* agent avatar */}
                    <div className="chatbot-recipient-avatar faq-recipient-avatar">
                        <img src="https://assets-global.website-files.com/604f10ece77ed2575179c749/60c14f09516c063c7ee9620a_tngd-logo.svg" alt="agent avatar" />
                        <div className="online-status"></div>
                    </div>

                    {/* agent details */}
                    <div className="chatbot-widget-recipient-details">
                      <p>Chat with</p>
                      <h3>FAQ Bot
                      </h3>
                    </div>
                  </div>

                  {/* agent right side options */}
                  <div className="chatbot-widget-header-right">

                    <div className='chatbot-widget-header-right-options'>

                      {/* language */}
                      {/* <div className="chatbot-widget-minimize refresh-icon" onClick={async () => {                    
                        setLanguage(null)
                        }}>
                        <AiOutlineGlobal title="Language"/>
                      </div> */}

                      {/* select box */}
                      <div className='faq-select-wrap'>
                        <select className='language-select-box' onChange={(e) => {
                          setLanguage({source: e.target.value, target: 'en'})
                          }}>
                          {
                            options.map(i => <option value={i.value}>{i.label}</option>)
                          }
                        </select>
                      </div>

                      {/* minimize */}
                      {
                        chatLists?.length > 0 && (
                          <div onClick={deleteChatsHandler} className="chatbot-widget-minimize delete-chat-icon">
                            <IoMdRefreshCircle style={{width:'30px', height:'30px'}}/>
                          </div>
                        )
                      }

                      {/* minimize */}
                      <div className="chatbot-widget-minimize desktop-hide" onClick={handleToggle}>
                        <BsX />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* chat conversation group body */}
              <div className="chat-conversation-wrapper bot-message-wrapper">
                {/* message body */}
                <div className="message-body chatbot-message-body faq-message-body">
                  <ul>
                    { deleteLoading && <MiniLoading /> }

                      <li>
                        <img className='agent-logo' src="https://assets-global.website-files.com/604f10ece77ed2575179c749/60d2b43c935a7d72bee11ec0_tng-logo.svg" alt="bot" />
                          <div className="chat-bubble-wrap faq-bubble-wrap">
                            <p className='message-bubble-agent faq-bubble-agent '>{staticResp[0]}</p>
                            <div className="message-auto-suggest-buttons">
                              
                                <button onClick={() => handleInputSubmit(null, "product")} className='suggest-btn'>
                                    <span>{capitalizeFirstLetter(staticResp[1])}</span>
                                </button>
                                <button onClick={() => handleInputSubmit(null, "faq")} className='suggest-btn'>
                                    <span>{capitalizeFirstLetter(staticResp[2])}</span>
                                </button>
                              </div>
                          </div>
                      </li>


                  {/* conversations */}
                  {
                    chatLists?.map((item, index) => (
                      <div key={index}>

                        {/* user */}
                        <li className="message-bubble-visitor faq-bubble-visitor">
                          <span className=''>{capitalizeFirstLetter(item?.user)}</span>
                        </li>

                        {/* bot response */}
                        <li>
                            <img className='agent-logo' src="https://assets-global.website-files.com/604f10ece77ed2575179c749/60d2b43c935a7d72bee11ec0_tng-logo.svg" alt="bot" />
                          <div className="chat-bubble-wrap faq-bubble-wrap">
                            {
                              item?.chat?.response === "" ? (
                                <>
                                  <p className='message-bubble-agent faq-bubble-agent '>{`Thank you, ${botObj?.name}. Your confirmation details are as below :`} </p>
                                  <div className='message-bubble-agent faq-bubble-agent  booking-summery-wrap'>
                                    <li>Room Type: <span className='sum-item-badge sum-item-room-type-badge'>{botObj?.roomType}</span></li>
                                    <li>Booking Date: <span className='sum-item-badge'>{botObj?.date} </span> </li>
                                    <li>Number of Guests: <span className='sum-item-badge'>0{botObj?.guest}</span> </li>
                                    <li>Total Cost: <span className='sum-item-badge'>$1080</span></li>
                                  </div>
                                  <p className='message-bubble-agent faq-bubble-agent  booking-end-msg'>Here are payment methods you can choose to pay.</p>

                                  <div className="message-auto-suggest-buttons">
                                    <button onClick={() => handleInputSubmit(null, "Book a Room")} className='suggest-btn'>
                                          <span>Paypal</span>
                                    </button>

                                    <button onClick={() => handleInputSubmit(null, "Room Services")} className='suggest-btn'>
                                          <span>Stripe</span>
                                    </button>

                                    <button onClick={() => handleInputSubmit(null, "Payment and Checkout")} className='suggest-btn'>
                                          <span>Apple Pay</span>
                                    </button>
                                  </div>
                                  
                                  <p className='message-bubble-agent faq-bubble-agent  booking-end-msg payment-question-text'>Is there anything else today?</p>
                                </>
                              ) : (
                                // <p className='message-bubble-agent faq-bubble-agent '>{item?.chat?.response} </p>
                                <div dangerouslySetInnerHTML={{ __html: item?.chat?.response }} />
                              )
                            }

                            {/* if button, then show the button layout */}
                            {item?.chat?.payloadType === "button" && (
                              <div className="message-auto-suggest-buttons">
                                
                                {
                                  item?.chat?.payload?.map((btn, btnIndex) => (
                                    btn?.next === "reset" ? (
                                      <button onClick={deleteChatsHandler} className='suggest-btn' key={btnIndex}>
                                      <span>{capitalizeFirstLetter(btn?.text || '')}</span>
                                    </button>
                                    ) : (
                                      <button onClick={() => handleInputSubmit(null, btn?.next, btn?.key)} className='suggest-btn' key={btnIndex}>
                                      <span>{capitalizeFirstLetter(btn?.text || '')}</span>
                                    </button>
                                    )
                                    
                                  ))
                                }
                              
                              </div>
                            )}

                            {/* if type === "text" */}
                            {item?.chat?.payloadType === "text" && (
                              <div className="message-auto-suggest-buttons">
                                
                                {
                                  item?.chat?.payload?.map((btn, btnIndex) => (
                                    btn?.next === "reset" ? (
                                      <button onClick={deleteChatsHandler} className='suggest-btn' key={btnIndex}>
                                      <span>{btn?.text}</span>
                                    </button>
                                    ) : (
                                      <button className='suggest-btn' key={btnIndex}>
                                      <span>{btn?.text}</span>
                                    </button>
                                    )
                                    
                                  ))
                                }
                              
                              </div>
                            )}

                            {/* if link, then show the link layout */}
                            {item?.chat?.payloadType === "link" && (
                              <div className="message-auto-suggest-buttons">
                                
                                {
                                  item?.chat?.payload?.map((linkItem, index) => (
                                    linkItem.next === "reset" ? (
                                      <button onClick={deleteChatsHandler} key={index} className="extarnal-link"> {linkItem?.text} </button>
                                    ) : (
                                      <a key={index} className="extarnal-link" href={`${linkItem?.next}`}> {linkItem?.text} </a>
                                    )
                                  ))
                                }
                              
                              </div>
                            )}

                            {/* if curosol, then show the curosol layout */}
                            {item?.chat?.payloadType === "curosol" && (
                              <div style={{width:'300px'}}>
                                <Swiper
                                slidesPerView={3}
                                spaceBetween={10}
                                slidesPerGroup={1}
                                centeredSlides={true}
                                loop={true}
                                loopFillGroupWithBlank={false}
                                navigation={true}
                                modules={[Navigation]}
                                className="chatbot-swiper-container faq-swiper-container"
                              >
                                {
                                    item?.chat?.payload?.map((btn, btnIndex) => (
                                      <SwiperSlide onClick={() => handleInputSubmit(null, btn?.next, btn?.key)} key={btnIndex}>
                                        <div className="curosol-item" key={btnIndex}>
                                          <img src={btn?.image} alt={btn?.text} />
                                          <p className='suggest-btn'>{btn?.text}</p>
                                        </div>
                                      </SwiperSlide>
                                    ))
                                }
                              
                              </Swiper>

                              <p className='message-bubble-agent faq-bubble-agent  booking-end-msg faq-curosol-agent'>Is there anything else today?</p>
                            </div>
                            // <div className="message-auto-suggest-buttons">

                            //   <div className="curosol-wrap">
                            //     {
                            //       // item?.chat?.payload?.map((btn, btnIndex) => (
                            //       //     <div onClick={() => handleInputSubmit(null, btn?.next)} className="curosol-item" key={btnIndex}>
                            //       //       <img src={btn?.image} alt={btn?.text} />
                            //       //       <p className='suggest-btn'>{btn?.text}</p>
                            //       //     </div>
                            //       // ))
                            //     }
                            //     </div>
                                
                            //   </div>
                            )}

                            {/* if close, then show the close layout */}
                            {item?.chat?.payloadType === "filled" && (
                            <div className="message-auto-suggest-buttons">

                                {
                                  item?.chat?.payload?.map((btn, btnIndex) => (
                                    <button onClick={() => handleInputSubmit(null, btn?.next)} className='suggest-btn message-bubble-filled color-black' key={btnIndex}>
                                      <span>{btn?.text}</span>
                                    </button>
                                  ))
                                }
                                
                              </div>
                            )}

                          </div>
                          
                        </li>
                      </div>
                    ))
                  }


                  {/* babble typing... */}
                  {loading && (
                    <li className="typing-item-wrap">
                      <img className='agent-logo' src="https://assets-global.website-files.com/604f10ece77ed2575179c749/60d2b43c935a7d72bee11ec0_tng-logo.svg" alt="bot" />
                      <div className="typing-wrap message-bubble-agent faq-bubble-agent ">
                        <span className="typing">Typing</span>
                        <span className="animate-typing">
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                        </span>
                      </div>
                    </li>
                  )}
                  {
                    error && (
                      <li>
                        <img className='agent-logo' src="https://assets-global.website-files.com/604f10ece77ed2575179c749/60d2b43c935a7d72bee11ec0_tng-logo.svg" alt="bot" />
                          <div className="chat-bubble-wrap">
                            <p className='message-bubble-agent faq-bubble-agent '>{error}</p>
                          </div>
                      </li>
                    )
                  }
                  <li ref={chatRef}></li>
                </ul>
                </div>
              </div>

              {/* chat footer */}
              <div className="chatbot-footer">
                <div className="chatbot-footer-input-box mini-bot-footer">
                  <form onSubmit={handleInputSubmit}>
                    <input onClick={() => setEmojiModel(false)} disabled={loading} value={userText} onChange={(e) => {
                      setUserText(e.target.value)
                    }} type="text" placeholder='Start conversation...' />
                    <button type='submit' className='chatbot-send-message-button'>
                      {loading ? <h6 style={{color:'white', fontSize:'10px'}}>Sending..</h6> : <FaTelegramPlane />}
                    </button>
                  </form>
                </div>
                <div className="chatbot-footer-options">
                  <BsFillEmojiSmileFill onClick={() => setEmojiModel(!emojiModel)} />
                </div>
              </div>
              <div className={emojiModel ? "emoji-wrap active" : "emoji-wrap"}>
                <EmojiPicker onEmojiClick={onEmojiClick} height={400} width={372} />
              </div>
          </div>

            {/* chatbot open icon && if resize is true the hide chatbot icon */}
            <div className={toggle ? 'chatbot-mini-preview-wrap active' : 'chatbot-mini-preview-wrap'} onClick={handleOpenChatAndResetChat}>
              <div className="chatbot-mini-preview-left">
                <img className='bot-preview-img' src={messageimg} alt="message icon" /> 
                <span className='send-us-message'>Send us a message</span>
              </div>
              <div className="chatbot-mini-preview-right">
                <h5>FAQ BOT</h5>
              </div>
            </div>
            {/* <ReactAudioPlayer
              src={audio}
              controls
              className='audio-hidden'
            /> */}
        </div>
        </section>
    </>
  )
}

export default FaqBotScreen