import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
// import {MdRestartAlt} from 'react-icons/gr'
import { AiOutlineDelete } from 'react-icons/ai'
import { FiX } from 'react-icons/fi'
import { MdRestartAlt } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { notifications } from '../Variables'
import MiniLoading from '../components/MiniLoading'
import { useAppContext } from '../context/AppContext'
import wipdata from "../images/aceva.png"

function NotificationScreen() {

  const [sidebar, setSidebar] = useState(false);
  const [editUser, setEditUser] = useState(false)
  const [exportModel, setExportModel] = useState(false)
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [NotiData, setNotiData] = useState([])
  const [detailsNoti, setDetailsNoti] = useState({})
  const [result ,setResult] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [updateNotification, setUpdateNotification] = useState([])
  const [editUserInfo, setEditUserInfo] = useState({})
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [filterRoleValue, setFilterRoleValue] = useState('all')

  console.log("NotiData", NotiData)
  console.log("update notification", updateNotification)

  const [filterReset, setFilterReset] = useState({
    title: "",
    channel_name: "",
    to: "",
    from: "",
  });

  const {userInfo, apiUrl} = useAppContext(); 
  const navigate = useNavigate();

  useEffect(() => {
    if(deleteSuccess) {
      navigate('/users')
    }
    const featchData = async () => {
      setLoading(true)
      await axios.get(notifications).then(res => {
        console.log('notires', res)
        setLoading(false)
        setNotiData(res.data)
        setUpdateNotification(res.data)
      }).catch((error) => {
        console.log("error", error)
        setLoading(false)
      })
    }
    featchData()
    
  },[result, deleteSuccess, updateSuccess, filterRoleValue, apiUrl])

  // filter name
  const handleFilterName = (name) => {
    const filteredData = NotiData?.filter((item) => {
      const fullName = `${item?.title}`;
      if (fullName.toLowerCase().includes(name.toLowerCase())) {
        return item;
      } else {
        return "";
      }
    });

    setUpdateNotification(filteredData);
    setFilterReset(name)
  };

  // Department filter
  const handleFilterDepartment = (departmentType) => {
    const filteredData = NotiData?.filter((filterdItem) => {
      return filterdItem.channel_name === departmentType
    })
    setUpdateNotification(filteredData);
    setFilterReset(departmentType)
  }

  console.log("detailsNoti", detailsNoti)

  return (
    <>
      <div onClick={() => setSidebar(false)} className={sidebar ? "details-sidebar-container active" : "details-sidebar-container"}>

        {/* notification details sidebar */}
        <div onClick={e => e.stopPropagation()} className={sidebar ? "details-sidebar active" : "details-sidebar"}>
          {/* header */}
          <div className="details-sidebar-header">
            <div className="left-sidebar-details">
              <div className="left-sidebar-details-thumb">
                <img src={detailsNoti.channel_name === 'Web' ? wipdata : ''} alt="" />
              </div>
              <article className='sidebar-article'>
                {/* <h2>{detailsNoti.channel_name}</h2> */}
                {/* <h3>{detailsNoti.bot_name}</h3> */}
                <h3>ACEVA AI</h3>
              </article>
            </div>
            <div className="right-sidebar-icons noti-filter">

              <div className="form-group mb-unset">
                <select value={filterReset?.department}>
                    <option value="forward">Forward</option>
                    <option value="email">Email</option>
                    <option value="delete">Delete</option>
                    <option value="settings">Settings</option>
                </select>
              </div>
              {/* <div className="action-icons">
                <BsReplyAllFill />
                <BsCloudDownload />
                <AiOutlineMail />
                <AiOutlineDelete />
              </div> */}
              <div className="close-icon" onClick={() => setSidebar(!sidebar)}>
                <FiX />
              </div>
            </div>
          </div>

          {/* body */}
          <div className="details-sidebar-body">

            <div className="details-sidebar-wrap">
              <h2>{detailsNoti.title}</h2>

              <p dangerouslySetInnerHTML={{ __html: detailsNoti.body}} >{}</p>
              
            </div>
          </div>
          
            {/* agent */}
            <div className="sidebar-agent">
              <div className="agent-thumb">
                <img src={detailsNoti.channel_name === 'Web' ? wipdata : ''} alt="agent" />
              </div>
              <div className="agent-details">
                <h3>{detailsNoti.department}</h3>
                <p>{moment(detailsNoti.createdAt).format('lll')}</p>
              </div>
            </div>
        </div>

      </div>

      {/* table */}
      <div className="table-container">

        {/* table filter */}
        <div className="table-filter-container">
        {loading && <MiniLoading />}

        {/* filter */}
        <div className="table-filter noti-filter">
          <div className="form-group">
            <input value={filterReset?.title} type="text" onChange={(e) => handleFilterName(e.target.value)} placeholder='Search notification...' />
          </div>

          {/* platform */}
          <div className="form-group">
            <select value={filterReset?.channel_name} onChange={(e) => handleFilterDepartment(e.target.value)} id="platform">
                <option value="Web">Web</option>
                <option value="Facebook">Facebook</option>
                <option value="Whatsapp">Whatsapp</option>
            </select>
          </div>

          {/* date */}
          {/* <div className="date-filter noti-date-filter">
              <h6>From</h6>
              <div className='date-item' style={{display:'flex', alignItems:'center'}}>
                <input type='date'/> 
                <h6 style={{margin:'0 5px'}}>to</h6> 
                <input type='date'/>
              </div>
          </div> */}
          

          {/* status */}
          {/* <div className="form-group">
            <select value={filterReset?.department} onChange={(e) => handleFilterDepartment(e.target.value)} id="department">
                <option value="on">On</option>
                <option value="off">Off</option>
            </select>
          </div> */}

          {/* reset */}
          <div className="form-group reset-filter-btn"
            onClick={(e) => 
              {
                e.preventDefault()
                setFilterReset({
                    title: "",
                    channel_name: "",
                    to: "",
                    from: "",
                })
                setUpdateNotification(NotiData)
              }
            }
          >
            <MdRestartAlt className='reset-button reset-noti'/>
            <span>Reset Filter</span>
          </div>
          {/* <div className="form-group">
            <IoMdMore className='reset-button reset-noti' />
          </div> */}
        </div>

        {/* export button */}
        {/* <div className="table-export">
          <span className='export-button'> <HiOutlineRefresh /> Refresh </span>
        </div> */}
        

        </div>

        {/* table wrapper */}
        <div className="table-wrapper">
          <table>
              <tr>
                <th>platform</th>
                {/* <th>Status</th> */}
                <th>Phone</th>
                <th>Description</th>
                {/* <th>Unique ID</th> */}
                <th>Date</th>
              </tr>
              <tbody className='notification-tbody'>
                {/* item */}
                {
                  updateNotification?.map((item, index) => (
                    <tr key={index}>
                      <td><span>{item.channel_name}</span></td>
                      {/* <td>
                        <span className='status-badge off-badge'>Off</span>
                        <span className='status-badge on-badge'>On</span>
                      </td> */}
                      <td>+88-01818457875</td>
                      {/* <td onClick={() => {
                        setDetailsNoti(item)
                        setSidebar(!sidebar)
                        }} className='noti-title flex-noti-title'> 
                        <img src={item?.channel_name === 'Web' ? wipdata : ''} className="table-social-icon" alt="" /> 
                      </td> */}
                      
                      <td onClick={() => {
                        setDetailsNoti(item)
                        setSidebar(!sidebar)
                        }}> <span className='noti-title'>{item?.title}</span> - {item?.body.substring(0, 80)}...
                      </td>
                      {/* <td><span className='unique-id'>#D4D5SGR4</span></td> */}

                      <div className='hover-date-item'>
                        <div className='hover-icons'> 
                          {/* <BsReplyAllFill />
                          <BsCloudDownload /> */}
                          <AiOutlineDelete />
                        </div>
                      </div>
                      <span className='date-item'>{moment(item.createdAt).format('lll')}</span>
                      
                    </tr>
                  ))
                }
              </tbody>
              
          </table>
          {
            NotiData?.length === 0 && <div className='table-data-not-found'>Nothing for display!</div>
          }

        </div>
          {/* pagination */}
          {/* <div className="pagination-wrapper">
            <div className="pagination">
              <a href="#">&laquo;</a>
              <a className="active" href="#">1</a>
              <a href="#">2</a>
              <a href="#">3</a>
              <a href="#">4</a>
              <a href="#">5</a>
              <a href="#">6</a>
              <a href="#">&raquo;</a>
            </div>
          </div> */}
      </div>
    </>
  )
}

export default NotificationScreen