import React, { useEffect, useId, useState } from 'react'
import Accordion from './Accordion'
import AnalyticsFieldItem from './AnalyticsFieldItem'

function AnalyticsFields({fileData}) {

  const [sheet, setSheet] = useState('')
  const [toggleField, setToggleField] = useState(false)

  const [commonRow, setCommonRow] = useState([])
  const [filter3Month, setFilter3Month] = useState([])
  const [groupedMonth, setGroupMonth] = useState([])

  const [filter, setFilter] = useState({
    column: 'older',
    amount: 0,
    condition: false,
  })

  const id = useId();

  const tempArr = (arr) => {
    return {
      id: id,
      data: arr,
    }
  }
  
  useEffect(() => {
    if(fileData !== "undefined") {
      setSheet(Object.keys(fileData?.dataArray)[0] || null)
    }
  },[fileData])
  
  const handleToggle = (index) => {
    setToggleField(!toggleField)
  }

  // get sheet header column wise data filter
/*   const getTableWiseData = (sheetName, sheetHeaderIndex, sheetHeaderName) => {
    console.log(sheetName, sheetHeaderIndex, sheetHeaderName)
    const tempArray = {
      label: sheetHeaderName,
      id: sheetHeaderIndex,
      sheet: sheetName,
    }
    const dataArr = []
    setItemClick(false)
    for (let i = 1; i < fileData?.dataArray[sheetName]?.length ; i++) { // first we map all sheet
      dataArr.push(fileData?.dataArray[sheetName][i][sheetHeaderIndex]) // here, loop the map and matching sheet data wise clicked current index. if current and sheet header field current index is match then push the code on tempArray[]. 
      setItemClick(!itemClick)
    }
    tempArray.data = dataArr; 
    setTableData(tempArray)
  } */

  // console.log("anayltics filed", tableData)

  // initialize to empty Array if Undefined
  // const specificSheetArr = fileData?.xero[sheet];
  // const dynamicSheetArr = specificSheetArr || [];

  useEffect(() => {
    // get common column
    function getCommonFirstColumn() {
      const tempArr = []
      fileData?.dataArray?.payable?.map((item, index) => {
        const tepm = Object.keys(item)
        tempArr.push(tepm)
      })
      if(tempArr) {
        setCommonRow(tempArr[0])
      }
    }
    getCommonFirstColumn()
  },[fileData])

  return (
    <>
      <ul className="analytics-sidebar-item analytics-fileds-wrapper">
        {/* Sheet names */}
      { fileData?.sheetArray?.map((sheet, sheetIndex) => (
        <li key={sheetIndex}>
          <Accordion title={sheet}>
            {
              commonRow?.map((sheetHeaderName, sheetHeaderIndex) => (
                <>
                      <AnalyticsFieldItem 
                        key={sheetHeaderIndex}
                        sheet={sheet} 
                        label={sheetHeaderName}
                        id={sheetHeaderIndex}
                      />
                </>
              ))
            }
          </Accordion>
        </li>
        )) }
      </ul>
      
    </>
  )
}

export default AnalyticsFields