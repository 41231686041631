import React, { useEffect, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { Link, useSearchParams } from 'react-router-dom'
import BotDetailsMessageBody from '../components/BotDetailsMessageBody'

import Logistic from '../images/icons/container.png'
import Factory from '../images/icons/factory.png'
import Hospital from '../images/icons/hospital.png'

function BotDemoDetailsScreen() {

  const inputRef = useRef(null);

  const [searchParams] = useSearchParams()

  const [width, setWidth] = useState(window.innerWidth);
  const [showChat, setShowChat] = useState(false);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const isMobile = width <= 768;

  return (
    <>
      <div className='admin-page-wrapper'>

        {/* chat body */} 
        <div className='chat-body-wrapper'>

          {/* sidebar */}
          <div className='chat-body-sidebar'>
            <div className='chatbox-sidebar-wrap'>
              <div className='chat-title chat-title-big'>
                <h2>Chats</h2>
              </div>

              {/* search */}
              <div className='chat-body-search'>
                <input ref={inputRef} placeholder='Search messages or users' />
                <FiSearch />
              </div>

              {/* recent chat */}
              <div className='chat-title'>
                <h2>Bots</h2>
              </div>

              {/* recent chat wrap */}
              <div className='recent-chat'>
                <ul className='recent-chat-wrap'>

                  {/* item */}
                  <li className={searchParams.get('botType') === 'healthcare' ? 'recent-chat-item active' : 'recent-chat-item'} onClick={() => setShowChat(!showChat)}>
                    <Link to="/demo/chatbot?botType=healthcare">
                      <div className='recent-chat-item-content'>
                        {/* thumb */}
                        <div className='featured-chat-thumb featured-list-bot-thumb'>
                          <img src={Hospital} alt="chat one" />
                          
                        </div>
                        {/* content */}
                        <div className='recent-chatbox-content'>
                          <h2>Healthcare Bot</h2>
                          <div className='recent-chatbox-user-chat'>
                            <span>Health care bot is only for health industry.</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>

                  {/* item */}
                  <li className={searchParams.get('botType') === 'factory' ? 'recent-chat-item active' : 'recent-chat-item'} onClick={() => setShowChat(!showChat)}>
                    <Link to="/demo/chatbot?botType=factory">
                      <div className='recent-chat-item-content'>
                        {/* thumb */}
                        <div className='featured-chat-thumb featured-list-bot-thumb'>
                          <img src={Factory} alt="chat one" />
                          
                        </div>
                        {/* content */}
                        <div className='recent-chatbox-content'>
                          <h2>Manufacturer Bot</h2>
                          <span>Your service is awesome. </span>
                        </div>
                      </div>
                    </Link>
                  </li>

                  {/* item */}
                  <li className={searchParams.get('botType') === 'logistic' ? 'recent-chat-item active' : 'recent-chat-item'} onClick={() => setShowChat(!showChat)}>
                    <Link to="/demo/chatbot?botType=logistic">
                      <div className='recent-chat-item-content'>
                        {/* thumb */}
                        <div className='featured-chat-thumb featured-list-bot-thumb'>
                          <img src={Logistic} alt="chat one" />
                          
                        </div>
                        {/* content */}
                        <div className='recent-chatbox-content'>
                          <h2>Logistic Bot</h2>
                          <span>The bot allows you to optimize warehouse.</span>
                        </div>
                      </div>
                    </Link>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          

          {/* message body */}
          <div className={showChat ? 'chat-message-body chatbox-message-body active' : 'chat-message-body chatbox-message-body'}>
            <BotDetailsMessageBody />
          </div>
        </div>
      </div>
    </>
  )
}

export default BotDemoDetailsScreen