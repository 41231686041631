import axios from 'axios'
import React, { useEffect, useState } from 'react'
// import ReactAudioPlayer from 'react-audio-player'
import "react-datepicker/dist/react-datepicker.css"
import { FaTelegramPlane } from 'react-icons/fa'
import { apiUrl } from '../Variables'
// product images
import { useRef } from 'react'
import MiniLoading from '../components/MiniLoading'
import logo from '../images/brand-logo-left.png'
import message from '../images/icons/message.svg'
import x from '../images/icons/times.svg'


import { BsXLg } from 'react-icons/bs'
import { GrRefresh } from 'react-icons/gr'
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"


function SamsungBotScreen({QueryParams, setQueryParams}) {

  const options = [
    { value: 'en', label: 'English' },
    { value: 'ta', label: 'Tamil' },
    { value: 'kn', label: 'Kannada' },
    { value: 'te', label: 'Telugu' }
  ];

  const [language, setLanguage] = useState({source: 'en', target: ''})

  const [staticResp, setStaticResp] = useState([]);

  const tData = [
    {
      intent: 'Enterprise IT Support', response: ['Getting started', 'Windows', 'Basics', 'Network & Internet', 'Applications', 'External devices', 'Troubleshooting', 'Appendix']
    },
    {
      intent: 'Getting started', response: ['Layout', 'Connecting the AC adapter', 'Turning the computer on and off', 'Unlocking the screen']
    },
    {
      intent: 'Basics', response: ['Touch screen', 'Keyboard', 'Touchpad', 'LCD', 'brightness', 'Volume Setup', 'utility (BIOS)']
    },
    {
      intent: 'Windows', response: ['About Windows', 'Help+Tips', 'Screens in Windows 8.1', 'Desktop', 'Start screen', 'Using the Start button', 'The Charms', 'Settings charm']
    },
    {
      intent: 'Network & Internet', response: ['Wired LAN', 'Wireless LAN', 'Mobile broadband', 'Wireless WAN']
    },
  ]

  const [toggle, setToggle] = useState(false); 

  let audio = new Audio("/message.mp3")
  const [resize, setResize] = useState(false);
  const [formToggle, setFormToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [width, setWidth] = useState(window.innerWidth); 
  const [emojiModel, setEmojiModel] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [botObj, setBotObj] = useState({
    name: "",
    email: "",
    mobile: "",
    guest: "",
    roomType: "",
    date: "",
    cost: 0,
    meal: "",
    roomNumber: "",

  })

  // chatlists
  const [chatLists, setChatLists] = useState([]);
  const [userText, setUserText] = useState(''); 
  const [reset, setReset] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(''); 
  const [deleteChats, seDeleteChats] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setBotObj({...botObj, date: `${start?.toLocaleDateString()} to ${end?.toLocaleDateString()}`})
    setStartDate(start);
    setEndDate(end);
  };

  console.log("botObj", botObj)

  const mobileViewport = () => {
    setWidth(window.innerWidth)
  }

  const chatRef = useRef(null)

  const tranlateFunc = async (txts) => {
    await axios.post(`${apiUrl}/gtranslate`, {
        "sourceLanguage": "",
        "targetLanguage": language.source,
        "text": txts
    }).then(res => {
      setStaticResp(res.data.translations.map(i => i.translatedText))
      return res.data.translations.map(i => i.translatedText)
    })
}

  useEffect(()=> {
    chatRef.current?.scrollIntoView({ behavior: "smooth" })
  },[userText, chatLists, error])

  useEffect(() => {
    tranlateFunc([
      "Please select from below options to connect to specific chatbot.", "Enterprise IT Support", "HR Direct Support", "PO Invoice & Payment Vendor Support", "Travel & Expense Support",    "R&D Sun Engineering Environment", "PLM Digital Twin Production Learning Assistant", "SPIS Environment Support", "XRM - Contingent Worker Hiring Support & SOW", "Ethics & Complience Support"
    ])
    window.addEventListener('resize', mobileViewport)
    return () => window.removeEventListener('resize', mobileViewport)
  }, [language])

  const handleToggle = (e) => {
    setToggle(!toggle);
  }

  // open chat and delete or reset existing chats
  const handleOpenChatAndResetChat = async () => {
    setToggle(true)
    setChatLists([])
  }

  const handleInputSubmit = async (e, next, key) => {
    e?.preventDefault(); 

    const lbtn = tData.find(item => item.intent === next)

    if (lbtn) {
      setChatLists([...chatLists, {bot: lbtn?.response, payloadType: "button", user: next, time: new Date()}])
    } else {
      try {
        setLoading(true)
        setError('')
        const tempUserText = userText
        setChatLists([...chatLists, {user: tempUserText || next}])
        setUserText('')
        const res = await axios.post(`${apiUrl}/telegram/demo/samsungCallBack`, {message: tempUserText || next, ln: language.source});
        console.log(res)
        chatLists[chatLists.length - 1] = res.data
        setChatLists([...chatLists])
        setLoading(false)
        console.log(res)
      } catch (error) {
        console.log(error);
        setError(error?.message === "Request failed with status code 500" && "Sorry, I can't get it 😔")
        setLoading(false)
        setUserText('')
      }

    }
  }

  // delete chats handler
  const deleteChatsHandler = async() => {
    setChatLists([])
  }


  console.log("bot obj", botObj?.name)

  return (
    <>      
        {/* <TestDialogflowReq /> */}
      <section className='chatbot-section bot-demo-wrapper samsung-bot-demo-wrapper'>
        <div className={resize ? "chatbot-widget-wrapper full-screen" : "chatbot-widget-wrapper"}>

            {/* chatbot widget wrapper */}
            <div className={toggle ? "chatbot-widget active" : "chatbot-widget"} style={{background: 'white'}}>
              
              {/* chat header */}
              <div className="chatbot-widget-header">

                {/* agent presence */}
                <div className="chatbot-widget-agent-precence">

                  {/* bot times icon */}
                  <div className='chatbot-times-icon' onClick={handleToggle}>
                    <img src={x} alt="x" /> 
                  </div>

                  {/* agent left side */}
                  <div className="chatbot-widget-header-left">
                    {/* agent avatar */}
                    <div className="chatbot-recipient-avatar">
                        <img src={logo} alt="agent avatar" />
                        <div className="online-status"></div>
                    </div>

                    {/* agent details */}
                    <div className="chatbot-widget-recipient-details">
                      <p>Chat with</p>
                      <h3>Dodla Dairy Chatbot
                      </h3>
                    </div>
                  </div>

                  {/* agent right side options */}
                  <div className="chatbot-widget-header-right">

                    {/* chatbot credit */}
                    {/* <div className='chatbot-creator'>
                      <p>Powered by</p>
                      <a href="https://www.wipdata.com/" target="_blank" rel="noreferrer">
                        <img src={logo} alt="site logo" />
                      </a>
                    </div> */}

                    <div className='chatbot-widget-header-right-options'>
                       {/* select box */}
                       <div className='faq-select-wrap'>
                        <select className='language-select-box' onChange={(e) => {
                          setLanguage({source: e.target.value, target: 'en'})
                          }}>
                          {
                            options.map(i => <option value={i.value}>{i.label}</option>)
                          }
                        </select>
                      </div>

                      {/* minimize */}
                      <div onClick={deleteChatsHandler} className="chatbot-widget-minimize delete-chat-icon">
                        <GrRefresh style={{width:'30px', height:'30px'}}/>
                        {/* <span>Close chat</span> */}
                      </div>

                      {/* minimize */}
                      <div className="chatbot-widget-minimize desktop-hide" onClick={handleToggle}>
                        <BsXLg />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* welcome */}
              {/* <div className="welcome-the-bot">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, minima?</p>
                <button>Start</button>
              </div> */}
              {/* chat conversation group body */}
              <div className="chat-conversation-wrapper bot-message-wrapper">
                {/* message body */}
                <div className="message-body chatbot-message-body">
                  <ul>
                    { deleteLoading && <MiniLoading /> }

                      <li className='dodla-bot-msg-li'>
                        <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            <p className='message-bubble-agent'>{staticResp[0] || "Please select from below options to connect to specific chatbot."}</p>
                            <div className="message-auto-suggest-buttons samsung-button-wrap">
                                {/* <button onClick={() => handleInputSubmit(null, "Room availability")} className='suggest-btn'> Room availability </button> */}
                                <button onClick={() => handleInputSubmit(null, "Enterprise IT Support")} className='suggest-btn'>
                                      <span>{staticResp[1] || "Enterprise IT Support"}</span>
                                </button>
                                
                                <button onClick={() => handleInputSubmit(null, "Room Services")} className='suggest-btn'>
                                      <span>{staticResp[2] || "HR Direct Support"}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Payment and Checkout")} className='suggest-btn'>
                                      <span>{staticResp[3] || "PO Invoice & Payment Vendor Support"}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "Travel & Expense Support")} className='suggest-btn'>
                                      <span>{staticResp[4] || "Travel & Expense Support"}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "R&D Sun Engineering Environment")} className='suggest-btn'>
                                      <span>{staticResp[5] || "R&D Sun Engineering Environment"}</span>
                                </button>
                             
                                <button onClick={() => handleInputSubmit(null, "PLM Digital Twin Production Learning Assistant")} className='suggest-btn'>
                                      <span>{staticResp[6] || "PLM Digital Twin Production Learning Assistant"}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "SPIS Environment Support")} className='suggest-btn'>
                                      <span>{staticResp[7] || "SPIS Environment Support"}</span>
                                </button>

                                <button onClick={() => handleInputSubmit(null, "XRM - Contingent Worker Hiring Support & SOW")} className='suggest-btn'>
                                      <span>{staticResp[8] || "XRM - Contingent Worker Hiring Support & SOW"}</span>
                                </button>
                                
                                <button onClick={() => handleInputSubmit(null, "Ethics & Complience Support")} className='suggest-btn'>
                                      <span>{staticResp[9] || "Ethics & Complience Support"}</span>
                                </button>
                              </div>
                          </div>
                      </li>

                  {/* conversations */}
                  {
                    chatLists?.map((item, index) => (
                      <div key={index}>

                        {/* user */}
                        <li className="message-bubble-visitor">
                          <span className=''>{item?.user}</span>
                        </li>

                        {/* bot response */}
                        {
                          item.bot && <li>
                          <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            {/* if button, then show the button layout */}
                            {item?.payloadType === "button" && (
                              <div className="message-auto-suggest-buttons">
                                
                                {
                                  item?.bot?.map((btn, btnIndex) => (
                                    <button onClick={() => handleInputSubmit(null, btn, btn)} className='suggest-btn' key={btnIndex}>
                                      <span>{btn}</span>
                                    </button>
                                  ))
                                }
                              
                              </div>
                            )}
                            {item?.payloadType !== "button" && <div dangerouslySetInnerHTML={{ __html: item?.bot?.replace(/\n/g, '<br />')?.replace(/\*\*(.*?)\*\*/g, "<i>$1</i>") }} />}
                          </div>
                        </li>
                        }
                      </div>
                    ))
                  }


                  {/* babble typing... */}
                  {loading && (
                    <li>
                      <img className='agent-logo' src={logo} alt="bot" />
                      <div className="typing-wrap message-bubble-agent">
                        <span className="tying">Typing</span>
                        <span className="animate-typing">
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                          <span className="dot ms-1"></span>
                        </span>
                      </div>
                    </li>
                  )}
                  {
                    error && (
                      <li>
                        <img className='agent-logo' src={logo} alt="bot" />
                          <div className="chat-bubble-wrap">
                            <p className='message-bubble-agent'>{error}</p>
                          </div>
                      </li>
                    )
                  }
                    <li className='reset-style' ref={chatRef}></li>
                </ul>
                </div>
              </div>

              {/* chat footer */}
              <div className="chatbot-footer">
                <form onSubmit={handleInputSubmit}>
                    <div className="chatbot-footer-input-box mini-bot-footer">
                      <input value={userText} onChange={(e) => setUserText(e.target.value) } type="text" placeholder='Start conversation...' />
                      <button className='chatbot-send-message-button'>
                        <FaTelegramPlane />
                      </button>
                    </div>
                </form>
            </div>
          </div>

            {/* chatbot open icon && if resize is true the hide chatbot icon */}
            <div className={toggle ? 'chatbot-mini-preview-wrap active' : 'chatbot-mini-preview-wrap'} onClick={handleOpenChatAndResetChat}>
              <div className="chatbot-mini-preview-left">
                <img className='bot-preview-img' src={message} alt="message icon" /> 
                <span className='send-us-message'>Send us a message</span>
              </div>
              <div className="chatbot-mini-preview-right">
                <h5>ACEVA BOT</h5>
              </div>
            </div>
            {/* <ReactAudioPlayer
              src={audio}
              controls
              className='audio-hidden'
            /> */}
        </div>
        </section>
    </>
  )
}

export default SamsungBotScreen