import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import Chatbot from '../components/Chatbot'
import Logistic from '../images/icons/container.png'
import Factory from '../images/icons/factory.png'
import Hospital from '../images/icons/hospital.png'
import Layout from '../components/Layout'

function BotDemoScreen() {
  const [toggle, setToggle] = useState(false); 
  const [QueryParams, setQueryParams] = useState('');

  return (
    <>
      {/* <Layout> */}
      <div className="integrate-container">
        {/* <div className="integrate-header">
          <h3> <BsPlusCircleDotted /> Third Party Integrate</h3>
        </div> */}
        {/* wrapper */}
        <div className="integrate-wrapper">
          {/* item */}
          <div className="integrate-item bot-demo-item">
            <div className="integrate-icon">
              <img src={Hospital} alt="instagram" />
            </div>
            <div className="integrate-content">
              <h2 className='text-instagram'>Healthcare Bot</h2>
              <p>Do it now and get response from ACEVA healthcare bot.</p>
              <button onClick={() => {
                if (QueryParams === 'healthCare') {
                  setQueryParams('')
                  setToggle(!toggle)
                } else {
                  setQueryParams('healthCare')
                  setToggle(true)
                }
                }} className='btn integrate-btn bot-demo-launch-btn'>
                Launch
              </button>
            </div>
          </div>
          {/* item */}
          <div className="integrate-item bot-demo-item">
            <div className="integrate-icon">
              <img src={Factory} alt="instagram" />
            </div>
            <div className="integrate-content">
              <h2 className='text-instagram'>Manufacturer Bot</h2>
              <p>Do it now and start responding to queries from instagram.</p>
              <button onClick={() => {
                  if (QueryParams === 'factory') {
                    setQueryParams('')
                    setToggle(!toggle)
                  } else {
                    setQueryParams('factory')
                    setToggle(true)
                  }
                }} className='btn integrate-btn bot-demo-launch-btn'>
                Launch
              </button>
            </div>
          </div>
          {/* item */}
          <div className="integrate-item bot-demo-item">
            <div className="integrate-icon">
              <img src={Logistic} alt="instagram" />
            </div>
            <div className="integrate-content">
              <h2 className='text-instagram'>Logistic Bot</h2>
              <p>Do it now and start responding to queries from instagram.</p>
              <button className='btn integrate-btn bot-demo-launch-btn'>
              <Link to="/demo?botType=logistic">Launch</Link>
              </button>
            </div>
          </div>

        </div>
      </div>
      <Chatbot toggle={toggle} setToggle={setToggle} QueryParams={QueryParams} setQueryParams={setQueryParams}/>
      {/* </Layout> */}
    </>
  )
}

export default BotDemoScreen