import React from 'react'

import email from '../images/icons/email.png'
import instagram from '../images/icons/instagram.png'
import telegram from '../images/icons/telegram.png'
import twitter from '../images/icons/twitter.png'
import whatsapp from '../images/icons/whatsapp.png'
import {BsPlusCircleDotted, BsPlusSquare} from 'react-icons/bs'
import XeroIntegrate from '../components/integrate/XeroIntegrate'
import AnalyticsIntegrate from '../components/integrate/AnalyticsIntegrate'

function IntegrateScreen() {
  return (
    <>
      <div className="integrate-container">
        <div className="integrate-header">
          <h3> <BsPlusCircleDotted /> Third Party Integrate</h3>
        </div>
        {/* wrapper */}
        <div className="integrate-wrapper">
          {/* item */}
          <XeroIntegrate />
          <AnalyticsIntegrate />


          {/* item */}
          {/* <div className="integrate-item">
            <div className="integrate-icon">
              <img src={instagram} alt="instagram" />
            </div>
            <div className="integrate-content">
              <h2 className='text-instagram'>Connect with instagram</h2>
              <p>Do it now and start responding to queries from instagram.</p>
            </div>
          </div> */}
          {/* item */}
          {/* <div className="integrate-item">
            <div className="integrate-icon">
              <img src={whatsapp} alt="whatsapp" />
            </div>
            <div className="integrate-content">
              <h2 className='text-whatsapp'>Connect with whatspp</h2>
              <p>Do it now and start responding to queries from whatsapp.</p>
            </div>
          </div> */}
          {/* item */}
          {/* <div className="integrate-item">
            <div className="integrate-icon">
              <img src={telegram} alt="telegram" />
            </div>
            <div className="integrate-content">
              <h2 className='text-telegram'>Connect with telegram</h2>
              <p>Do it now and start responding to queries from telegram.</p>
            </div>
          </div> */}
          {/* item */}
          {/* <div className="integrate-item">
            <div className="integrate-icon">
              <img src={email} alt="email" />
            </div>
            <div className="integrate-content">
              <h2 className='text-email'>Connect with email</h2>
              <p>Do it now and start responding to queries from email.</p>
            </div>
          </div> */}
          {/* item */}
          {/* <div className="integrate-item">
            <div className="integrate-icon">
              <img src={twitter} alt="twitter" />
            </div>
            <div className="integrate-content">
              <h2 className='text-twitter'>Connect with twitter</h2>
              <p>Do it now and start responding to queries from twitter.</p>
            </div>
          </div> */}
          
        </div>
      </div>
    </>
  )
}

export default IntegrateScreen