import React, { useEffect, useRef, useState } from 'react'
import { BsFillEmojiSmileFill } from 'react-icons/bs'
import { FaTelegramPlane, FaTimes, FaVideo } from 'react-icons/fa'
import { MdAttachFile, MdCall } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import logo from '../images/aceva.png'

// product images
import happyChat from '../images/peoples/happy-chat.jpg'
import FactoryBot from './chatBotTypes/FactoryBot'
import HealthCareBot from './chatBotTypes/HealthCareBot'


function BotDetailsMessageBody() {
  
  const [callToggle, setCallToggle] = useState(true);
  const [searchToggle, setSearchToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [whichIcon, setWhichIcon] = useState('');
  const [sticky, setSticky] = useState(window.scrollY); 
  const [bodyClick, setBodyClick] = useState(window);

  const [searchParams] = useSearchParams()

  const windowBodyClick = () => {
    setBodyClick(window)
  }

  useEffect(() => {
    window.addEventListener('click', windowBodyClick)
    return () => window.removeEventListener('click', windowBodyClick)
  }, [])

  const stickyChatHeader = () => {
    setSticky(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', stickyChatHeader)
    return () => window.removeEventListener('scroll', stickyChatHeader)
  }, [])

  const isScrolled = sticky >= 0

  const searchRef = useRef(null);
  const chatRef = useRef(null); 

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  // call cancle
  const callModel = (iconName) => {
    setCallToggle(!callToggle);
    setWhichIcon(iconName)
  }

  return (
    <>
      <div className="chatbox-wrapper">

        {/* call model */}
        <div className={callToggle ? "call-model-container" : "call-model-container active"}>
          <div className='call-model-wrapper'>
            <div className='call-user-avatar'>
              <img src={happyChat} alt="user on call" />
            </div>
            {/* user name */}
            <div className='call-user-content'>
              <h2>Doris Brown</h2>
              <p>Start { whichIcon == "audio" && "Audio" || whichIcon == "video" && "Video" } Call</p>
            </div>
            {/* user actions */}
            <div className='call-user-actions'>
              <div className='call-user-icon' onClick={callModel}>
                <FaTimes />
              </div>
              <div className='call-user-icon'>
              {whichIcon=="audio"&&<MdCall /> || whichIcon=="video" && <FaVideo />}
              {}
              </div>
            </div>
          </div>
        </div>

          {/* chatbot widget wrapper */}
          <div className="chatbox">
            
            {/* chat header */}
            <div className={isScrolled ? "chatbot-widget-header chatbox-header active" : "chatbot-widget-header chatbox-header"}>

              {/* agent presence */}
              <div className="chatbot-widget-agent-precence">

                {/* agent left side */}
                <div className="chatbot-widget-header-left">
                  {/* agent avatar */}
                  <div className="chatbot-recipient-avatar chatbox-recipient-avatar">
                      <img src={logo} alt="agent avatar" />
                      <div className="online-status"></div>
                  </div>

                  {/* agent details */}
                  <div className="chatbot-widget-recipient-details chatbox-recipient-details">
                    <p>Chat with</p>
                    <h3>HealthCare Assistant</h3>
                  </div>
                </div>

                {/* agent right side options */}
                <div className="chatbot-widget-header-right">

                  {/* chatbot credit */}
                  <div className='chatbot-creator chatbox-creator'>
                    <p>Powered by</p>
                    <a href="https://www.wipdata.com/" target="_blank" rel="noreferrer">
                      <img src={logo} alt="site logo" />
                    </a>
                  </div>

                </div>
              </div>
            </div>

            {/* chat conversation group body */}
            <div className="chat-conversation-wrapper chatbox-conversation">
              <ul ref={chatRef} className='message-body'>
              {
                ((foo) => {
                  if(searchParams.get('botType') === "healthcare") {
                    return <HealthCareBot />
                  } else if(searchParams.get('botType') === "factory") {
                    return <FactoryBot />
                  }
                  })()
              }
              </ul>
            </div>

            {/* chat footer */}
            <div className="chatbot-footer chatbox-footer">
              <div className="chatbot-footer-input-box">
                <input type="text" placeholder='Start conversation...' />
                <button className='chatbot-send-message-button'>
                  <FaTelegramPlane />
                </button>
              </div>
              <div className="chatbot-footer-options">
                {/* <HiPlus /> */}
                {/* <MdAttachFile />
                <BsFillEmojiSmileFill /> */}
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default BotDetailsMessageBody