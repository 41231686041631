import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BsX } from 'react-icons/bs'
import { apiUrl } from '../Variables'
import { data } from '../components/SidebarData'


import { FiChevronDown } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import logo from '../images/wipdata.png'

function Sidebar({isMobile, sidebarToggle, setSidebarToggle}) {

  const [settings, setSettings] = useState({});
  const [update, setUpdate] = useState(false)
  const [toggle, setToggle] = useState(false)

  const clickToRemoveSidebar = () => {
    setSidebarToggle(!sidebarToggle)
  }

  useEffect(() => {
    const fetchSettingsForLogo = async() => {
      setUpdate(false)
      const {data} = await axios.get(`${apiUrl}/settings`)
      setSettings(data[0])
      // setUpdate(true)
    }
    fetchSettingsForLogo()
  },[/*update*/])

  return (
    <>
      <aside className={sidebarToggle ? 'sidebar' : 'sidebar active'}>
            <ul className={isMobile ? "sidebar" : "sidebar active"} onClick={isMobile && clickToRemoveSidebar}>
              <BsX className='mobile-sidebar-cross' onClick={() => setSidebarToggle(!sidebarToggle)} />
              <li>
                <NavLink to="/">
                  <div className="header-logo-wrap">
                    <div className="header-sidebar-logo">
                      <img src={settings?.logo ? `${settings?.logo}` : logo} alt="logo" />
                    </div>
                  {/* <h2 className="header-site-title">{settings?.siteName}</h2> */}
                </div>
                </NavLink>
              </li>
              
              {
                data?.map((item, index) => (
                  <li key={index} className={toggle ? "" : "dropdown-menu-item active"} onClick={() => setToggle(!toggle)} >
                    <NavLink to={item.path} className={({ isActive }) =>isActive ? "active-link" : ""}>
                      <div className='flex-label-icon'>
                      {item.icon}
                      { sidebarToggle === false && <span>{item.label}</span> }
                      </div>
                      { item?.dropdown && <FiChevronDown className='dropdown-icon' /> }
                    </NavLink>
                    
                    {/* sub menu */}
                    { item?.dropdown && (
                      <div className="sub-menu-wrapper">
                        <ul>
                          {
                            item?.subMenu?.map((sub, subIndex) => (
                              <li onClick={() => setToggle(!toggle)} key={subIndex}>
                                <NavLink to={sub?.path} className={({ isActive }) =>isActive ? "active-link" : ""}>
                                  <div className='flex-label-icon'>
                                  {sub?.icon}
                                  <span>{sub?.label}</span>
                                  </div>
                                </NavLink>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    ) }
                  </li>
                ))
              }
              
            </ul>
        </aside>
    </>
  )
}

export default Sidebar