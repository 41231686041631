import React, {useState, useEffect} from 'react'
import {MdIndeterminateCheckBox, MdOutlineSpaceDashboard} from 'react-icons/md'
import {BsChat, BsPlusCircleDotted, BsX} from 'react-icons/bs'
import {FiDatabase} from 'react-icons/fi'
import {SiGoogleanalytics} from 'react-icons/si'
import {AiOutlineSetting, AiOutlineUser} from 'react-icons/ai'
import {data} from '../../components/SidebarData'

import logo from '../../images/wipdata.png'
import { NavLink } from 'react-router-dom'

function MobileSidebar({isMobile, mobileSidebar, setMobileSidebar}) {

  const clickToRemoveSidebar = () => {
    setMobileSidebar(!mobileSidebar)
  }


  return (
    <>
      {/* mobile sidebar */}
      <aside className={mobileSidebar ? 'sidebar' : 'sidebar active'}>
            <ul onClick={isMobile && clickToRemoveSidebar}>
              <BsX className='mobile-sidebar-cross' onClick={() => setMobileSidebar(!mobileSidebar)} />
              <li>
                <NavLink to="/">
                <div className="header-logo">
                  <img src={logo} alt="logo" />
                </div>
                </NavLink>
              </li>
              
              {
                data.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.path} className={({ isActive }) =>isActive ? "active-link" : ""}>
                      {item.icon}
                      <span>{item.label}</span>
                    </NavLink>
                  </li>
                ))
              }
              
            </ul>
          </aside>
    </>
  )
}

export default MobileSidebar