import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import AllUserScreen from './screens/AllUserScreen';
import AnalyticsScreen from './screens/AnalyticsScreen';
import ChatlogsScreen from './screens/ChatlogsScreen';
import DataScreen from './screens/DataScreen';
import IntegrateScreen from './screens/IntegrateScreen';
import LoginScreen from './screens/LoginScreen';
import PageNotFoundScreen from './screens/PageNotFoundScreen';
import RegisterScreen from './screens/RegisterScreen';
import SettingsScreen from './screens/SettingsScreen';
import HomeScreen from './screens/home/HomeScreen';
// import {UserContext} from './context/UserContext'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { apiUrl } from './Variables';
import { AppContextProvider, useAppContext } from './context/AppContext';
import BotDemoDetailsScreen from './screens/BotDemoDetailsScreen';
import BotDemoScreen from './screens/BotDemoScreen';
import BotScreen from './screens/BotScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import NotificationScreen from './screens/NotificationScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import FaqBotScreen from './screens/FaqBotScreen';
import UserProfile from './screens/UserProfile';
import BulkMessageScreen from './screens/BulkMessageScreen';
import PTPBotScreen from './screens/PTPBotScreen';
import ABMSBUC from './screens/ABMSBUC';
import SamsungBotScreen from './screens/SamsungBotScreen';
// import XeroScreen from './screens/XeroScreen';
// import AgedReceiveableScreen from './screens/AgedReceiveableScreen';
// import TelegramScreen from './screens/TelegramScreen';

function App() {
  const [settings, setSettings] = useState({});
  const [update, setUpdate] = useState(false)
  const [userInfo, setUserInfo] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null)
  const [refresh, setRefresh] = useState(false)
  const toggleRefresh = () => setRefresh(!refresh)
  useEffect(() => {
    const fetchSettingsForHelmet = async() => {
      setUpdate(false)
      const {data} = await axios.get(`${apiUrl}/settings`)
      setSettings(data[0])
      setUpdate(true)
    }
    fetchSettingsForHelmet()
    setUserInfo(JSON.parse(localStorage.getItem('userInfo')))
  },[refresh])
  return (
    <>
      <BrowserRouter>

      {/* Helmet */}
        <Helmet>
            <title>{`${settings?.siteName ? settings?.siteName : 'WipData'} | ${settings?.siteTitle ? settings?.siteTitle : 'Beyond Automation'}`}</title>
            <meta name="description" content={settings?.siteDescription} />
        </Helmet>

        <AppContextProvider>

          {userInfo ? <Layout toggleRefresh={toggleRefresh}>
              <Routes>
                <Route path='/' element={<HomeScreen />} />
                <Route path='/analytics' element={<AnalyticsScreen />} />
                <Route path='/notification' element={<NotificationScreen />} />
                <Route path='/chatlogs' element={<ChatlogsScreen />} />
                <Route path='/integrations' element={<IntegrateScreen />} />
                <Route path='/users' element={<AllUserScreen />} />
                <Route path='/profile' element={<UserProfile />} />
                <Route path='/data' element={<DataScreen />} />
                <Route path='/settings' element={<SettingsScreen />} />
                <Route path='/demo' element={<BotDemoScreen />} />
                <Route path='/demo/chatbot' element={<BotDemoDetailsScreen />} />
                <Route path='/faq-bot' element={<FaqBotScreen />} />
                <Route path='/bulk-message' element={<BulkMessageScreen />} />
                <Route path='/bot-demo' element={<BotScreen />} />
                <Route path='/ptp-bot' element={<PTPBotScreen />} />
                <Route path='/new-bot-demo' element={<SamsungBotScreen />} />
                {/* <Route path='/xero' element={<XeroScreen />} />
                <Route path='/aged' element={<AgedReceiveableScreen />} />
                <Route path='/telegram' element={<TelegramScreen />} /> */}
                <Route path="*" element={<PageNotFoundScreen />} />
              </Routes>
            </Layout> : <Routes>
              <Route path='/bot-demo' element={<BotScreen />} />
              <Route path='/new-bot-demo' element={<SamsungBotScreen />} />
              <Route path='/ABMSBUC' element={<ABMSBUC />} />
              <Route path="/login" element={<LoginScreen toggleRefresh={toggleRefresh}/>}/>
              <Route path="/register" element={<RegisterScreen toggleRefresh={toggleRefresh}/>}/>
              <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
              <Route path='/reset-password/:id/:token' element={<ResetPasswordScreen />} />
            </Routes> 
          }


        </AppContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
