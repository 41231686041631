import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangle, BsX } from 'react-icons/bs';
import { MdCloudUpload, MdOutlineImportExport } from 'react-icons/md';
import { SiMicrosoftexcel } from 'react-icons/si';
import { VscDiffAdded } from 'react-icons/vsc';
import Swal from 'sweetalert2';
import { apiUrl } from '../Variables';
import BulkMessageImportModel from '../components/BulkMessageImportModel';
import MiniLoading from '../components/MiniLoading';
import AddNumberModel from '../components/model/AddNumberModel';
import fileUrl from '../number-sheet-v2.xlsx';
import Spinner from 'react-bootstrap/Spinner';
import { AiFillRightCircle } from 'react-icons/ai';

const logo = '../images/bg-logo.png'
const tempLogo = "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png"


function ABMSBUC() {
  const [exportModel, setExportModel] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)

  const [numbers, setNumbers] = useState([]);
  const [newNumber, setNewNumber] = useState('')
  const [updateNumbers, setUpdateNumbers] = useState([]);
  const [importedNumbers, setImportedNumbers] = useState([]);

  const [msgSendSuccess, setMsgSendSuccess] = useState(false);
  const [msgSendLoading, setMsgSendLoading] = useState(false); 


  const [message, setMessage] = useState({
    numbers: [],
    header: "",
    body: "",
    footer: "",
    media: ""
  })

  useEffect(() => {
    const sendBulkmessage = async () => {
      setMsgSendLoading(true)
      let responses = [];
      let promises = [];
      for (const item of importedNumbers) {
        promises.push(
            axios.post(`${apiUrl}/telegram/whatsapp/whatsAppSendMsgV2`, { number: item.Mobile_Number, uVariable: item.Invoice_url}).then(response => {
            // do something with response
            responses.push(response);
            console.log(response)
          }).catch(error => {
            // Handle error
            console.error('Error sending message:', error);
          })
        )
      }
      Promise.all(promises).then(() => {
        setMsgSendLoading(false)
        setImportedNumbers([])
        setMsgSendSuccess(!msgSendSuccess)
      }).catch(async error => {
        // Handle error
        console.error('Error sending message:', error);
      });
    }
    if(importedNumbers.length > 0) {
      setExportModel(false)
      sendBulkmessage()
    }
  },[importedNumbers])

  const exportModelhandle = (e) => {
    e.preventDefault();
  }


  // after successfull send message show the alart
  useEffect(() => {
    if(msgSendSuccess) {
      Swal.fire({
        position: 'middle',
        icon: 'success',
        title: 'Blast Successfull!',
        showConfirmButton: false,
        timer: 1500
      })
    }
  },[msgSendSuccess])

  return (
    <>

      {/* popup model */}
      <div className={exportModel ? "create-user-wrapper active" : "create-user-wrapper"}>
        <form onSubmit={exportModelhandle} className="create-user-form bulk-msg-create-user-form" style={{background:'white'}}>

          <div className="form-title bulk-import-title">
          <SiMicrosoftexcel className='excel-text' />
            <span>Upload Excel File</span>
            {/* <p>Insert file name.</p> */}
            <BsX onClick={() => setExportModel(!exportModel)} className='time-icon' />
          </div>

          {/* export items */}
          <BulkMessageImportModel setImportedNumbers={setImportedNumbers} />
          
        </form>
      </div>

      <div className="bulk-message-container">

            {/* bulk message header */}
            <div className="bulk-message-header">
              <h3>Automated Bulk Message Send By Uploading XL</h3>
              <div className="flex">
                <button onClick={() => setExportModel(!exportModel)} className="btn c-btn">Import numbers</button>
                {/* { importedNumbers?.length > 0 && <button onClick={() => setImportedNumbers([])} className="btn c-btn c-btn-remove">Remove numbers</button> } */}
              </div>
            </div>

            
            {
              importedNumbers?.length > 0 ? <>
                 <div className="init-bulk-message">
                  <div className="init-bulk-wrap">
                    <div className="init-exl-wrap">
                      {msgSendLoading ? <Spinner animation="grow" /> : <AiFillRightCircle color='green'/>}
                    </div>
                    <h2>Blasting...</h2>
                    <p>Wait till message blasting is finished.</p>
                  </div>
                </div>
              </> : (
                <div className="init-bulk-message">
                  <div className="init-bulk-wrap">
                    <div className="init-exl-wrap">
                      <BsExclamationTriangle className='bulk-not-found-icon' />
                    </div>
                    <h2>No numbers found!</h2>
                    <p>Please import new number from your local computer.</p>
                    <div className="init-bulk-btn-wrapper">
                      <button onClick={() => setExportModel(!exportModel)} className="btn init-button flex exp-btn"> <MdOutlineImportExport /> Import</button>
                      
                      <a
                        href={fileUrl}
                        download="Sample-number-v2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="btn init-button flex sample-btn"> <SiMicrosoftexcel /> Sample</button>
                      </a>
                    </div>
                  </div>
                </div>
              )
            }
            
      </div>
    </>
  )
}

export default ABMSBUC