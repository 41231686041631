import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { BsX } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';

function YAxis({fileData, tableData, yData, setYData, xData, toggleFunc, filter, setFilter, toggleReset}) {

  const [yBoard, setYBoard] = useState([]);
  const [updateBoard, setUpdateBoard] = useState([])

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "div",
    drop: (item) => getFieldObject(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    const haveData = yBoard;
    const checkData = haveData || []
    if(checkData) {
      setYData(checkData)
    }

    if(yBoard && yBoard?.length !== 0) {
      localStorage.setItem('yBoard', JSON.stringify(yBoard))
      localStorage.setItem('yFilter', JSON.stringify(filter))
    }
    
  }, [tableData, yBoard])

  useEffect(() => { 
    setYBoard(JSON.parse(localStorage.getItem('yBoard')) || yBoard) 
    setFilter(JSON.parse(localStorage.getItem('yFilter')) || filter) 
  }, [])

  const getFieldObject = (obj) => {
    const dataArr = []
    for (let i = 0; i < fileData?.dataArray[obj.sheet]?.length; i++) { // first we map all sheet
      dataArr.push(fileData?.dataArray[obj.sheet][i][obj.label]) 
    }
    filter.label = [...filter.label, {name: obj.label, amount: 0, operation: '>'}]
    setFilter({...filter, sheet: obj.sheet})
    setYBoard((yBoard) => [...yBoard, {...obj, data: dataArr}]);
  };

  const handleRemoveYItem = (index) => {
    localStorage.removeItem('yBoard')
    toggleReset()
    const element = yBoard?.filter((item, i) => i !== index)
    setYBoard(element)
    filter.label?.splice(index, 1)
    setFilter({...filter})
  }
  
  
  return (
    <>
     {/* y axis */}
     <div className="y-axis">
        <label htmlFor="">Y-Axis</label>
        <div className="y-axis-drag-canvas" ref={drop} style={{border: isOver ? "2px dotted green" : "2px dotted lightgray"}}>
          
          {
            yBoard && yBoard?.map((item, index) => (
              <div className="field-item label-item" key={index}>
                <div className="label-content">
                  <span>{`${index+1}. ${item.label}`}</span>
                </div>
                <div className='item-action-btn'>
                  {
                    xData.length !== 0 &&  <div className="label-action">
                    <MdKeyboardArrowDown onClick={() => {
                        toggleFunc()
                      }} />
                  </div>
                  }
                  <div className="label-action" onClick={() => handleRemoveYItem(index)}>
                    <BsX />
                  </div>
                </div>
              </div> 
            ))
          }
        </div>
      </div> 
    </>
  )
}

export default YAxis