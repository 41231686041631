import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import ErrorText from '../components/ErrorText';
import Loading from '../components/Loading';
import { useAppContext } from '../context/AppContext';
import Logo from '../images/wipdata-logo.png';
import { apiUrl } from '../Variables';

function ForgotPasswordScreen() {

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {register, handleSubmit, formState: {errors}} = useForm();
  const {userInfo} = useAppContext();

  useEffect(() => {

    // if email is valid and email send success then show the message
    if(success) {
       Swal.fire({
        title: "Success",
        html: "Your have successfully send an mail. Please check your provided email.",
        icon: "success"
      })
    }

    // if email is not valid and doesn't exist in db then throw an error
    if(error) {
      Swal.fire({
       title: "Oops!",
       html: "Your provided email not valid!",
       icon: "warning"
     })
   }
  }, [success, error])

  // console.log(success) 


  const onSubmit = async (data) => {
    setSuccess(false)
    setError(false)
    try {
      setLoading(true)
      const res = await axios.post(`${apiUrl}/user/forgot-password`, data)
      setSuccess(true)
      setLoading(false)
    } catch (error) {
      setError(true)
      setLoading(false)
      console.log(error.response
        ? error.response.data.message
        : error.message)
    }
  }

  console.log(error)

  return (
    <>
      {loading && <Loading />}
      <div className="register-container">
          <div className="single-logo-wrapper">
            <Link to="/">
              <div className="single-logo">
                <img src={Logo} alt="logo" />
                <div className="logo-text">
                  <h2>WipData</h2>
                </div>
              </div>
            </Link>
          </div>

        {/* wrapper */}
        <div className="register-wrapper">
        
          <form onSubmit={handleSubmit(onSubmit)}>

          {
          success ? (
            <>
              <div className='form-title'>
              <h2>Email has been send successfully</h2>
            </div>
              <p>Please check your email. After clicking link you can reset new password. Note: your email will expire within 15 minutes.</p>
            </>
          ) : (
            <>
              <div className="form-title">
              <h2>Recover your password</h2>
              <p>Enter your valid email address, Which is already register in this site.</p>
            </div>

            {/* email */}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input className={errors.email?.message && "error-input"} {...register('email', {required: "Email is required!"})} type="email" placeholder='Email address' id='email' />
              <ErrorText error={errors.email?.message} />
            </div>

            <button type='submit' className='form-button'>
              Submit
            </button>
            </>
          )
          }
            
          </form>
          
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordScreen