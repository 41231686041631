// export const apiUrl = "https://wipdata-chatbot-api.herokuapp.com/api"
// export const fileUrl = 'https://wipdata-chatbot-api.herokuapp.com/api/upload'
// export const rootUrl = 'https://wipdata-chatbot-api.herokuapp.com'

//azure borhan service apis
// export const apiUrl = "https://wipdata-chatbot-api.azurewebsites.net/api"
// export const fileUrl = 'https://wipdata-chatbot-api.azurewebsites.net/api/upload'
// export const rootUrl = 'https://wipdata-chatbot-api.azurewebsites.net'

export const apiUrl = "https://api2.aceva.com.my/api"
export const fileUrl = 'https://api2.aceva.com.my/api/upload'
export const rootUrl = 'https://api2.aceva.com.my'
// export const apiUrl = "http://localhost:5000/api"
// export const fileUrl = 'http://localhost:5000/api/upload'
// export const rootUrl = 'http://localhost:5000'


//chatbot
// const baseUrl = 'http://localhost:5000'
// const baseUrl = 'https://aibotapis.azurewebsites.net'
// const baseUrl = 'http://35.247.186.176:5000'
const baseUrl = 'https://api.aceva.com.my'

export const chatUrl = `${baseUrl}/api/botInteract/chat` 
export const chatMyUrl = `${baseUrl}/api/botInteract/chat_my` 
export const getChatByUserId = `${baseUrl}/api/botInteract` 
export const symptomsUrl = `${baseUrl}/api/symptoms` 
export const kpisUrl = `${baseUrl}/api/appointment/kpis` 
export const notifications = `${baseUrl}/api/notifications` 
