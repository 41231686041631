import axios from 'axios';
import React, { useEffect, useState } from 'react';
import xero from '../../images/icons/xero.png';
import { apiUrl } from '../../Variables';

function XeroIntegrate() {

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [syncLoading, setSyncLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [data, setData] = useState([])

  const xeroDataFetchHandler = async () => {
    console.log("clicked")
    setLoading(true)
    try {
      setLoading(true)
      setSuccess(false)
      const {data} = await axios.get(`${apiUrl}/scrap`)
      setLoading(false)
      if(data) {
        setLoading(false)
        setSuccess(true)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      setError(error?.message)
    }
  }

  useEffect(() => {
    (async() => {
      setError('')
      try {
        const {data} = await axios.get(`${apiUrl}/scrap/xero`)
        setData(data[0])
      } catch (error) {
        setError(error)
        console.log(error)
      }
    })()
  },[])

  console.log(data)

  return (
    <div className='integrate-item'>
      {data && <div className="integrate-status">Connected</div>}
      <div className='integrate-icon'>
        <img src={xero} alt='xero' />
      </div>
      <div className='integrate-content'>
        <h2 className='text-messenger'>Connect with Xero</h2>
        <p>Do it now and start responding to queries from Xero.</p>
        <button onClick={xeroDataFetchHandler} className='btn integrate-btn'>{loading ? "Syncing..." : "Sync"}</button>
      </div>
    </div>
  );
}

export default XeroIntegrate;
