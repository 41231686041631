import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import { useAppContext } from '../context/AppContext'
import MobileSidebar from './sidebar/MobileSidebar'

function Layout({children, toggleRefresh}) {
const {userInfo} = useAppContext()
  const [mobileSidebar, setMobileSidebar] = useState(true)

  const [width, setWidth] = useState(window.innerWidth)

  const {sidebarToggle, setSidebarToggle} = useAppContext()

  const windowSizeChangeHandler = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', windowSizeChangeHandler)
    return () => {
      window.removeEventListener('resize', windowSizeChangeHandler)
    }

  }, [userInfo])
  
  const isMobile = width <= 768; 

  return (
    <>
      <section className='main-section'>
        <div className="dashboard-wrapper">
          {/* sidebar */}
          <div className="mobile-hide">
            <Sidebar isMobile={isMobile} sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} />
          </div>

          {/* mobile sidebar */}
          <div className="desktop-hide">
            <MobileSidebar isMobile={isMobile} mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} />
          </div>
          
          {/* main body */}
          <main className={sidebarToggle ? "active" : ""}>
            <div className="body-container">
              <Header toggleRefresh={toggleRefresh} isMobile={isMobile} mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle}/>
              <div className="body-wrapper">

                {children}

              </div>
            </div>
          </main>
        </div>
      </section>
    </>
  )
}

export default Layout