import React, { useEffect, useRef } from 'react'
import { BsCameraFill } from 'react-icons/bs'
import { MdOutlineKeyboardVoice } from 'react-icons/md'
import { Link } from 'react-router-dom'
import logo from '../../images/aceva.png'

function FactoryBot() {

  const chatRef = useRef(null)

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: "smooth" })
  },[])

  return (
    <>
      <ul>
        {/* plain text bubble */}
        <li>
            <img className='agent-logo' src={logo} alt="bot" />
          <div className="chat-bubble-wrap">
            <p className='message-bubble-agent'>Hi, How can I help you? </p>

            <div className="message-auto-suggest-buttons">
              {/* item */}
              <button className='suggest-btn'>
                <span>Error Notification</span>
              </button>
              {/* item */}
              <button className='suggest-btn'>
                <span>Maintanance Support</span>
              </button>
              {/* item */}
              <button className='suggest-btn'>
                <span>Knowledge Center </span>
              </button>
              {/* item */}
              <button className='suggest-btn'>
                <span>Update</span>
              </button>
              {/* item */}
              <button className='suggest-btn'>
                <span>HR</span>
              </button>
            </div>
          </div>
          
        </li>
        {/* <div className="messageTimestamp fade-enter-done">Yesterday, 20:00</div> */}

        {/* buttons  */}
        <li className='message-bubble-agent clear-style-message'>
          
        </li>


        {/* message by visitor */}
        <li className="message-bubble-visitor ">
          <span className=''>Maintanance Support</span>
        </li>

        {/* plain text bubble */}
        <li>
          <img className='agent-logo' src={logo} alt="bot" />
          <div className="chat-bubble-wrap">        
            <p className='message-bubble-agent'>Hi Shah, How can I help you?</p>
            <div className="message-auto-suggest-buttons">
              {/* item */}
              <button className='suggest-btn'>
                <span>Progress Report</span>
              </button>
              {/* item */}
              <button className='suggest-btn'>
                <span>Report an Issue</span>
              </button>
              {/* item */}
              <button className='suggest-btn'>
                <span>Inventory</span>
              </button>
            </div>
          </div>
        </li>

        {/* message by visitor */}
        <li className="message-bubble-visitor">
          {/* <img src={book} alt="book" /> */}
          <span>Report an Issue</span>
        </li>

        {/* plain text bubble */}
        <li>
          <img className='agent-logo' src={logo} alt="bot" />
            <div className="appointment-form">
              <form>
                <div className="appo-title">
                  <h4>Report an Issue</h4>
                  <p>Please ensure all necessary information is provided and we will forward it to the maintenance team promptly.</p>
                </div>
                <div className="form-group appo-group">
                  <label>Start date</label>
                  <input type="date" />
                </div>
                <div className="form-group appo-group">
                  <label>Issue</label>
                  <input type="text" placeholder='Issue' />
                </div>
                <div className="form-row">
                  <div className="form-group appo-group">
                    <label>Remark</label>
                    <input type="number" placeholder='Remark' />
                  </div>
                </div>
                  <div className="form-group appo-group space-between">
                    <div className="factory-btns">
                      <BsCameraFill />
                      <MdOutlineKeyboardVoice />
                    </div>
                    <button className='btn appointment-btn'>Add</button>
                  </div>
              </form>
            </div>
        </li>

        {/* message by visitor */}
        <li className="message-bubble-visitor">
          <span>Leave Application</span>
        </li>

        <li>
          <img className='agent-logo' src={logo} alt="bot" />
            <div className="appointment-form">
              <form>
                <div className="appo-title">
                  <h4>Leave Application</h4>
                  <p>Hi Shah, Please remember to submit your leave request at least 3 days in advance.</p>
                </div>
                <div className="form-group appo-group">
                  <label>Start date</label>
                  <input type="date" />
                </div>
                <div className="form-group appo-group">
                  <label>End date</label>
                  <input type="date" placeholder='Issue' />
                </div>
                <div className="form-group appo-group">
                  <label>Reason</label>
                  <input type="text" placeholder='Write reason' />
                </div> 
                <div className="form-group appo-group">
                  <button className='btn appointment-btn'>Add</button>
                </div>
              </form>
            </div>
        </li>

        {/* message by visitor */}
        <li className="message-bubble-visitor">
          <span>Error Notifiction</span>
        </li>

        {/* response */}
        <li>
          <img className='agent-logo' src={logo} alt="bot" />
            <div className="chat-bubble-wrap">
                <div className="appo-title">
                  <span className='message-bubble-agent'>Error notification</span>
                </div>
                <div className="factory-error-box">
                  <li>Production Error: 
                    <span className='error-notifi'>Line #06</span>
                  </li>
                  <li>Department:
                  <span className='error-notifi'>Production</span>
                  </li>
                  <li>Manager:
                  <span className='error-notifi'>Mr Shabhi Kurama</span>
                  </li>
                  <li>Supervisor:
                  <span className='error-notifi'>Mr Shofiq Abdullah</span>
                  </li>
                  <li>Remark:
                  <span className='error-notifi'>Leakage</span>
                  </li>
                </div>
            </div>
        </li>

        {/* message by visitor */}
        <li className="message-bubble-visitor">
          <span>Update</span>
        </li>

        {/* response */}
        <li>
          <img className='agent-logo' src={logo} alt="bot" />
            <div className="message-bubble-agent">
              <h4 className='message-bubble-agent'>Update:</h4>
              <span>Logistic Arrival <span className='error-notifi'>#ID 123BCGE</span> will arrive at <span className='error-notifi'>3PM</span> . Click the <Link to="#">link</Link> to proceed with the delivery.</span>
            </div>
            {/* <div className="shadow-box message-bubble-agent">
            </div> */}
        </li>

        {/* babble typing... */}
        <li>
          <img className='agent-logo' src={logo} alt="bot" />
          <div className="typing-wrap message-bubble-agent">
            <span className="tying">Typing</span>
            <span className="animate-typing">
              <span className="dot ms-1"></span>
              <span className="dot ms-1"></span>
              <span className="dot ms-1"></span>
            </span>
          </div>
        </li>

        <li ref={chatRef}></li>
      </ul>
    </>
  )
}

export default FactoryBot