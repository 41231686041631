import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
// import {MdRestartAlt} from 'react-icons/gr'
import { MdImportExport, MdRestartAlt } from 'react-icons/md'


import { Link, useNavigate } from 'react-router-dom'
import CreateUserModel from '../components/CreateUserModel'
import ExportModel from '../components/ExportModel'
import MiniLoading from '../components/MiniLoading'
import UpdateUserModel from '../components/UpdateUserModel'
import { useAppContext } from '../context/AppContext'


function AllUserScreen() {

  const [createUser, setCreateUser] = useState(false);
  const [editUser, setEditUser] = useState(false)
  const [exportModel, setExportModel] = useState(false)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [result ,setResult] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [updateUsers, setUpdateUsers] = useState([])
  const [editUserInfo, setEditUserInfo] = useState({})
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [filterRoleValue, setFilterRoleValue] = useState('all')

  const [filterReset, setFilterReset] = useState({
    name: "",
    role: "",
    department: "",
  });

  const {userInfo, apiUrl} = useAppContext(); 
  const navigate = useNavigate();

  useEffect(() => {
    if(deleteSuccess) {
      navigate('/users')
    }
    const featchData = async () => {
      const {data} = await axios.get(`${apiUrl}/user`)
      setUsers(data)
      setUpdateUsers(data)
      setLoading(false)
    }
    featchData()
    
  },[result, deleteSuccess, updateSuccess, filterRoleValue, apiUrl])


  const exportHandler = () => setExportModel(!exportModel)
  const createUserHandler = () => setCreateUser(!createUser)

  // edit handler
  const editHandler = (item) => {
    setEditUser(!editUser)
    setEditUserInfo(item)
  }

  // user delete handler
  const deleteHandler = async (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    if(window.confirm("Do you want to delete")) {
      setDeleteSuccess(false)
      await axios.delete(`${apiUrl}/user/${id}`, config)
      setDeleteSuccess(true)
    }
  }

  const handleFilterName = (name) => {
    const filteredData = users?.filter((item) => {
      const fullName = `${item.name} ${item.email} ${item.phone}`;
      if (fullName.toLowerCase().includes(name.toLowerCase())) {
        return item;
      } else {
        return "";
      }
    });

    setUpdateUsers(filteredData);
    setFilterReset(name)
  };

  // Role filter
  const handleFilterRole = (roleType) => {
    const filteredData = users?.filter((filterdItem) => {
      return filterdItem.role === roleType
    })
    setUpdateUsers(filteredData);
    setFilterReset(roleType)
  }

  // Department filter
  const handleFilterDepartment = (departmentType) => {
    const filteredData = users?.filter((filterdItem) => {
      return filterdItem.department === departmentType
    })
    setUpdateUsers(filteredData);
    setFilterReset(departmentType)
  }

  return (
    <>
        {/* {loading && <Loading />} */}
        <CreateUserModel createUser={createUser} setResult={setResult} setCreateUser={setCreateUser} />
        <UpdateUserModel updateSuccess={updateSuccess} setUpdateSuccess={setUpdateSuccess} editUserInfo={editUserInfo} setEditUserInfo={setEditUserInfo} editUser={editUser} setEditUser={setEditUser} />
        <ExportModel users={users} exportModel={exportModel} setExportModel={setExportModel} />
      <div className="table-container">

        {loading && <MiniLoading /> }

        {/* table header */}
        <div className="table-header">
          <div className="table-title">
            <h3>All user list</h3>
          </div>

          {
            (() => {
              if(userInfo?.role === 'admin') {
                return (
                  <div onClick={createUserHandler} className="table-add-new">
                    <button>Create user</button>
                  </div>
                )
              } else if(userInfo?.role === 'editor') {
                return (
                  <div className="table-add-new btn-disabled">
                    <button>Create user</button>
                  </div>
                )
              } else {
                return (
                  <div className="table-add-new btn-disabled">
                    <button>Create user</button>
                  </div>
                )
              }
            })()
          }

        </div>

        {/* table filter */}
        <div className="table-filter-container">

        {/* filter */}
        <div className="table-filter">
          <div className="form-group">
            <input value={filterReset?.name} type="text" onChange={(e) => handleFilterName(e.target.value)} placeholder='Search...' />
          </div>

          {/* Role */}
          {/* <RoleFilter onFilterValueSelected={onFilterValueSelected} /> */}
          <div className="form-group">
            <select value={filterReset?.role} onChange={(e) => handleFilterRole(e.target.value)} id="isAdmin">
              <option value="select-user" disabled selected>Roles</option>
              <option value="admin">admin</option>
              <option value="editor">editor</option>
              <option value="user">user</option>
            </select>
          </div>

          {/* Department */}
          <div className="form-group">
            <select value={filterReset?.department} onChange={(e) => handleFilterDepartment(e.target.value)} id="department">
                <option value="sales">Sales</option>
                <option value="human-resource">Human Resource</option>
                <option value="account">Account</option>
                <option value="finance">Finance</option>
                <option value="audit">Audit</option>
            </select>
          </div>

          {/* reset */}
          <div className="form-group" onClick={(e) => 
                {
                  e.preventDefault()
                  setFilterReset({
                      name: "",
                      role: "",
                      department: "",
                  })
                  setUpdateUsers(users)
                }
              }>
            <MdRestartAlt className='reset-button'/>
          </div>
        </div>

        {/* export button */}
        {
            (() => {
              if(userInfo?.role === 'admin') {
                return (
                  <div onClick={exportHandler} className="table-export">
                    <span className='export-button'> <MdImportExport /> Export </span>
                  </div>
                )
              } else if(userInfo?.role === 'editor') {
                return (
                  <div className="table-export export-disabled">
                    <span className='export-button'> <MdImportExport /> Export </span>
                  </div>
                )
              } else {
                return (
                  <div className="table-export export-disabled">
                    <span className='export-button'> <MdImportExport /> Export </span>
                  </div>
                )
              }
            })()
          }
        

        </div>

        {/* table wrapper */}
        <div className="table-wrapper">
          <table>
            {/* header */}
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Role</th>
              <th>Department</th>
              <th>Created Date</th>
              <th>Active User</th>
              <th>Action</th>
              </tr>
              <tbody>
                {/* item */}
                {
                  updateUsers?.map((item, index) => {
                    return (
                      <>
                        {
                          item?.email === userInfo?.email ? (
                            <tr key={index} className="active-user-row">
                              <td><span className="table-name">{item.name}</span></td>

                              <td>
                                {item.email && <Link to="#" onClick={() => window.location = `mailto:${item.email}`
                              }>{item.email}</Link>}
                              </td>

                              <td>{item?.phone ? item?.phone : <span className='no-number-badge'>No number!</span>}
                              </td>

                              <td>
                                {(() => {
                                  if(item.role === "admin") {
                                    return <span className="badge admin-badge">{item.role}</span>
                                  } else if(item.role === "editor") {
                                      return <span className="badge editor-badge">{item.role}</span>
                                  } else {
                                    return <span className="badge user-badge">{item.role}</span>
                                  }
                                })()}
                              </td>
                              <td><span className="badge department-badge">{item.department}</span></td>
                              <td>{moment(item.createdAt).format('lll')}</td>
                              <td>{moment(item.createdAt).fromNow()}</td>
                              <td>
                                <div className="action-wrapper">

                                  {/* edit */}
                                  {
                                    (() => {
                                      if(userInfo?.role === 'admin') {
                                      
                                        return (
                                          <Link onClick={() => editHandler(item)} to={`/users/?userId=${item._id}`}>
                                            <FiEdit className='edit-icon' />
                                          </Link>
                                        )
                                      } else if(userInfo?.role === 'editor') {
                                        return (
                                            <Link to="#">
                                              <FiEdit className='edit-icon edit-disabled' />
                                            </Link>
                                        )
                                      } else {
                                        return (
                                          <Link to="#">
                                              <FiEdit className='edit-icon edit-disabled' />
                                            </Link>
                                        )
                                      }
                                    })()
                                  }

                                  {/* delete */}
                                  {
                                    (() => {
                                      if(userInfo?.role === 'admin') {
                                        return (
                                          <div onClick={() => deleteHandler(item._id)}>
                                            <AiOutlineDelete className='delete-icon' />
                                          </div>
                                        )
                                      } else if(userInfo?.role === 'editor') {
                                        return (
                                          <div>
                                            <AiOutlineDelete className='delete-icon delete-disabled' />
                                          </div>
                                        )
                                      } else {
                                        return (
                                          <div>
                                            <AiOutlineDelete className='delete-icon delete-disabled' />
                                          </div>
                                        )
                                      }
                                    })()
                                  }

                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr key={index}>
                              <td><span className="table-name">{item.name}</span></td>

                              <td>
                                {item.email && <Link to="#" onClick={() => window.location = `mailto:${item.email}`
                              }>{item.email}</Link>}
                              </td>

                              <td>{item?.phone ? item?.phone : <span className='no-number-badge'>No number!</span>}
                              </td>

                              <td>
                                {(() => {
                                  if(item.role === "admin") {
                                    return <span className="badge admin-badge">{item.role}</span>
                                  } else if(item.role === "editor") {
                                      return <span className="badge editor-badge">{item.role}</span>
                                  } else {
                                    return <span className="badge user-badge">{item.role}</span>
                                  }
                                })()}
                              </td>
                              <td><span className="badge department-badge">{item.department}</span></td>
                              <td>{moment(item.createdAt).format('lll')}</td>
                              <td>{moment(item.createdAt).fromNow()}</td>
                              <td>
                                <div className="action-wrapper">

                                  {/* edit */}
                                  {
                                    (() => {
                                      if(userInfo?.role === 'admin') {
                                      
                                        return (
                                          <Link onClick={() => editHandler(item)} to={`/users/?userId=${item._id}`}>
                                            <FiEdit className='edit-icon' />
                                          </Link>
                                        )
                                      } else if(userInfo?.role === 'editor') {
                                        return (
                                            <Link to="#">
                                              <FiEdit className='edit-icon edit-disabled' />
                                            </Link>
                                        )
                                      } else {
                                        return (
                                          <Link to="#">
                                              <FiEdit className='edit-icon edit-disabled' />
                                            </Link>
                                        )
                                      }
                                    })()
                                  }

                                  {/* delete */}
                                  {
                                    (() => {
                                      if(userInfo?.role === 'admin') {
                                        return (
                                          <div onClick={() => deleteHandler(item._id)}>
                                            <AiOutlineDelete className='delete-icon' />
                                          </div>
                                        )
                                      } else if(userInfo?.role === 'editor') {
                                        return (
                                          <div>
                                            <AiOutlineDelete className='delete-icon delete-disabled' />
                                          </div>
                                        )
                                      } else {
                                        return (
                                          <div>
                                            <AiOutlineDelete className='delete-icon delete-disabled' />
                                          </div>
                                        )
                                      }
                                    })()
                                  }

                                </div>
                              </td>
                            </tr>
                          )
                        }
                      </>
                    )
                  })
                }
              </tbody>
              
          </table>
          {
            users?.length === 0 && <div className='table-data-not-found'>User not found</div>
          }

        </div>
          {/* pagination */}
          {/* <div className="pagination-wrapper">
            <div className="pagination">
              <a href="#">&laquo;</a>
              <a className="active" href="#">1</a>
              <a href="#">2</a>
              <a href="#">3</a>
              <a href="#">4</a>
              <a href="#">5</a>
              <a href="#">6</a>
              <a href="#">&raquo;</a>
            </div>
          </div> */}
      </div>
    </>
  )
}

export default AllUserScreen