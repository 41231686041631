import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaPlug } from 'react-icons/fa';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import MiniLoading from '../components/MiniLoading';
import { apiUrl } from '../Variables';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Button from '../components/Button';
import Payable from '../components/data/Payable';
import Receivable from '../components/data/Receivable';
// import 'react-tabs/style/react-tabs.css';

function AgedReceiveableScreen() {

  const [xeroData, setXeroData] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [syncLoading, setSyncLoading] = useState(false)
  const [filter3Month, setFilter3Month] = useState([])
  const [groupedMonth, setGroupMonth] = useState([])
  const [alartToggle, setAlartToggle] = useState(false)

  const [success, setSuccess] = useState(false)

  const syncHandler = async () => {
    console.log("clicked")
    setLoading(true)
    // try {
    //   setLoading(true)
    //   setSuccess(false)
    //   const {data} = await axios.get(`${apiUrl}/scrap`)
    //   setLoading(false)
    //   if(data) {
    //     setLoading(false)
    //     setSuccess(true)
    //   }
    // } catch (error) {
    //   setLoading(false)
    //   console.log(error)
    //   setError(error?.message)
    // }
  }

  useEffect(() => {
    // if(alartToggle === true) {
    //   Swal.fire({
    //     title: 'Before process you have to authrize by your phone!',
    //     text: "wait 10-15 seconds!",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, Process it!'
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       Swal.fire(
    //         'Processing...',
    //         'After few seconds bot will automatically grab data from xero.',
    //         'success'
    //       )
    //       syncHandler();
    //     }
    //   })
    // }
    if(error) {
      setLoading(false)
    }
    if(success) {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      })
    }
  },[success, error, alartToggle])

  console.log(alartToggle)

  return (
   <>
   <div className="table-container">
      {/* table header */}
        <Tabs>
          <div className="table-header">
              <TabList className="api-header-wrapper">
                <Tab className="api-item">Receiveable</Tab>
                <Tab className="api-item">Payable</Tab>
              </TabList>
            
            <div onClick={syncHandler} > 
              <Button className='table-header-button' type="submit"> 
                {/* { loading ? <MiniLoading /> : <span><FaPlug /> Connect</span>}  */}
                {<span><FaPlug /> Connect</span>} 
              </Button>
              {/* {error && <h3>Error</h3>} */}
              {/* disabled class: loading-btn */}
            </div>
          </div>

          <div className="tab-content-wrapper">
              <TabPanel>
                <Receivable />
              </TabPanel>

              <TabPanel>
                <Payable />
              </TabPanel>
          </div>
        </Tabs>
     </div>
   </>
  )
}

export default AgedReceiveableScreen