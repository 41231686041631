import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsExclamationTriangle, BsX } from 'react-icons/bs';
import { MdCloudUpload, MdOutlineImportExport } from 'react-icons/md';
import { SiMicrosoftexcel } from 'react-icons/si';
import { VscDiffAdded } from 'react-icons/vsc';
import Swal from 'sweetalert2';
import { apiUrl } from '../Variables';
import BulkMessageImportModel from '../components/BulkMessageImportModel';
import MiniLoading from '../components/MiniLoading';
import AddNumberModel from '../components/model/AddNumberModel';
import fileUrl from '../number-sheet.xlsx';

const logo = '../images/bg-logo.png'
const tempLogo = "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png"


function BulkMessageScreen() {
  const [exportModel, setExportModel] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)

  const [numbers, setNumbers] = useState([]);
  const [newNumber, setNewNumber] = useState('')
  const [updateNumbers, setUpdateNumbers] = useState([]);
  const [importedNumbers, setImportedNumbers] = useState([]);

  const [msgSendSuccess, setMsgSendSuccess] = useState(false);
  const [msgSendLoading, setMsgSendLoading] = useState(false); 

  const [disabled, setDisabled] = useState(false);
  const [addNumber, setAddNumber] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");


  const [message, setMessage] = useState({
    numbers: [],
    header: "",
    body: "",
    footer: "",
    media: ""
  })

  // useEffect(() => {
  //   setNumbers(importedNumbers);
  // }, []);

  const handleChange = (e) => {
    
    const { name, checked } = e.target;
    if (name === "allSelect") {
      if (checked === true) {
        let tempUser = importedNumbers?.map((user) => {
          return { ...user };
        })
        setUpdateNumbers(tempUser)
      } else {
        setUpdateNumbers([])
      } 
    } else {
      // let tempUpdateUser = importedNumbers?.map((user) => (
      //   user.number === name && { ...user } ? setUpdateNumbers([...updateNumbers, {number: name}]) : []
      // ))
      if (checked === true) {
          let tempUser = importedNumbers?.find((user) => {
          // user.number === name ? { ...user } : user
          if (user.number === parseInt(name)) {
            return { ...user }
          }
        });
        // setImportedNumbers(tempUser);
        setUpdateNumbers([...updateNumbers, tempUser])
      } else {
        setUpdateNumbers(updateNumbers.filter(i => i.number !== parseInt(name)))
      } 
      
    }
  };

  useEffect(() => {
    if(updateNumbers.length > 0) {
      setMessage({...message, numbers: updateNumbers})
    }
  },[updateNumbers])

  const exportModelhandle = (e) => {
    e.preventDefault();
  }

  // remove selceted numbers
  const removeSelectedNumber = index => setUpdateNumbers(updateNumbers?.filter((_, i) => i !== index))

  // file upload handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append("upload_preset", "pewhglsn")
    formData.append("cloud_name", "dzo2vmjdy")

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      // const { data } = await axios.post(`${fileUrl}`, formData, config)
      setUploadLoading(true)
      const {data} = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, config)
      message.media = data?.secure_url
      setMessage({...message})
      setUploadLoading(false)
    } catch (error) {
      console.error(error)
      setUploadLoading(false)
    }
  }

  console.log("message", message)

  const removeMedia = () => {
    setMessage({...message, media: ""})
  }

  useEffect(() => {
    if(importedNumbers.length > 0) {
      setExportModel(false)
    }
  },[importedNumbers])

  // after successfull send message show the alart
  useEffect(() => {
    if(msgSendSuccess) {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500
      })
    }
  },[msgSendSuccess])

  const sendBulkmessage = async (e) => {
    e.preventDefault()
    const {numbers, header, body, footer, media} = message
    setMsgSendLoading(true)
    let responses = [];
    let promises = [];
    for (const number of numbers) {
      promises.push(
        axios.post(`${apiUrl}/telegram/whatsapp/whatsAppSendMsg`, {number: number.number, header, body: `${body}. ${media}`, footer, media}).then(response => {
          // do something with response
          responses.push(response);
          console.log(response)
        })
      )
    }
    Promise.all(promises).then(() => {
      setMsgSendLoading(false)
      setMessage({
        numbers: [],
        header: "",
        body: "",
        footer: "",
        media: ""
      })
      setUpdateNumbers([])
      setMsgSendSuccess(!msgSendSuccess)
    });
  }

  // add number handler
  const addNumberHandler = (e) => {
    e.preventDefault();
    if (newNumber.trim() !== '') {
      setImportedNumbers((prevNumbers) => [...prevNumbers, {number: parseInt(newNumber, 10), __rowNum__: undefined}]);
      setNewNumber('');
    }
  };
  
  const filteredItems = importedNumbers.filter((item) => {
    const itemNumber = Array.isArray(item.number) ? item.number : [item.number];
    return itemNumber.some((number) =>
      number.toString().includes(searchQuery.toLowerCase())
    );
  });

  return (
    <>
      {/* add number model */}
      <AddNumberModel 
        addNumber={addNumber}
        setNewNumber={setNewNumber}
        setAddNumber={setAddNumber}
        addNumberHandler={addNumberHandler}
      />

      {/* popup model */}
      <div className={exportModel ? "create-user-wrapper active" : "create-user-wrapper"}>
        <form onSubmit={exportModelhandle} className="create-user-form bulk-msg-create-user-form">

          <div className="form-title bulk-import-title">
          <SiMicrosoftexcel className='excel-text' />
            <span>Upload Excel File</span>
            {/* <p>Insert file name.</p> */}
            <BsX onClick={() => setExportModel(!exportModel)} className='time-icon' />
          </div>

          {/* export items */}
          <BulkMessageImportModel setImportedNumbers={setImportedNumbers} />
          
        </form>
      </div>

      <div className="bulk-message-container">

            {/* bulk message header */}
            <div className="bulk-message-header">
              <h3>Whatsapp Bulk Messages</h3>
              <div className="flex">
                <button onClick={() => setExportModel(!exportModel)} className="btn c-btn">Import numbers</button>
                {/* { importedNumbers?.length > 0 && <button onClick={() => setImportedNumbers([])} className="btn c-btn c-btn-remove">Remove numbers</button> } */}
              </div>
            </div>

            
            {
              importedNumbers?.length > 0 ? (
                <>{/* bulk message */}
                <div className="bulk-message-wrapper">
                  <div className="bulk-table-wrap">
                      <div className="list-of-bulk-title">
                        {/* <p>Select & send message</p> */}
                        <div className="bulk-search-input-wrap">
                          <input className='search-number-input' type="number" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder='Search number...' />

                          {searchQuery && (
                            <BsX
                              onClick={() => setSearchQuery("")}
                              disabled={!searchQuery}
                              className="bulk-clear-icon"
                            />
                          )}
                        </div>

                        <VscDiffAdded onClick={() => setAddNumber(!addNumber)} />
                      </div>
                      <div className="table-wrap-bulk">
                        <table className='bulk-table'>
                          {/* header */}
                          <tr>
                            <th> 
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="allSelect"
                                checked={updateNumbers.length === importedNumbers.length && updateNumbers.every((o, idx) => Object.keys(o).every(p => o[p] === importedNumbers[idx][p]))}
                                onChange={handleChange}
                              />
                            </th>
                            <th>Select All</th>
                          </tr>

                          <tbody>
                            {/* item */}
                            {filteredItems?.map((user, index) => (
                            <tr>
                              <td>
                                <input
                                  key={index}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={updateNumbers.find(i => i.number === user.number)}
                                  name={user.number}
                                  onChange={handleChange}
                                  value={user.number}
                                />
                              </td>
                              <td>{user.number}</td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                  </div>
                  {/* right side fields */}
                  <div className="bulk-field-wrap">

                  {/* selected numbers */}
                  {
                    updateNumbers?.length === 0 ? (
                      <div className="not-yet-selected">
                        <p>Not selected any numbers</p>
                      </div>
                    ) : (
                    <div className="bulk-selected-field">
                      <label htmlFor="">Selected numbers</label>
                      <div className="bulk-selected-box">
                        {
                          updateNumbers?.map((item, index) => (
                            <li key={index}>{item?.number} <BsX onClick={() => removeSelectedNumber(index)} /></li>
                          ))
                        }
                      </div>
                    </div>
                    )
                  }
                  <form onSubmit={sendBulkmessage}>

                    {/* header */}
                    <div className="form-group bulk-group">
                      <label htmlFor="">Header</label>
                      <input 
                        onChange={(e) => {
                          message.header = e.target.value
                          setMessage({...message})              
                        }} 
                        type="text" placeholder='Write header...' 
                        value={message.header}
                      />
                    </div>
                    
                    {/* header */}
                    <div className="form-group bulk-group">
                      <label htmlFor="">Body</label>
                      <textarea
                        onChange={(e) => {
                          message.body = e.target.value
                          setMessage({...message})              
                        }} 
                      placeholder='Write full details...' value={message.body}/>
                    </div>
                    
                    {/* footer */}
                    <div className="form-group bulk-group">
                      <label htmlFor="">Footer</label>
                      <input 
                      onChange={(e) => {
                        message.footer = e.target.value
                        setMessage({...message})              
                      }} 
                      type="text" placeholder='Write footer...' value={message.footer} />
                    </div>

                    {/* media */}
                    <div className="form-group">
                      {
                        uploadLoading && (
                          <div className="bulk-media-preview loading-wrap">
                            <MiniLoading />
                          </div>
                        )
                      }
                      
                      {
                        message?.media ? (
                          <div className="bulk-media-preview">
                            <img src={message?.media ? message?.media : tempLogo} alt="profile photo" />
                            <div onClick={removeMedia} className="media-icon">
                              <BsX className='media-remove-icon' />
                            </div>
                          </div>
                        ) : (
                        <label>
                          <div className="user-profile-header-thumb create-group-logo bulk-media-thumb">
                            {/* <img src={message?.media ? message?.media : tempLogo} alt="profile photo" /> */}
                            <div className="user-update-profile create-user-profile-update-icon-wrapper bulk-update-profile">
                              <input type="file" id='image-file' name="profilePic" custom="true" onChange={uploadFileHandler} hidden />
                              <div className="user-update-profile bulk-profile">
                                <MdCloudUpload className='user-update-icon' />
                                <span>Upload media</span>
                              </div>
                            </div>
                          </div>
                        </label>
                        )
                      }
                    </div>

                    <div className="form-group right-btn">
                      <button disabled={message.body === ""} type='submit' className="btn c-btn"> {msgSendLoading ? <MiniLoading /> : "Send Message"} </button>
                    </div>
                  </form>
                  </div>
                </div>
                </>
              ) : (
                <div className="init-bulk-message">
                  <div className="init-bulk-wrap">
                    <div className="init-exl-wrap">
                      <BsExclamationTriangle className='bulk-not-found-icon' />
                    </div>
                    <h2>No numbers found!</h2>
                    <p>Please import new number from your local computer.</p>
                    <div className="init-bulk-btn-wrapper">
                      <button onClick={() => setExportModel(!exportModel)} className="btn init-button flex exp-btn"> <MdOutlineImportExport /> Import</button>
                      
                      <a
                        href={fileUrl}
                        download="Sample-number"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="btn init-button flex sample-btn"> <SiMicrosoftexcel /> Sample</button>
                      </a>
                    </div>
                  </div>
                </div>
              )
            }
            
      </div>
    </>
  )
}

export default BulkMessageScreen