import axios from 'axios'
import EmojiPicker from 'emoji-picker-react'
import React, { useEffect, useState } from 'react'
import { chatMyUrl, chatUrl, getChatByUserId } from '../Variables'
// import ReactAudioPlayer from 'react-audio-player'
import { AiOutlineGlobal } from 'react-icons/ai'
import { BsFillEmojiSmileFill } from 'react-icons/bs'
import { FaTelegramPlane, FaTimes } from 'react-icons/fa'
import { IoMdRefreshCircle } from 'react-icons/io'
import textLogo from '../images/oren.png'

// product images
import messageIcon from '../images/icons/message.svg'
import x from '../images/icons/times.svg'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import en from '../images/en.png'
import my from '../images/my.jpg'
import FactoryBot from './chatBotTypes/FactoryBot'
import HealthCareBot from './chatBotTypes/HealthCareBot'
const MySwal = withReactContent(Swal)


function Chatbot({toggle, setToggle, QueryParams, setQueryParams}) {
  const [ip, setIp] = useState(localStorage.getItem('ip'))
  const [error, setError] = useState('')
  const [resetData, setResetData] = useState(false);
  const [language, setLanguage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [chat, setChat] = useState([/*{bot: [{text: language === 'en' ? 'Good day! How can I help you?' : language === 'my' ? 'selamat hari! Bagaimana saya boleh tolong awak?' : ''}], user: null, time: "today's date'"}*/])
  const [message, setMessage] = useState('')
  let audio = new Audio("/message.mp3")
  const [resize, setResize] = useState(false);
  const [formToggle, setFormToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [width, setWidth] = useState(window.innerWidth); 
  const [emojiModel, setEmojiModel] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [symptoms, setSymptoms] = useState([])

  const mobileViewport = () => {
    setWidth(window.innerWidth)
  }

  const onEmojiClick = (event, emojiObject) => {
    setMessage(`${message}${event.emoji}`);
  };

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
      localStorage.setItem('ip', res.data.IPv4)
      setIp(res.data.IPv4)
    }
    getData()
    window.addEventListener('resize', mobileViewport)
    return () => window.removeEventListener('resize', mobileViewport)
  }, [])

  const isMobile = width <= 768

  const handleToggle = (e) => {
    setToggle(!toggle);
    setQueryParams('')

    // play notification sound
    if(!toggle) {
      startSound();
    }
  }

  // resize handler
  const handleResize = () => {
    setResize(!resize)
  }

  // play massege notification
  const startSound = () => {
    audio.play()
  }

  const handleSubmit = (e) => e.preventDefault(); 
  //fetch chats
  useEffect(() => {
    const fetchChats = async() => {
        await axios.get(`${getChatByUserId}/${ip}`).then(res => {
          console.log(res)
          setChat(res.data.chats)
        }).catch((error) => {
          console.log("error", error)
        })
        // await axios.get(symptomsUrl).then(res => {
        //   console.log(res)
        //   setSymptoms(res.data.map(d => { return {value: d, label: d}}))
        // }).catch((error) => {
        //   console.log("error", error)
        // })
    }
    fetchChats()
  }, [/*resetData*/])
  // submit or send message
  const handleSendMessage = async (e, msg) => {
    e?.preventDefault()
    if (!message && !msg) {
      console.log('retrun from onsubmit')
      return
    }
    setLoading(true)
    const data = { sender: ip, message: message || msg };
    console.log(data)
    console.log('lan', language)
    const chatArr = [...chat, {user: message || msg, time: "today's date'"}]
    setChat(chatArr)
    // const config = {
    //   headers: {
    //     'Content-Type' : "application/json"
    //   }
    // }
    if (language === 'my') {
      await axios.post(chatMyUrl, data).then( res => {
        console.log(res)
         //for removing greeting msg on initial
         if (msg === '/restart') {
           setChat([chat[0]])
         } else {
           setChat([chat[0], ...res.data.chats.filter((i, index) => !(i.bot.length === 0 && index === 0))])
         }
         setMessage('')
         setLoading(false)
        }).catch( error => {
          setLoading(false)
          console.log(error)
          setError(true)
          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
          })
        })
    } else if (language === "en") {
      await axios.post(chatUrl, data).then( res => {
        console.log(res)
         //for removing greeting msg on initial
         if (msg === '/restart') {
          setChat([chat[0]])
         } else {
            setChat([chat[0], ...res.data.chats.filter((i, index) => !(i.bot.length === 0 && index === 0))])
         }
         setMessage('')
         setLoading(false)
        }).catch( error => {
          setLoading(false)
          console.log(error)
          setError(true)
          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.message,
          })
        })
    }
  }
  console.log("chat arr", chat)
const determinePeriod = () => {
  if (new Date().getHours() >= 0 && new Date().getHours() < 5) {
    return 'day'
  }
  if (new Date().getHours() >= 5 && new Date().getHours() < 12) {
    return 'morning'
  }
  if (new Date().getHours() >= 12 && new Date().getHours() < 17) {
    return 'afternoon'
  }
  if (new Date().getHours() >= 17 && new Date().getHours() <= 21) {
    return 'evening'
  }
}
const refreshFunc = async () => {
    setLoading(true)
    setResetData(!resetData)
    await axios.delete(`${getChatByUserId}/${ip}`).then(async (res) => {
      setChat([])
      await handleSendMessage(null, `/restart`)
    }).catch((err) => {
      console.log(err)
      setLoading(false)
    })
}
const langChangeFunc = async (ln) => {
    setLoading(true)
    if (chat.length > 1) {
      await axios.delete(`${getChatByUserId}/${ip}`).then(async (res) => {
        if (ln === 'en') {
          setChat([{bot: [{text: `Good ${determinePeriod()}! How can I help you?`}], user: null, time: "today's date'"}])
          setLanguage('en')
          // await handleSendMessage(null, `/restart`)
        } else if (ln === 'my') {
          setChat([{bot: [{text: `${determinePeriod() === 'morning' ? 'Selamat Pagi' : determinePeriod() === 'afternoon' ? 'Selamat Tengahhari' : determinePeriod() === 'evening' ? 'Selamat Petang' : 'Selamat hari'}! Bagaimana saya boleh tolong awak?`}], user: null, time: "today's date'"}])
          setLanguage('my')
          // await handleSendMessage(null, `/restart`)
        }
        setResetData(!resetData)
        setLoading(false)
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })
    } else {
      if (ln === 'en') {
        setChat([{bot: [{text: `Good ${determinePeriod()}! How can I help you?`}], user: null, time: "today's date'"}])
        setLanguage('en')
        // await handleSendMessage(null, `/restart`)
      } else if (ln === 'my') {
        setChat([{bot: [{text: `${determinePeriod() === 'morning' ? 'Selamat Pagi' : determinePeriod() === 'afternoon' ? 'Selamat Tengahhari' : determinePeriod() === 'evening' ? 'Selamat Petang' : 'Selamat hari'}! Bagaimana saya boleh tolong awak?`}], user: null, time: "today's date'"}])
        setLanguage('my')
        // await handleSendMessage(null, `/restart`)
      }
      setResetData(!resetData)
      setLoading(false)
    }
}
  return (
    <>
      <section className='chatbot-section'>
      <div className={resize ? "chatbot-widget-wrapper full-screen" : "chatbot-widget-wrapper"}>

          {/* chatbot widget wrapper */}
          <div className={toggle ? "chatbot-widget active" : "chatbot-widget"}>
            
            {/* chat header */}
            <div className="chatbot-widget-header">

              {/* agent presence */}
              <div className="chatbot-widget-agent-precence">

                {/* bot times icon */}
                <div className='chatbot-times-icon' onClick={handleToggle}>
                  <img src={x} alt="x" /> 
                </div>

                {/* agent left side */}
                <div className="chatbot-widget-header-left">
                  {/* agent avatar */}
                  <div className="chatbot-recipient-avatar">
                      <img src={textLogo} alt="agent avatar" />
                      {/* https://play-lh.googleusercontent.com/ZG6-bDrdxvKV9lwg_aQ0H90VBHdYApCBaOn4VL33fSnKYKigGsgK5xe7E_rCYra1fDag */}
                      <div className="online-status"></div>
                  </div>

                  {/* agent details */}
                  <div className="chatbot-widget-recipient-details">
                    <p>Chat with</p>
                    <h3>
                      {
                        (() => {
                          if(QueryParams === "healthCare") {
                            return "Healthcare Bot"
                          } else if(QueryParams === "factory") {
                            return "Manufacturar Bot"
                          } else {
                            return "Aceva Bot"
                          }
                        })() 
                      }
                    </h3>
                  </div>
                </div>

                {/* agent right side options */}
                <div className="chatbot-widget-header-right">

                  {/* chatbot credit */}
                  {/* <div className='chatbot-creator'>
                    <p>Powered by</p>
                    <a href="https://www.wipdata.com/" target="_blank" rel="noreferrer">
                      <img src={logo} alt="site logo" />
                    </a>
                  </div> */}

                  <div className='chatbot-widget-header-right-options'>

                    {/* minimize */}
                    <div className="chatbot-widget-minimize refresh-icon" onClick={async () => {      
                      if (language === null) {
                        setLanguage('en')
                      } else {
                        setLanguage(null)
                      }             
                      }}>
                      <AiOutlineGlobal title="Language"/>
                    </div>
                    {
                      chat?.length > 1 && (
                        loading ? 'loading...' : <div className="chatbot-widget-minimize refresh-icon" onClick={() => refreshFunc()}>
                          <IoMdRefreshCircle/>
                        </div>
                      )
                    }

                    {/* cross icon for mobile */}
                    <div className="chatbot-widget-minimize refresh-icon desktop-hide" onClick={handleToggle}>
                      <FaTimes/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* language select */}
            <div style={language ? {display: 'none'} : {position:'absolute', zIndex:'9', background: '#000000a8', width:'100%', height:'100%', padding: '50px 30px'}}>
              <div className='lang-child-wrap'>
                <h5>Select your language:</h5>
                <div style={{display: 'flex', padding:'25px 60px', justifyContent:'space-between'}}>
                  <img width={'40%'} src={en} style={{cursor:'pointer'}} onClick={() => langChangeFunc('en')}/>
                  <img width={'40%'} src={my} style={{cursor:'pointer'}} onClick={() => langChangeFunc('my')}/>
                </div>
              </div>
            </div>

            {/* chat conversation group body */}
            <div className="chat-conversation-wrapper bot-message-wrapper">
              {/* message body */}
              <div className="message-body chatbot-message-body">
                {
                  ((foo) => {
                    if(QueryParams === "healthCare") {
                      return <HealthCareBot language={language} symptoms={symptoms} error={error} chat={chat} handleSendMessage={handleSendMessage} message={message} setMessage={setMessage}/>
                    } else if(QueryParams === "factory") {
                      return <FactoryBot />
                    }
                    })()
                }
              </div>
            </div>

            {/* chat footer */}
            <div className="chatbot-footer">
              <div className="chatbot-footer-input-box mini-bot-footer">
                <form onSubmit={handleSendMessage}>
                  <input autoFocus disabled={loading} value={message} onChange={(e) => setMessage(e.target.value)} onClick={() => setEmojiModel(false)} type="text" placeholder='Start conversation...' />
                  <button type='submit' className='chatbot-send-message-button'>
                    {loading ? <h6 style={{color:'white', fontSize:'10px'}}>sending...</h6> : <FaTelegramPlane />}
                  </button>
                </form>
              </div>
              <div className="chatbot-footer-options">
                <BsFillEmojiSmileFill onClick={() => setEmojiModel(!emojiModel)} />
              </div>
            </div>
            <div className={emojiModel ? "emoji-wrap active" : "emoji-wrap"}>
              <EmojiPicker onEmojiClick={onEmojiClick} height={400} width={372} />
            </div>
          </div>

          {/* chatbot open icon && if resize is true the hide chatbot icon */}
          <div className={toggle ? 'chatbot-mini-preview-wrap active' : 'chatbot-mini-preview-wrap'} onClick={handleToggle}>
            <div className="chatbot-mini-preview-left">
              <img className='bot-preview-img' src={messageIcon} alt="message icon" /> 
              <span className='send-us-message'>Send us a message</span>
            </div>
            <div className="chatbot-mini-preview-right">
              <h5>ACEVA BOT</h5>
            </div>
          </div>
          {/* <ReactAudioPlayer
            src={audio}
            controls
            className='audio-hidden'
          /> */}
      </div>
      </section>
    </>
  )
}

export default Chatbot