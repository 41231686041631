import { AiOutlineSetting, AiOutlineUser } from 'react-icons/ai'
import { BsChat, BsPlusCircleDotted, BsQuestionDiamondFill } from 'react-icons/bs'
import { FaMailBulk } from 'react-icons/fa'
import { FiDatabase } from 'react-icons/fi'
import { IoChatboxEllipsesOutline, IoNotificationsOutline } from 'react-icons/io5'
import { MdOutlineSpaceDashboard } from 'react-icons/md'
import { SiChatbot, SiGoogleanalytics } from 'react-icons/si'

export const data = [
  {
    label: "Dashboard",
    path: "/",
    icon: <MdOutlineSpaceDashboard />
  },
  {
    label: "Analytics",
    path: "/analytics",
    icon: <SiGoogleanalytics />
  },
  {
    label: "Data",
    path: "/data",
    icon: <FiDatabase />
  },
  {
    label: "Notifications",
    path: "/notification",
    icon: <IoNotificationsOutline />
  },
  {
    label: "Chatlogs",
    path: "/chatlogs",
    icon: <BsChat />
  },
  {
    label: "Bot Demo",
    path: "/demo",
    icon: <IoChatboxEllipsesOutline />,
  },
  {
    label: "Integrations",
    path: "/integrations",
    icon: <BsPlusCircleDotted />
  },
  {
    label: "Users",
    path: "/users",
    icon: <AiOutlineUser />
  },
  {
    label: "Settings",
    path: "/settings",
    icon: <AiOutlineSetting />
  },
  {
    label: "Conditional Bot",
    path: "/bot-demo",
    icon: <SiChatbot />
  },
  {
    label: "New Bot Bot",
    path: "/new-bot-demo",
    icon: <SiChatbot />
  },
  {
    label: "PTP Bot",
    path: "/ptp-bot",
    icon: <SiChatbot />
  },
  {
    label: "FAQ Bot",
    path: "/faq-bot",
    icon: <BsQuestionDiamondFill />
  },
  {
    label: "Bulk Message",
    path: "/bulk-message",
    icon: <FaMailBulk />
  },
  // {
  //   label: "Aged Receiveable",
  //   path: "/aged",
  //   icon: <RiFolderReceivedFill />
  // },
  // {
  //   label: "Telegram",
  //   path: "/telegram",
  //   icon: <BsTelegram />
  // },
]